import Stack from '@mui/material/Stack'
import { useTheme } from '@material-ui/styles'
import React from 'react'
import PropTypes from 'prop-types'
import { Chip as CustomChip, Typography } from '../../Wrappers'

import { isMobileDevice } from 'code/Helper'
import { getEventTiming } from 'code/TimeAgo'

import { LocationOnOutlined as LocationIcon } from '@material-ui/icons'

import useMediaQuery from '@material-ui/core/useMediaQuery'

// styles
import useStyles from '../styles'

/**
 * Event component displays event details in either a small or large format based on screen size.
 * Shows event name, timing, location, cost and categories.
 * Small format is used for mobile devices or when explicitly requested.
 * Large format provides a more detailed layout for desktop views.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.event - The event object containing details like name, location, cost, categories etc
 * @param {boolean} [props.showDate=false] - Whether to show the event date
 * @param {boolean} [props.showLength=false] - Whether to show the event duration
 * @param {boolean} [props.showSmall=false] - Force small format display regardless of screen size
 */
export default function Event(props) {
    var classes = useStyles()
    var theme = useTheme()

    const { event, showDate, showLength, showSmall } = props

    const e = event

    const atLeastMedium = useMediaQuery(theme.breakpoints.up('md'))
    
    if (!atLeastMedium || showSmall || isMobileDevice()) {
        // Return small version
        return (
            <Stack spacing={1} alignItems="center">
                <Stack alignItems="center">
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 700,
                            fontSize: '1.142em',
                        }}
                        align="center"
                    >
                        {e.name}
                    </Typography>
                    <Typography align="center" style={{ fontSize: '1em' }}>
                        {getEventTiming(e, { includeLength: !showDate || showLength, onlyLength: !showDate })}
                    </Typography>
                </Stack>

                {(e?.googleMapsPlaceLabel || e?.location || e?.address !== '') && (
                    <Stack alignItems="center" spacing={1}>
                        <Stack 
                            direction="row" 
                            spacing={1} 
                            alignItems="flex-start"
                            style={{ 
                                overflow: 'hidden',
                                width: '100%',
                                flexWrap: 'nowrap'
                            }}
                        >
                            <LocationIcon style={{ fontSize: '1.5em', flexShrink: 0, marginTop: '3px' }} />
                            <Typography 
                                style={{ 
                                    fontSize: '1em',
                                    width: '100%',
                                    wordBreak: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word'
                                }} 
                                className={classes.locationTypography}
                                align="left"
                            >
                                {e?.googleMapsPlaceLabel || e?.location || e?.address}
                            </Typography>
                        </Stack>

                        {(e.categories.length > 0 || e.cost > 0) && (
                            <Stack 
                                direction="row" 
                                spacing={1} 
                                flexWrap="wrap" 
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    gap: '8px',
                                }}
                            >
                                {e.cost > 0 && (
                                    <CustomChip 
                                        className={classes.chip} 
                                        color="green" 
                                        label={'$' + e.cost} 
                                        style={{ margin: 0 }}
                                    />
                                )}
                                {e.categories.map(category => (
                                    <CustomChip
                                        key={`Event.${e.id}.${category.id}`}
                                        className={classes.chip}
                                        color={category.color}
                                        label={category.name}
                                        style={{ margin: 0 }}
                                    />
                                ))}
                            </Stack>
                        )}
                    </Stack>
                )}
            </Stack>
        )
    }

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap={'nowrap'}
                style={{
                    overflowX: 'auto',
                    userSelect: 'none',
                }}
            >
                <Stack alignItems="center">
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 700,
                            fontSize: '1.142em',
                        }}
                    >
                        {e.name}
                    </Typography>
                </Stack>
                <Stack alignItems="center">
                    <Typography align="center" style={{ fontSize: '1em' }}>
                        {getEventTiming(e, { includeLength: !showDate || showLength, onlyLength: !showDate })}
                    </Typography>
                </Stack>
            </Stack>
            {(e?.googleMapsPlaceLabel || e?.location || e?.address !== '' || e.categories.length > 0) && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap={'nowrap'}
                    style={{
                        overflowX: 'auto',
                    }}
                >
                    <Stack 
                        direction="row" 
                        spacing={1} 
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ width: '100%' }}
                    >
                        <Stack 
                            direction="row" 
                            spacing={1} 
                            alignItems="flex-start"
                            style={{ 
                                overflow: 'hidden',
                                flexShrink: 1,
                                minWidth: 0  // Important for text overflow
                            }}
                        >
                            <LocationIcon style={{ fontSize: '1.5em', flexShrink: 0, marginTop: '3px' }} />
                            <Typography 
                                style={{ 
                                    fontSize: '1em',
                                    wordBreak: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word'
                                }} 
                                className={classes.locationTypography}
                                align="left"
                            >
                                {e?.googleMapsPlaceLabel || e?.location || e?.address}
                            </Typography>
                        </Stack>

                        <Stack 
                            direction="row" 
                            spacing={1} 
                            alignItems="center"
                            style={{ flexShrink: 0 }}  // Prevent chips from shrinking
                        >
                            {e.cost > 0 && (
                                <CustomChip 
                                    className={classes.chip} 
                                    color="green" 
                                    label={'$' + e.cost} 
                                />
                            )}
                            {e.categories.map(category => (
                                <CustomChip
                                    key={`Event.${e.id}.${category.id}`}
                                    className={classes.chip}
                                    color={category.color}
                                    label={category.name}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    )
}

Event.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        location: PropTypes.string,
        cost: PropTypes.number,
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                color: PropTypes.string.isRequired
            })
        )
    }).isRequired,
    showDate: PropTypes.bool,
    showLength: PropTypes.bool,
    showSmall: PropTypes.bool
}
