import React from 'react'

import {
    AssignmentOutlined as TasksIcon,
    CalendarToday as CalendarIcon,
    SchoolOutlined as CoursesIcon,
    ContactsOutlined as MembersIcon,
    FastfoodOutlined as MealsIcon,
    ForumOutlined as MessagesIcon,
    Group as RolesIcon,
    HomeOutlined as HomeIcon,
    HomeWorkOutlined as OrganizationsIcon,
    HouseOutlined as ChaptersIcon,
    PollOutlined as PollsIcon,
    Settings as AdminIcon,
    PeopleAltOutlined as StaffIcon,
    Timeline as PointsIcon,
    WhatshotOutlined as RushIcon,
    SmsOutlined as AnnouncementsIcon,
} from '@material-ui/icons'

import { DriveIcon, QuoteIcon, AccreditationOutlinedIcon as AccreditationIcon } from 'components/Icons'

import { AppsIcon as ApplicationsIcon, ManageAccountsIcon as ManageMembersIcon } from '../Icons'

import Permissions from '../../objects/Permissions'

// Next ID: 53

const structure = [
    {
        id: 0,
        label: 'My House',
        link: '/app/dashboard',
        icon: <HomeIcon />,
        shouldHide: false,
    },
    {
        id: 3,
        label: 'Calendar',
        link: '/app/calendar',
        icon: <CalendarIcon />,
        shouldHide: false,
    },
    {
        id: 1,
        label: 'Messages',
        link: '/app/messages',
        icon: <MessagesIcon />,
        feature: 'messages',
    },
    { id: 38, type: 'divider' },
    {
        id: 39,
        label: 'Apps',
        link: '/app/applications',
        icon: <ApplicationsIcon />,
    },
    /*
    {
        id: 37,
        label: 'Courses',
        link: '/app/applications/courses',
        icon: <CoursesIcon />,
        shouldHide: true,
        feature: 'courses',
    },
    */
    {
        id: 33,
        label: 'Drive',
        link: '/app/applications/drive',
        icon: <DriveIcon />,
        shouldHide: false,
        feature: 'drive',
    },
    {
        id: 36,
        label: 'Meals',
        link: '/app/applications/meals',
        icon: <MealsIcon />,
        feature: 'meals',
    },
    {
        id: 17,
        label: 'Members',
        link: '/app/applications/members',
        icon: <MembersIcon />,
        shouldHide: false,
    },
    {
        id: 34,
        label: 'Points',
        link: '/app/applications/points',
        icon: <PointsIcon />,
        shouldHide: false,
        feature: 'points',
    },
    {
        id: 19,
        label: 'Polls',
        link: '/app/applications/polls',
        icon: <PollsIcon />,
        permission: Permissions.POLL_CREATE,
        feature: 'polls',
    },
    {
        id: 18,
        label: 'Rush',
        link: '/app/applications/rush',
        icon: <RushIcon />,
        permission: Permissions.RUSH_VIEW,
        feature: 'rush',
    },
    {
        id: 35,
        label: 'Tasks',
        link: '/app/applications/tasks',
        icon: <TasksIcon />,
        feature: 'tasks',
    },
    { id: 5, type: 'divider', permission: Permissions.ADMIN },
    {
        id: 6,
        label: 'Admin',
        link: '/app/admin',
        icon: <AdminIcon />,
        permission: Permissions.ADMIN,
    },
    {
        id: 9,
        label: 'Manage Members',
        link: '/app/admin/member-management',
        icon: <ManageMembersIcon />,
        permission: Permissions.ADMIN,
    },
    {
        id: 20,
        label: 'Roles',
        link: '/app/admin/roles',
        icon: <RolesIcon />,
        permission: Permissions.ADMIN,
    },
    { id: 11, type: 'divider' },
    /* HQ Staff */
    {
        id: 21,
        label: 'Chapters',
        link: '/app/hq/chapters',
        icon: <ChaptersIcon />,
        isHeadquarters: true,
    },
    {
        id: 41,
        label: 'Calendar',
        link: '/app/hq/calendar',
        icon: <CalendarIcon />,
        isHeadquarters: true,
    },
    {
        id: 51,
        label: 'Members',
        link: '/app/hq/members',
        icon: <StaffIcon />,
        isHeadquarters: true,
    },
    {
        id: 42,
        label: 'Messages',
        link: '/app/hq/messages',
        icon: <MessagesIcon />,
        isHeadquarters: true,
    },
    { id: 44, type: 'divider', isHeadquarters: true },
    {
        id: 43,
        label: 'Apps',
        link: '/app/hq/apps',
        icon: <ApplicationsIcon />,
        isHeadquarters: true,
    },
    {
        id: 47,
        label: 'Accreditation',
        link: '/app/hq/apps/accreditation',
        icon: <AccreditationIcon />,
        isHeadquarters: true,
    },
    {
        id: 52,
        label: 'Announcements',
        link: '/app/hq/apps/announcements',
        icon: <AnnouncementsIcon />,
        isHeadquarters: true,
    },
    {
        id: 48,
        label: 'Courses',
        link: '/app/hq/apps/courses',
        icon: <CoursesIcon />,
        isHeadquarters: true,
    },
    {
        id: 49,
        label: 'Drive',
        link: '/app/hq/apps/drive',
        icon: <DriveIcon />,
        isHeadquarters: true,
    },
    {
        id: 50,
        label: 'Polls',
        link: '/app/hq/apps/polls',
        icon: <PollsIcon />,
        isHeadquarters: true,
    },
    { id: 45, type: 'divider', isHeadquarters: true },
    {
        id: 23,
        label: 'Settings',
        link: '/app/hq/settings',
        icon: <ManageMembersIcon />,
        isHeadquarters: true,
    },
    { id: 46, type: 'divider', isHeadquarters: true },
    /* Greek Connect Staff */
    {
        id: 28,
        label: 'Chapters',
        link: '/app/gc/chapters',
        icon: <ChaptersIcon />,
        isAdmin: true,
    },
    {
        id: 29,
        label: 'Organizations',
        link: '/app/gc/organizations',
        icon: <OrganizationsIcon />,
        isAdmin: true,
    },
    {
        id: 40,
        label: 'Quotes',
        link: '/app/gc/quotes',
        icon: <QuoteIcon />,
        isAdmin: true,
    },
]

export default structure
