


import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'lib/queryKeys';
import { db } from 'config/firebase'

/**
 * returns rushee data
 * @param {string} chapterId - the id of the chapter
 * @param {string} rushId - the id of the rush
 * @param {string} rusheeId - the id of the rushee
 * @returns {firebase.firestore.DocumentData}
 */
const fetchRusheeData = async (chapterId, rushId, rusheeId) => {
    const doc = await db.collection('chapters')
      .doc(chapterId)
      .collection('rushes')
      .doc(rushId).collection('rushees').doc(rusheeId)
      .get()

    if (!doc.exists) {
      console.log('Rushee not found')
      throw new Error('Rushee not found')
    }
    return doc.data()
  }

export function useGetRushee({ 
  chapterId, 
  rushId, 
  rusheeId
}) {
  return useQuery({
    queryKey: [queryKeys.rushee.detail, chapterId, rushId, rusheeId],
    queryFn: () => fetchRusheeData(chapterId, rushId, rusheeId),
    enabled: !!chapterId && !!rushId && !!rusheeId,
  })
}
