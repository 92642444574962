import moment from 'moment'

export const timeAgo = prevDate => {
    const diff = Number(new Date()) - prevDate
    const minute = 60 * 1000
    const hour = minute * 60
    const day = hour * 24
    const month = day * 30
    const year = day * 365
    switch (true) {
        case diff < minute:
            return 'seconds ago'
        case diff < hour:
            const minutes = Math.round(diff / minute)
            return `${minutes} ${minutes > 1 ? 'min' : 'min'} ago`
        case diff < day:
            const hours = Math.round(diff / hour)
            return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`
        case diff < month:
            const days = Math.round(diff / day)
            return `${days} ${days > 1 ? 'days' : 'day'} ago`
        case diff < year:
            const months = Math.round(diff / month)
            return `${months} ${months > 1 ? 'months' : 'month'} ago`
        case diff > year:
            const years = Math.round(diff / year)
            return `${years} ${years > 1 ? 'years' : 'year'} ago`
        default:
            return ''
    }
}

export const getMomentFromYMD = (ymd, timezone) => {
    let date = moment.tz(timezone ? timezone : getDefaultTimezone())
    date.set('year', ymd.y)
    date.set('month', ymd.m)
    date.set('date', ymd.d)
    date.startOf('day')
    return date
}

export const getEventStart = (e, timezone) => {
    let data = e.date
    if ('sd' in data && data.sd !== undefined) {
        let date = getMomentFromYMD(data.sd, timezone)
        if (data.st !== undefined) {
            date.set({ hour: Math.floor(data.st / 60), minute: data.st % 60 })
        } else {
            date.startOf('day')
        }
        return date.toDate()
    }

    let date = new Date(0)
    date.setUTCSeconds(data._seconds ? data._seconds : data.seconds)
    return date
}

export const getEventEnd = (e, timezone) => {
    let data = e.date
    if ('ed' in data && data.ed !== undefined) {
        let date = getMomentFromYMD(data.ed, timezone)
        if ('et' in data && data.et !== undefined) {
            date.set({ hour: Math.floor(data.et / 60), minute: data.et % 60 })
        } else {
            date.endOf('day')
        }
        return date.toDate()
    }

    let date = new Date(0)
    date.setUTCSeconds(data._seconds ? data._seconds : data.seconds)
    if (e.length !== undefined) {
        date.setMinutes(date.getMinutes() + e.length)
    }
    return date
}

export const getFirebaseEndDate = e => {
    return getEventEnd(e)
}

export const getFirebaseDate = e => {
    let data = e.date
    if ('sd' in data && data.sd !== undefined) {
        let date = getMomentFromYMD(data.sd)
        if ('st' in data && data.st !== undefined) {
            date.set({ hour: Math.floor(data.st / 60), minute: data.st % 60 })
            date = date.toDate()
        } else {
            date = date.startOf('day').toDate()
        }
        return date
    }

    let date = new Date(0)
    date.setUTCSeconds(data._seconds ? data._seconds : data.seconds)
    return date
}

export const hasSignInStarted = e => {
    if (e) {
        let eventStart = getFirebaseDate(e)

        if (e.signIn.start !== undefined && e.signIn.start !== null && typeof e.signIn.start === 'number') {
            eventStart.setMinutes(eventStart.getMinutes() - e.signIn.start)
        }

        let diff = new Date() - eventStart

        return diff >= 0
    }

    return false
}

export const hasSignInEnded = e => {
    return new Date() - getEventEnd(e) >= 0 || (e && e.signIn && e.signIn.closed)
}

export const timeTillSignInStarts = e => {
    if (e) {
        let eventStart = getFirebaseDate(e)

        if (e.signIn.start !== undefined && e.signIn.start !== null && typeof e.signIn.start === 'number') {
            eventStart.setMinutes(eventStart.getMinutes() - e.signIn.start)
        }

        return Math.ceil(moment.duration(moment(eventStart).diff(moment())).asMinutes())
    }

    return false
}

export const timeTillExcuseDeadline = e => {
    if (e) {
        let excuseDeadline = getFirebaseDate(e)

        if (e.excuses.deadline !== undefined && e.excuses.deadline !== null && typeof e.excuses.deadline === 'number') {
            excuseDeadline.setMinutes(excuseDeadline.getMinutes() + e.excuses.deadline)
        }

        let deadline = Math.ceil(moment.duration(moment(excuseDeadline).diff(moment())).asMinutes())

        if (deadline < 60 && deadline > 0) {
            return `Excuses due in ${deadline} ${deadline === 1 ? 'minute' : 'minutes'}`
        }

        if (deadline >= 60) {
            let deadlineHours = Math.floor(deadline / 60)

            if (deadlineHours < 24) {
                return `Excuses due in ${deadlineHours} ${deadlineHours === 1 ? 'hour' : 'hours'}`
            }
        }

        return false
    }

    return false
}

export const getYMDFromDate = date => {
    let mom = moment(date)
    return { y: mom.get('year'), m: mom.get('month'), d: mom.get('date') }
}

export const getDefaultTimezone = () => {
    let cache = localStorage.getItem('cache_chapterTimezone')

    if (cache) {
        return cache
    }

    let chapterStorageItem = localStorage.getItem('cache_chapter')
    if (chapterStorageItem) {
        chapterStorageItem = JSON.parse(chapterStorageItem)

        if (chapterStorageItem && chapterStorageItem.settings && chapterStorageItem.settings.timezone) {
            localStorage.setItem('cache_chapterTimezone', chapterStorageItem.settings.timezone)
            return chapterStorageItem.settings.timezone
        }
    }

    return 'America/New_York'
}

export const getDateFromYMD = (ymd, timezone) => {
    const defaultTimezone = getDefaultTimezone()

    let date = moment.tz(timezone ? timezone : defaultTimezone)
    date.set('year', ymd.y)
    date.set('month', ymd.m)
    date.set('date', ymd.d)
    date.startOf('day')
    return date.toDate()
}

export const compareMomentsSafe = (momentA, momentB) => {
    if (!momentA && !momentB) return 0
    if (!momentA) return -1
    if (!momentB) return 1
    return momentA.valueOf() - momentB.valueOf()
}

export const getEventMoment = e => {
    if (e) {
        return moment(getFirebaseDate(e))
    }
    return null
}

export const compareDates = (a, b) => {
    return getEventMoment(a).valueOf() - getEventMoment(b).valueOf()
}

/**
 * Gets the timing/date information for an event
 * @param {Object} e - The event object containing date information
 * @param {Object} [options] - Options for formatting the event timing
 * @param {boolean} [options.includeLength=false] - Whether to include event duration
 * @param {boolean} [options.onlyLength=false] - Whether to only show event duration
 * @param {boolean} [options.notRelative=false] - Whether to avoid relative date formatting (e.g. "Today", "Tomorrow")
 * @param {boolean} [options.longText=false] - Whether to use long date format
 * @param {boolean} [options.includeTZ=false] - Whether to include timezone
 * @param {string} [options.timezone] - Timezone to use for formatting (defaults to chapter timezone)
 * @returns {string} Formatted date/time string for the event
 */
export const getEventTiming = (e, options) => {
    let timezone = getDefaultTimezone()

    let settings = {
        includeLength: false,
        onlyLength: false,
        notRelative: false,
        longText: false,
        includeTZ: false,
        timezone: timezone,
        ...options,
    }

    let tz = moment.tz(settings.timezone).format('z')

    let endStr = options.includeTZ ? ' ' + tz : ''

    let chance = {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] ddd',
        sameElse: 'ddd, MMM D',
    }

    if (settings.notRelative) {
        chance = {
            sameDay: 'ddd, MMM D',
            nextDay: 'ddd, MMM D',
            nextWeek: 'ddd, MMM D',
            lastDay: 'ddd, MMM D',
            lastWeek: 'ddd, MMM D',
            sameElse: 'ddd, MMM D',
        }
    }

    if (settings.longText) {
        chance = {
            sameDay: 'dddd, MMMM D, YYYY',
            nextDay: 'dddd, MMMM D, YYYY',
            nextWeek: 'dddd, MMMM D, YYYY',
            lastDay: 'dddd, MMMM D, YYYY',
            lastWeek: 'dddd, MMMM D, YYYY',
            sameElse: 'dddd, MMMM D, YYYY',
        }
    }

    if (e !== undefined) {
        if (e.date !== undefined && e.date.sd !== undefined) {
            let mom = moment() //.tz('New_York')
            mom.set('year', e.date.sd.y)
            mom.set('month', e.date.sd.m)
            mom.set('date', e.date.sd.d)

            if (!e.date.ad) {
                mom.set('hour', Math.floor(e.date.st / 60))
                mom.set('minute', e.date.st % 60)
            } else {
                mom.startOf('day')
            }

            let end = moment() //.tz('New_York')
            end.set('year', e.date.ed.y)
            end.set('month', e.date.ed.m)
            end.set('date', e.date.ed.d)
            if (!e.date.ad) {
                mom.set('hour', Math.floor(e.date.et / 60))
                mom.set('minute', e.date.et % 60)
            } else {
                end.endOf('day')
            }

            let sdText = mom.calendar(null, chance)

            let edText = end.calendar(null, chance)

            if (mom.isSame(end, 'day')) {
                // Only show start date
                if (e.date.ad) {
                    if (settings.includeLength && settings.onlyLength) {
                        return 'All Day'
                    } else if (settings.includeLength) {
                        return sdText + ' • All Day'
                    }

                    return sdText
                } else {
                    let startTime = '12:00 AM'
                    for (let i = 0; i < times.length; i++) {
                        let time = times[i]
                        if (time.val === e.date.st) {
                            startTime = time.str
                        }
                    }

                    let endTime = '12:00 AM'
                    for (let i = 0; i < times.length; i++) {
                        let time = times[i]
                        if (time.val === e.date.et) {
                            endTime = time.str
                        }
                    }

                    if (settings.includeLength && settings.onlyLength) {
                        return startTime + ' - ' + endTime + endStr
                    } else if (settings.includeLength) {
                        return sdText + ' @ ' + startTime + ' - ' + endTime + endStr
                    } else {
                        return sdText + endStr
                    }
                }
            } else {
                // Show start & stop date
                if (e.date.ad) {
                    return sdText + ' - ' + edText
                } else {
                    let startTime = '12:00 AM'
                    for (let i = 0; i < times.length; i++) {
                        let time = times[i]
                        if (time.val === e.date.st) {
                            startTime = time.str
                        }
                    }

                    let endTime = '12:00 AM'
                    for (let i = 0; i < times.length; i++) {
                        let time = times[i]
                        if (time.val === e.date.et) {
                            endTime = time.str
                        }
                    }

                    if (settings.includeLength) {
                        return sdText + ' @ ' + startTime + ' - ' + edText + ' @ ' + endTime + endStr
                    } else {
                        return sdText + ' - ' + edText + endStr
                    }
                }
            }
        } else {
            let mom = moment(new Date(0).setUTCSeconds(e.date._seconds ? e.date._seconds : e.date.seconds))
            let dateText = mom.calendar(null, chance)

            if (settings.onlyLength) {
                dateText = ''
            }

            if (e.length !== 0) {
                let timeOfDay = mom.format('h:mm a')
                if (settings.onlyLength) {
                    dateText = timeOfDay
                    if (settings.includeLength) {
                        dateText = dateText + ' • ' + minutesToTime(e.length)
                    }
                } else {
                    dateText = dateText + ' @ ' + timeOfDay

                    if (settings.includeLength) {
                        dateText = dateText + ' • ' + minutesToTime(e.length)
                    }
                }
            } else {
                if (settings.onlyLength && settings.includeLength) {
                    dateText = 'All Day'
                } else if (settings.includeLength) {
                    dateText = dateText + ' • All Day'
                }
            }

            return dateText + endStr
        }
    }
    return ''
}

export const getEvents = (date, events) => {
    let arr = []
    if (events) {
        for (let i = 0; i < events.length; i++) {
            let e = events[i]
            let eMoment = getEventMoment(e)
            let eventStart = getFirebaseDate(e)
            let eEnd = e.date.ad !== undefined ? getFirebaseEndDate(e) : new Date(eventStart.getTime() + e.length * 1000 * 60)
            if (eMoment.isSame(date, 'day') || (eMoment.isBefore(date) && moment(eEnd).isAfter(date))) {
                arr.push(e)
            }
        }
    }

    return arr
}

export const minutesToTime = minutes => {
    const hour = 60
    const day = hour * 24
    const week = day * 7
    switch (true) {
        case minutes < hour:
            return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
        case minutes < day:
            const hours = minutes / hour
            return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
        case minutes < week:
            const days = minutes / day
            return `${days} ${days > 1 ? 'days' : 'day'}`
        default:
            const weeks = minutes / week
            return `${weeks} ${weeks > 1 ? 'weeks' : 'week'}`
    }
}

export const times = [
    { str: '12:00 AM', val: 0 },
    { str: '12:15 AM', val: 15 },
    { str: '12:30 AM', val: 30 },
    { str: '12:45 AM', val: 45 },
    { str: '1:00 AM', val: 60 },
    { str: '1:15 AM', val: 75 },
    { str: '1:30 AM', val: 90 },
    { str: '1:45 AM', val: 105 },
    { str: '2:00 AM', val: 120 },
    { str: '2:15 AM', val: 135 },
    { str: '2:30 AM', val: 150 },
    { str: '2:45 AM', val: 165 },
    { str: '3:00 AM', val: 180 },
    { str: '3:15 AM', val: 195 },
    { str: '3:30 AM', val: 210 },
    { str: '3:45 AM', val: 225 },
    { str: '4:00 AM', val: 240 },
    { str: '4:15 AM', val: 255 },
    { str: '4:30 AM', val: 270 },
    { str: '4:45 AM', val: 285 },
    { str: '5:00 AM', val: 300 },
    { str: '5:15 AM', val: 315 },
    { str: '5:30 AM', val: 330 },
    { str: '5:45 AM', val: 345 },
    { str: '6:00 AM', val: 360 },
    { str: '6:15 AM', val: 375 },
    { str: '6:30 AM', val: 390 },
    { str: '6:45 AM', val: 405 },
    { str: '7:00 AM', val: 420 },
    { str: '7:15 AM', val: 435 },
    { str: '7:30 AM', val: 450 },
    { str: '7:45 AM', val: 465 },
    { str: '8:00 AM', val: 480 },
    { str: '8:15 AM', val: 495 },
    { str: '8:30 AM', val: 510 },
    { str: '8:45 AM', val: 525 },
    { str: '9:00 AM', val: 540 },
    { str: '9:15 AM', val: 555 },
    { str: '9:30 AM', val: 570 },
    { str: '9:45 AM', val: 585 },
    { str: '10:00 AM', val: 600 },
    { str: '10:15 AM', val: 615 },
    { str: '10:30 AM', val: 630 },
    { str: '10:45 AM', val: 645 },
    { str: '11:00 AM', val: 660 },
    { str: '11:15 AM', val: 675 },
    { str: '11:30 AM', val: 690 },
    { str: '11:45 AM', val: 705 },
    { str: '12:00 PM', val: 720 },
    { str: '12:15 PM', val: 735 },
    { str: '12:30 PM', val: 750 },
    { str: '12:45 PM', val: 765 },
    { str: '1:00 PM', val: 780 },
    { str: '1:15 PM', val: 795 },
    { str: '1:30 PM', val: 810 },
    { str: '1:45 PM', val: 825 },
    { str: '2:00 PM', val: 840 },
    { str: '2:15 PM', val: 855 },
    { str: '2:30 PM', val: 870 },
    { str: '2:45 PM', val: 885 },
    { str: '3:00 PM', val: 900 },
    { str: '3:15 PM', val: 915 },
    { str: '3:30 PM', val: 930 },
    { str: '3:45 PM', val: 945 },
    { str: '4:00 PM', val: 960 },
    { str: '4:15 PM', val: 975 },
    { str: '4:30 PM', val: 990 },
    { str: '4:45 PM', val: 1005 },
    { str: '5:00 PM', val: 1020 },
    { str: '5:15 PM', val: 1035 },
    { str: '5:30 PM', val: 1050 },
    { str: '5:45 PM', val: 1065 },
    { str: '6:00 PM', val: 1080 },
    { str: '6:15 PM', val: 1095 },
    { str: '6:30 PM', val: 1110 },
    { str: '6:45 PM', val: 1125 },
    { str: '7:00 PM', val: 1140 },
    { str: '7:15 PM', val: 1155 },
    { str: '7:30 PM', val: 1170 },
    { str: '7:45 PM', val: 1185 },
    { str: '8:00 PM', val: 1200 },
    { str: '8:15 PM', val: 1215 },
    { str: '8:30 PM', val: 1230 },
    { str: '8:45 PM', val: 1245 },
    { str: '9:00 PM', val: 1260 },
    { str: '9:15 PM', val: 1275 },
    { str: '9:30 PM', val: 1290 },
    { str: '9:45 PM', val: 1305 },
    { str: '10:00 PM', val: 1320 },
    { str: '10:15 PM', val: 1335 },
    { str: '10:30 PM', val: 1350 },
    { str: '10:45 PM', val: 1365 },
    { str: '11:00 PM', val: 1380 },
    { str: '11:15 PM', val: 1395 },
    { str: '11:30 PM', val: 1410 },
    { str: '11:45 PM', val: 1425 },
]
