import React from 'react'
import EmojiPicker from 'components/EmojiPicker'
import { Dialog as DialogMobile, DialogContent as DialogContentMobile, DialogTitle as DialogTitleMobile } from 'components/Wrappers'
import { useTheme } from '@material-ui/styles'
import { useStatusBar } from 'hooks/capacitor/useStatusBar'

const SelectEmojiDialog = ({ id, onSelect, title, onClose }) => {
    const theme = useTheme()

    useStatusBar({ isDarkMode: theme.palette.type === 'dark' })

    return (
        <DialogMobile maxWidth="md" aria-labelledby={`${id}-title`} open fullWidth onClose={onClose}>
            <DialogTitleMobile id={`${id}-title`} onClose={onClose}>
                {title}
            </DialogTitleMobile>
            <DialogContentMobile
                dividers
                style={{
                    height: '100%',
                    overflow: 'hidden',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: 'none',
                    position: 'relative',
                    padding: 0,
                }}
            >
                <EmojiPicker onSelect={onSelect} style={{ width: 'initial', height: '100%' }} />
            </DialogContentMobile>
        </DialogMobile>
    )
}

export default React.memo(SelectEmojiDialog)
