import React, { useContext, useState } from 'react'

import 'firebase/functions'
import app from 'firebase/app'
import { useTheme } from '@material-ui/styles'

import { Box, CircularProgress, Divider, Grid, FormControl, IconButton, InputLabel, Switch, Select, TextField } from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'
import {
    Close as CloseIcon,
    HomeOutlined as HomeIcon,
    WhatshotOutlined as RushIcon,
    SettingsOutlined as SettingsIcon,
    HowToVoteOutlined as VotingIcon,
    CommentOutlined as CommentsIcon,
    Publish as ActivateIcon,
} from '@material-ui/icons'
import { v4 as uuidv4 } from 'uuid';

import { PreviewIcon } from 'components/Icons'

import FormSection from 'components/Forms/FormSection'
import NavigationBar from 'components/NavigationBar'
import Paper from 'components/Paper'
import { Button } from 'components/Wrappers'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { isMobileDevice } from 'code/Helper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
const fieldTypes = [
    {
        value: 'CHECKBOX',
        label: 'Checkbox',
    },
    {
        value: 'SLIDER',
        label: 'Slider',
    },
]

export default function CreateRush(props) {
    const theme = useTheme()
    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const { requestChapterUpdate } = useContext(ChapterContext)
    const { user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [commentsAllowed, setCommentsAllowed] = useState('after_voting')
    const [votingResults, setVotingResults] = useState('after_voting')

    const [fields, setFields] = useState([
        {
            id: uuidv4(),
            type: 'SLIDER',
            name: 'Academics',
        },
        {
            id: uuidv4(),
            type: 'SLIDER',
            name: 'Philanthropy',
        },
        {
            id: uuidv4(),
            type: 'SLIDER',
            name: 'Involvement',
        },
        {
            id: uuidv4(),
            type: 'CHECKBOX',
            name: 'Bid',
        },
    ])

    const [checkboxState, setCheckboxState] = useState({
        usesVoting: true,
        activateRush: true,
    })

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [errorMessage, setErrorMessage] = useState('');
    function handleFieldsNameChange(event, fieldID) {
        let curFields = [...fields]
        for (var i = 0; i < curFields.length; i++) {
            if (curFields[i].id === fieldID) {
                curFields[i].name = event.target.value
            }
        }
        setFields(curFields)
    }

    function handleFieldsChange(event, fieldID) {
        let curFields = [...fields]
        for (var i = 0; i < curFields.length; i++) {
            if (curFields[i].id === fieldID) {
                curFields[i].type = event.target.value
            }
        }
        setFields(curFields)
    }

    function removeVoteField(id) {
        let curFields = [...fields]
        let indexToRemove = -1
        for (var i = 0; i < curFields.length; i++) {
            if (curFields[i].id === id) {
                indexToRemove = i
            }
        }

        if (indexToRemove > -1) {
            curFields.splice(indexToRemove, 1)
            setFields(curFields)
        }
    }

    function addVoteField() {
        let curFields = [...fields]
        curFields.push({
            id: uuidv4(),
            type: 'CHECKBOX',
            name: '',
        })
        setFields(curFields)
    }

    function handleSubmit() {
        /*
        if (!startDate || !endDate) {
            setErrorMessage('Please select both start and end dates')
            return
        }*/

        let rush = {
            chapter: user.getChapter(),
            name: name,
            rushID: uuidv4(),
            startDate: startDate ? app.firestore.Timestamp.fromDate(startDate) : null,
            endDate: endDate ? app.firestore.Timestamp.fromDate(endDate) : null,
            features: {
                voting: {
                    isEnabled: checkboxState.usesVoting,
                    fields: fields,
                    results: votingResults,
                },
                comments: {
                    allowed: commentsAllowed,
                },
                timeline: {
                    isEnabled: false,
                },
            },
            activate: checkboxState.activateRush,
        }

        var createRush = app.functions().httpsCallable('createRush')
        createRush(rush)
            .then(async function(result) {
                if (checkboxState.activateRush) {
                    // TODO: Add slight delay
                    await requestChapterUpdate()
                }

                setTimeout(() => {
                    setIsLoading(false)

                    if (props.history.length === 0) {
                        props.history.replace('/app/applications/rush/settings/')
                    } else {
                        props.history.goBack()
                    }
                }, 500)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setErrorMessage(message || 'An error occurred while creating the rush')
                setIsLoading(false)
            })

        setIsLoading(true)
    }

    const handleChange = event => {
        setCheckboxState({
            ...checkboxState,
            [event.target.name]: event.target.checked,
        })
    }

    const renderEditor = () => (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    label={'Name'}
                    type="text"
                    inputProps={{
                        maxLength: 32,
                    }}
                    style={{ marginTop: 0 }}
                    onChange={e => {
                        setName(e.target.value)
                    }}
                    value={name}
                    required
                    fullWidth
                    placeholder="ex: Fall 2024"
                />
            </Grid>
            <Grid item xs={12}>
            <MuiAlert severity="info">
                Beta Feature: Set a date range to track Rush event attendance in rushee profiles.
            </MuiAlert>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: "outlined",
                                margin: "dense",
                                required: true
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        minDate={startDate}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: "outlined",
                                margin: "dense",
                                required: true
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label="Comments"
                    labelTooltip="Configure if and when a user can comment on a rushee."
                    icon={<CommentsIcon />}
                    showHeader
                    control={
                        <Select
                            native
                            variant="outlined"
                            margin="dense"
                            size="small"
                            value={commentsAllowed}
                            onChange={e => {
                                setCommentsAllowed(e.target.value)
                            }}
                        >
                            <option value={`admin`}>Admins only</option>
                            <option value={`after_voting`}>After voting</option>
                            <option value={`everyone`}>Everyone</option>
                        </Select>
                    }
                    enabled={checkboxState.usesComments}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label="Voting"
                    labelTooltip="When enabled, officers can add fields for rushees that members can rate."
                    icon={<VotingIcon />}
                    showHeader
                    control={<Switch color="primary" checked={checkboxState.usesVoting} name="usesVoting" onChange={handleChange} size="small" />}
                    enabled={checkboxState.usesVoting}
                >
                    {fields.map((field, index) => (
                        <React.Fragment key={field.id}>
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid container spacing={1} style={{ flexGrow: 1 }}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label={field.name ? '' : 'Name'}
                                            type="text"
                                            id={'voteFieldName_' + field.id}
                                            value={field.name}
                                            onChange={event => {
                                                handleFieldsNameChange(event, field.id)
                                            }}
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            style={{ marginTop: 4 }}
                                            InputLabelProps={{ shrink: false }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl variant="outlined" margin="dense" fullWidth style={{ marginTop: 4 }}>
                                            <InputLabel htmlFor={field.id}>Type</InputLabel>
                                            <Select
                                                native
                                                value={field.type}
                                                onChange={event => {
                                                    handleFieldsChange(event, field.id)
                                                }}
                                                label="Type"
                                                inputProps={{
                                                    id: field.id,
                                                }}
                                            >
                                                {fieldTypes.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Box
                                    style={{
                                        margin: 4,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                removeVoteField(field.id)
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            {!atLeastSmall && index < fields.length - 1 ? (
                                <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                            ) : (
                                <Divider style={{ backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }} />
                            )}
                        </React.Fragment>
                    ))}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => addVoteField()}
                                style={{ marginTop: 4, marginBottom: 4 }}
                                disabled={fields.length >= 6}
                                size="large"
                                disableElevation
                            >
                                Add Field
                            </Button>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            {checkboxState.usesVoting && (
                <>
                    <Grid item xs={12}>
                        <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSection
                            label="Voting Results"
                            labelTooltip="Configure if and when a user can view the voting results of a rushee."
                            icon={<PreviewIcon />}
                            showHeader
                            control={
                                <Select
                                    native
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={votingResults}
                                    onChange={e => {
                                        setVotingResults(e.target.value)
                                    }}
                                >
                                    <option value={`admin`}>Admins only</option>
                                    <option value={`after_voting`}>After voting</option>
                                    <option value={`everyone`}>Everyone</option>
                                </Select>
                            }
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label="Activate Rush"
                    labelTooltip="If any other rush is currently active it will be automatically deactivated."
                    icon={<ActivateIcon />}
                    showHeader
                    control={<Switch color="primary" checked={checkboxState.activateRush} name="activateRush" onChange={handleChange} size="small" />}
                    enabled={checkboxState.activateRush}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {errorMessage && (
                        <Grid item xs={12}>
                            <MuiAlert 
                                severity="error" 
                                onClose={() => setErrorMessage('')}
                                sx={{ mb: 2 }}
                            >
                                {errorMessage}
                            </MuiAlert>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{ marginRight: 4 }}
                            onClick={() => {
                                props.history.replace('/app/applications/rush/settings/')
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {isLoading ? (
                            <Box style={{ paddingLeft: 25 }}>
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Button 
                                variant={'contained'} 
                                color={'primary'} 
                                onClick={() => handleSubmit()} 
                                style={{ marginLeft: 4 }}
                            >
                                Create
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Grid container spacing={2}>
            <NavigationBar
                titles={[
                    {
                        name: 'My House',
                        link: '/app/dashboard/',
                        icon: <HomeIcon />,
                    },
                    {
                        name: 'Rush',
                        link: '/app/applications/rush/',
                        icon: <RushIcon />,
                        iconMobileOnly: true,
                    },
                    {
                        name: 'Settings',
                        link: '/app/applications/rush/settings/',
                        icon: <SettingsIcon />,
                        iconMobileOnly: true,
                    },
                    { name: 'Create' },
                ]}
                grid
            />
            <Grid item xs={12}>
                {isMobileDevice() ? (
                    renderEditor()
                ) : (
                    <Paper>
                        <Box style={{ padding: '1rem' }}>{renderEditor()}</Box>
                    </Paper>
                )}
            </Grid>
        </Grid>
    )
}
