import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    applicationIcon: {
        fontSize: '2em',
        height: '2em',
        margin: '0.5em',
        marginBottom: 0,
        width: '2em',
    },
    representativeBox: {
        marginLeft: 24,
        marginRight: 24,
        marginBottom: 12,
    },
    representativePhoto: {
        width: 128,
        height: 128,
        display: 'block',
        objectFit: 'cover',
        margin: '0 auto',
        borderRadius: 64,
        padding: 4,
    },
    sectionOuter: {
        marginLeft: 24,
        marginRight: 12,
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    dividerOuter: {
        padding: 0,
    },
    sectionInner: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 1,
    },
    sectionSwitch: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    recommendedArticleBody: {
        padding: `0px !important`,
        position: 'relative',
        width: '100%',
    },
}))
