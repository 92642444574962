import { Box, CircularProgress, Checkbox, Collapse, Fade, Grid, IconButton, Slide, TextField } from '@material-ui/core'
import {
    ArrowBack as BackIcon,
    CancelOutlined as CloseIcon,
    ExpandMore as ExpandMoreIcon,
    HomeOutlined as HomeIcon,
    PersonOutlineOutlined as PersonIcon,
//    VideocamOutlined as VideoIcon,
} from '@material-ui/icons'
import { useAnalytics } from '../../services/mixpanel/analytics';

import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MuiPhoneNumber from 'material-ui-phone-number'

import classnames from 'classnames'
import app from 'firebase/app'
import React, { useState, useContext } from 'react'

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'

import { AuthContext } from 'context/AuthContext'
import { FraternityContext } from 'context/FraternityContext'
import { getAppName } from '../../code/App'
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '../../components/Wrappers'
import google from '../../images/google.svg'

import { Capacitor } from '@capacitor/core'
import { useStatusBar } from 'hooks/capacitor/useStatusBar'
import { useTheme } from '@material-ui/styles'

import Operation from './components/Operation'
import Poll from './components/Poll'

import { ButtonLargeItem } from '../setup/components/ToggleItem'

import logoDark from './assets/app_icon.png'
import logoLarge from './assets/logo.png'
import useStyles from './styles'

import firebase from 'firebase'

import { isValidEmail, MarketingState, State } from './data.js'
import { isAndroidDevice } from 'code/Helper'

export default function Login(props) {
    var classes = useStyles()
    const theme = useTheme()
    const analytics = useAnalytics();
    const searchParams = new URLSearchParams(props.location.search)

    const db = app.firestore()

    let { user, requestUserUpdate } = useContext(AuthContext)
    let { fraternity } = useContext(FraternityContext)

    const textFieldInputProps = {
        classes: {
            input: classes.textFieldInput,
        },
    }

    const [googleValues, setGoogleValues] = useState({
        photoURL: '',
        uid: '',
    })

    const searchParamActivationCode = searchParams.get('code')
    const searchParamEmail = searchParams.get('email')
    const searchParamRegister = searchParams.get('register')
    const searchParamFirst = searchParams.get('first')
    const searchParamLast = searchParams.get('last')

    const [inputs, setInputs] = useState({
        email: user ? user.email : searchParamEmail ? searchParamEmail : '',
        password: '',
        first: searchParamFirst ? searchParamFirst : '',
        last: searchParamLast ? searchParamLast : '',
        agreedToTerms: false,
        activationCode: searchParamActivationCode ? searchParamActivationCode : '',
        editingEmail: false,
    })

    function getBirthdayStr(birthday) {
        if (birthday.seconds) {
            let seconds = birthday.seconds
            let date = new Date(0)
            date.setUTCSeconds(seconds)
            return date
        }

        let date = new Date(0)
        date.setUTCSeconds(birthday._seconds)
        return date
    }

    const [detailInputs, setDetailInputs] = useState({
        major: user && user.major ? user.major : '',
        hometown: user && user.hometown ? user.hometown : '',
        class: user && user.class ? user.class : null,
        phone: user && user.phone ? user.phone : '',
        birthday: user && user.birthday ? getBirthdayStr(user.birthday) : null,
    })
    useStatusBar({ isDarkMode: theme.palette.type === 'dark' })


    const updateInput = (type, val) => setInputs(inputs => ({ ...inputs, [type]: val }))
    const updateDetailInput = (type, val) => setDetailInputs(inputs => ({ ...inputs, [type]: val }))

    function clearInputs() {
        setDetailInputs({ major: '', hometown: '', class: null, phone: '', birthday: null })
        setGoogleValues({ photoURL: '', uid: '' })
        setInputs({
            email: '',
            password: '',
            activationCode: '',
        })
    }

    const [message, setMessage] = useState({ type: 'none', message: '' })

    const resetMessage = () => {
        setMessage({ type: 'none', message: '' })
    }

    function forgotPassword(email) {
        setIsLoading(true)
        resetMessage()
        app.auth()
            .sendPasswordResetEmail(email)
            .then(function (result) {
                setIsLoading(false)
                setMessage({ type: 'success', message: 'Password reset email sent' })
            })
            .catch(function (error) {
                setIsLoading(false)
                if (error.code === 'auth/invalid-email') {
                    setMessage({ type: 'error', message: 'Email is not valid' })
                } else if (error.code === 'auth/user-not-found') {
                    setMessage({ type: 'error', message: 'Account does not exist' })
                } else {
                    setMessage({ type: 'error', message: 'Unable to send reset password email' })
                }
            })
    }

    const getSignInOptions = async email => {
        resetMessage()
        setIsLoading(true)
        let options = await firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .catch(error => {
                setIsLoading(false)
                setMessage({ type: 'error', message: 'Email is not valid' })
            })
        setIsLoading(false)

        if (options !== undefined) {
            setLoginOptions(options)
            setState(state => ({ active: null, next: options.length === 0 ? State.REGISTER : State.AUTHENTICATE }))
        }
    }
    const identifyUser = async (user) => {
        const { uid, email, phone, notifications, chapter, chapterId, displayName, createdAt } = user
            try {
                const traits = {
                    uid,
                    email,
                    $phone: phone,
                    notifications,
                    chapter, 
                    chapterId,
                    $email: email,
                    $name: displayName, // Add if available
                    $created: createdAt
                };
            
                // Single call with all properties
                await analytics.identify(user.uid, traits);
            } catch(e) {
                console.log(`error identifying user ${uid}`)
            }
    }

    const activateAccount = async () => {
        var activateAcc = app.functions().httpsCallable('activateAccount')

        let submission = {
            emailAddress: inputs.email,
            password: inputs.password,
            activationCode: inputs.activationCode,
        }

        if (inputs.first && inputs.last) {
            submission.first = inputs.first
            submission.last = inputs.last
        }

        if (googleValues.photoURL) {
            submission.photoURL = googleValues.photoURL
        }

        setIsLoading(true)
        resetMessage()

        let result = await activateAcc(submission).catch(function (error) {
            setIsLoading(false)
            setMessage({ type: 'error', message: error.message })
        })

        if (result === undefined) {
            return
        }

        let user = result.data
        let prevUser = localStorage.getItem('user')
        if (user) {
            await identifyUser(user)
        }
        if (prevUser) {
            const prevUserId = JSON.parse(prevUser).userID

            if (prevUserId) {
                user.uid = prevUserId
            }
        }

        localStorage.setItem('user', JSON.stringify(user))

        if (loginOptions.length === 0 && inputs.email && inputs.password) {
            await signInUser()
        } else {
            setIsLoading(false)

            window.location.reload()
        }
    }

    async function signInWithGoogle(history) {
        var provider = new app.auth.GoogleAuthProvider()

        provider.setCustomParameters({
            prompt: 'select_account',
        })

        setIsLoading(true)

        resetMessage()

        var user

        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
            await GoogleAuth.signOut()

            const response = await GoogleAuth.signIn({
                /*webClientId: '977375765064-kfoa7qu2ua36akag6gue50sbu24cddom.apps.googleusercontent.com',
                offline: true,*/
            }).catch(error => {
                console.log('unable to sign in with Google', error)
                setIsLoading(false)
            })

            const googleCredential = firebase.auth.GoogleAuthProvider.credential(response.authentication.idToken, response.authentication.accessToken)

            user = await firebase.auth().signInWithCredential(googleCredential)

            if (user) {
                user = user.user
                await identifyUser(user)
            }
        } else {
            let signIn = Capacitor.getPlatform() === 'web' ? app.auth().signInWithPopup(provider) : app.auth().signInWithRedirect(provider)

            let result = await signIn.catch(function (error) {
                setIsLoading(false)
                if (error.code === 'auth/account-exists-with-different-credential') {
                    setMessage({ type: 'error', message: 'Sign in with your password to add Google Sign In' })
                } else if (error.code !== 'auth/popup-closed-by-user') {
                    setMessage({ type: 'error', message: 'Unknown error occured, please talk to an admin if this persists' })
                }
            })

            user = result && result.user ? result.user : null
        }

        if (user) {
            const userId = user.uid ? user.uid : user.id
            await identifyUser(user)
            let userDoc = await db
                .collection('users')
                .doc(userId)
                .get()

            if (!userDoc.exists) {
                setInputs(inputs => ({ ...inputs, email: user.email }))
                setGoogleValues(values => ({ ...values, photoURL: user.photoURL, uid: userId }))
                setState(state => ({ active: null, next: State.REGISTER }))
                setIsLoading(false)
                setLoginOptions(['google.com'])
            }
        }
    }

    function goToDashboard() {
        localStorage.setItem('cache_ignoreInfo', true)
        props.history.push('/app/dashboard')
    }

    async function signInUser() {
        try {
            setIsLoading(true)
            const userCredential = await app.auth().signInWithEmailAndPassword(inputs.email, inputs.password)
            if (userCredential) {
                const user = userCredential.user
                await identifyUser(user)
            }
        } catch (error) {
            if (error.code === 'auth/invalid-email') {
                setMessage({ type: 'error', message: 'Email is not valid' })
            } else if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                setMessage({ type: 'error', message: "Account doesn't exist or password is incorrect" })
            } else if (error.code === 'auth/user-disabled') {
                setMessage({ type: 'error', message: 'Account is disabled, try resetting your password' })
            }

            setIsLoading(false)
        }
    }

    const getGoogleButton = () => {
        return (
            <Button size="large" className={classes.googleButton} onClick={() => signInWithGoogle(props.history)}>
                <img src={google} alt="Google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
            </Button>
        )
    }

    const getLoginInformation = canCancel => {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', maxWidth: '100%' }}>
                <Box className={classes.accountDisplayBox}>
                    <Typography className={classnames(classes.accountDisplay, { [classes.accountDisplayClose]: canCancel })}>{inputs.email}</Typography>
                    <TextField type="hidden" value={inputs.email} autoComplete="email username" />
                    {canCancel && (
                        <IconButton
                            size="small"
                            aria-label="Cancel"
                            onClick={() => {
                                clearInputs()
                                setState(state => ({ active: null, next: State.LOGIN }))
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>
        )
    }

    const getLoginScreen = () => (
        <form
            onSubmit={() => {
                if (isValidEmail(inputs.email)) {
                    getSignInOptions(inputs.email)
                }
            }}
        >
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.greeting}>
                        Howdy!
                    </Typography>
                </Grid>
                {message.type === 'error' && (
                    <Grid item xs={12}>
                        <Collapse in appear>
                            <Typography color="red" className={classes.errorMessage} align="center">
                                {message.message}
                            </Typography>
                        </Collapse>
                    </Grid>
                )}
                {!isAndroidDevice() && (
                    <>
                        <Grid item xs={12}>
                            {getGoogleButton()}
                        </Grid>
                        <Grid item xs={12} className={classes.formDividerContainer}>
                            <div className={classes.formDivider} />
                            <Typography className={classes.formDividerWord}>or</Typography>
                            <div className={classes.formDivider} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <TextField
                        InputProps={textFieldInputProps}
                        className={classes.textField}
                        value={inputs.email}
                        onChange={e => updateInput('email', e.target.value)}
                        placeholder="Email Address"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        autoComplete="email"
                    />
                </Grid>
                {isAndroidDevice() && (
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
                        <Checkbox
                            color="primary"
                            checked={inputs.agreedToTerms}
                            onChange={() => setInputs(inputs => ({ ...inputs, agreedToTerms: !inputs.agreedToTerms }))}
                        />
                        <Typography align="center" variant="caption" className={classes.terms} style={{ marginTop: 0 }}>
                            I agree to the{' '}
                            <Typography
                                variant="caption"
                                component="a"
                                color="primary"
                                href="https://getgreekconnect.com/privacy"
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                            >
                                Privacy Policy
                            </Typography>{' '}
                            and{' '}
                            <Typography
                                variant="caption"
                                component="a"
                                color="primary"
                                href="https://getgreekconnect.com/terms"
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                            >
                                Terms of Service
                            </Typography>
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.formButtons}>
                    {isLoading ? (
                        <CircularProgress size={42} className={classes.loginLoader} />
                    ) : (
                        <Button
                            disabled={!isValidEmail(inputs.email) || (isAndroidDevice() && !inputs.agreedToTerms)}
                            onClick={() => getSignInOptions(inputs.email)}
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ margin: '0 auto' }}
                            type="submit"
                        >
                            Sign In
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'right', marginTop: 10, marginRight: 15}}> 
                <Typography>
                    or register{' '}
                    <Typography
                        component="span"
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setState(state => ({ active: null, next: State.REGISTER }))
                            if (!isValidEmail(inputs.email)) {
                                setInputs(inputs => ({ ...inputs, email: '' }))
                            }
                        }}
                    >
                        here
                    </Typography>
                </Typography>
            </Box>
            <Box className={classes.copyrightBox}>
                {!isAndroidDevice() && (
                    <Typography align="center" variant="caption" className={classes.terms}>
                        By continuing, you agree to our
                        <br />
                        <Typography
                            variant="caption"
                            component="a"
                            color="primary"
                            href="https://getgreekconnect.com/privacy"
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                        >
                            Privacy Policy
                        </Typography>{' '}
                        and{' '}
                        <Typography
                            variant="caption"
                            component="a"
                            color="primary"
                            href="https://getgreekconnect.com/terms"
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                        >
                            Terms of Service
                        </Typography>
                    </Typography>
                )}
                <Typography align="center" color="primary" className={classes.copyright}>
                    © 2025 Greek Connect. All rights reserved.
                </Typography>
            </Box>
        </form>
    )

    const getAuthenticateScreen = () => (
        <form
            onSubmit={() => {
                if (inputs.password.length !== 0) {
                    signInUser()
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.greeting}>
                        Login
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {getLoginInformation(true)}
                </Grid>
                {message.type === 'error' && (
                    <Grid item xs={12}>
                        <Collapse appear in>
                            <Typography color="red" className={classes.errorMessage} align="center">
                                {message.message}
                            </Typography>
                        </Collapse>
                    </Grid>
                )}
                {message.type === 'success' && (
                    <Grid item xs={12}>
                        <Collapse appear in>
                            <Typography color="green" className={classes.errorMessage} align="center">
                                {message.message}
                            </Typography>
                        </Collapse>
                    </Grid>
                )}
                {Capacitor.getPlatform() !== 'android' && loginOptions.includes('google.com') && (
                    <Grid item xs={12}>
                        {getGoogleButton()}
                    </Grid>
                )}
                {Capacitor.getPlatform() !== 'android' && loginOptions.includes('google.com') && (
                    <Grid item xs={12} className={classes.formDividerContainer}>
                        <div className={classes.formDivider} />
                        <Typography className={classes.formDividerWord}>or</Typography>
                        <div className={classes.formDivider} />
                    </Grid>
                )}
                {loginOptions.includes('password') && (
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            InputProps={textFieldInputProps}
                            className={classes.textField}
                            value={inputs.password}
                            onChange={e => updateInput('password', e.target.value)}
                            margin="dense"
                            variant="outlined"
                            placeholder="Password"
                            type="password"
                            fullWidth
                            autoComplete="current-password"
                        />
                    </Grid>
                )}
                <Grid item xs={12} className={classes.formButtons}>
                    <Button
                        color="primary"
                        size="large"
                        className={classes.forgetButton}
                        onClick={() => forgotPassword(inputs.email)}
                        style={{ margin: '0 auto' }}
                    >
                        {loginOptions.includes('password') ? 'Forgot Password?' : 'Setup Password'}
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.formButtons}>
                    {isLoading ? (
                        <CircularProgress size={42} className={classes.loginLoader} />
                    ) : (
                        <>
                            {loginOptions.includes('password') && (
                                <Button
                                    disabled={inputs.password.length === 0}
                                    onClick={() => signInUser()}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{ margin: '0 auto' }}
                                    type="submit"
                                >
                                    Login
                                </Button>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </form>
    )

    const getBackButton = () => (
        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', maxWidth: '100%' }}>
            <Button
                className={classes.accountDisplayBox}
                style={{ textTransform: 'initial', padding: '0 8px 0 6px' }}
                onClick={() => {
                    setState(state => ({ active: null, next: State.LOGIN }))
                }}
                startIcon={<BackIcon style={{ margin: 0 }} />}
            >
                <Typography id="go_back_label" className={classnames(classes.accountDisplay, classes.accountDisplayBack)} style={{ padding: 0 }}>
                    Back
                </Typography>
            </Button>
        </Box>
    )

    const getRegisterScreen = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.greeting}>
                    Register
                </Typography>
            </Grid>
            {inputs.email ? (
                <Grid item xs={12}>
                    {getLoginInformation(true)}
                </Grid>
            ) : (
                <Grid item xs={12}>
                    {getBackButton()}
                </Grid>
            )}
            <Grid item xs={12}>
                <ButtonLargeItem
                    icon={<PersonIcon />}
                    title="Activate Account"
                    subtitle="join an existing chapter"
                    onClick={() => {
                        setInputs(inputs => ({ ...inputs, editingEmail: !inputs.email }))
                        setState(state => ({ active: null, next: State.ACTIVATE }))
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <ButtonLargeItem
                    icon={<HomeIcon />}
                    title="Create Chapter"
                    subtitle="get your organization setup"
                    onClick={() => {
                        let url = '/setup'

                        if (!!inputs.email) {
                            url += `?email=${encodeURI(inputs.email)}`
                        }

                        props.history.push(url)
                    }}
                />
            </Grid>
            {
                /**
                 *<Grid item xs={12}>
                    <ButtonLargeItem
                    icon={<VideoIcon />}
                    title="Schedule Demo"
                    subtitle="to learn more"
                    onClick={() => {
                        window.open('https://getgreekconnect.com', '_blank')
                    }}
                    />
                </Grid>
                 */
            }
            <Grid item xs={12}>
                <Typography align="center" variant="caption">
                    Councils and national headquarters{' '}
                    <Typography
                        variant="caption"
                        component="a"
                        color="primary"
                        href="https://getgreekconnect.com/get-started"
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        get started here
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    )

    const getActivateScreen = () => (
        <form
            onSubmit={() => {
                if (!((loginOptions.length === 0 && inputs.password.length === 0) || inputs.activationCode.length === 0 || !isValidEmail(inputs.email))) {
                    activateAccount()
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.greeting}>
                        Activate Account
                    </Typography>
                </Grid>
                {inputs.editingEmail ? (
                    <Grid item xs={12}>
                        {getBackButton()}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        {getLoginInformation(true)}
                    </Grid>
                )}
                {message.type === 'error' && (
                    <Grid item xs={12}>
                        <Collapse appear in>
                            <Typography color="red" className={classes.errorMessage} align="center">
                                {message.message}
                            </Typography>
                        </Collapse>
                    </Grid>
                )}
                {searchParamEmail && inputs.email && inputs.email !== searchParamEmail && (
                    <Grid item xs={12}>
                        <Collapse appear in>
                            <Typography color="red" className={classes.errorMessage} align="center">
                                Your activation code is for {searchParamEmail}, but you are attempting to register with {inputs.email}
                            </Typography>
                        </Collapse>
                    </Grid>
                )}
                <Grid item xs={6}>
                    <TextField
                        InputProps={textFieldInputProps}
                        className={classes.textField}
                        value={inputs.first}
                        onChange={e => updateInput('first', e.target.value)}
                        label="First"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        InputProps={textFieldInputProps}
                        className={classes.textField}
                        value={inputs.last}
                        onChange={e => updateInput('last', e.target.value)}
                        label="Last"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                    />
                </Grid>
                {inputs.editingEmail && (
                    <Grid item xs={12}>
                        <TextField
                            InputProps={textFieldInputProps}
                            className={classes.textField}
                            value={inputs.email}
                            onChange={e => updateInput('email', e.target.value)}
                            label="Email Address"
                            type="email"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="email"
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TextField
                        InputProps={textFieldInputProps}
                        className={classes.textField}
                        value={inputs.activationCode}
                        onChange={e => updateInput('activationCode', e.target.value)}
                        label="Activation Code"
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                    />
                </Grid>
                {loginOptions.length === 0 && (
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            InputProps={textFieldInputProps}
                            className={classes.textField}
                            value={inputs.password}
                            onChange={e => updateInput('password', e.target.value)}
                            margin="dense"
                            variant="outlined"
                            label="Create Password"
                            type="password"
                            fullWidth
                            autoComplete="new-password"
                        />
                    </Grid>
                )}
                <Grid item xs={12} className={classes.formButtons}>
                    {isLoading ? (
                        <CircularProgress size={42} className={classes.loginLoader} />
                    ) : (
                        <Button
                            disabled={
                                (loginOptions.length === 0 && inputs.password.length === 0) || inputs.activationCode.length === 0 || !isValidEmail(inputs.email)
                            }
                            onClick={() => activateAccount()}
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ margin: '0 auto' }}
                            type="submit"
                        >
                            Activate
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.formDividerContainer}>
                    <div className={classes.formDivider} />
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>No activation code?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography>Your organization must be registered, and then an admin can provide you an activation code</Typography>
                            <Typography
                                color="primary"
                                onClick={() => setState({ active: null, next: State.REGISTER })}
                                align="center"
                                style={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                Register
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </form>
    )

    const getClass = () => {
        if (detailInputs.class) {
            let d = new Date()
            d.setFullYear(detailInputs.class)
            return d
        }

        return null
    }

    const updateDetails = async () => {
        if (user && user.getId()) {
            let submission = {
                chapter: user.getChapter(),
                memberID: user.getId(),
                hometown: detailInputs.hometown,
                major: detailInputs.major,
                class: detailInputs.class,
                phone: detailInputs.phone,
                birthday: detailInputs.birthday ? detailInputs.birthday.toISOString() : null,
            }
            setIsLoading(true)
            resetMessage()

            var functionsCall = app.functions().httpsCallable('editProfile')

            await functionsCall(submission).catch(function (error) {
                setIsLoading(false)
                setMessage({ type: 'error', message: error.message })
            })

            requestUserUpdate()

            goToDashboard()
        }
    }

    const getUpdateScreen = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.greeting}>
                    Update Details
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {getLoginInformation(false)}
            </Grid>
            {message.type === 'error' && (
                <Grid item xs={12}>
                    <Collapse appear in>
                        <Typography color="red" className={classes.errorMessage} align="center">
                            {message.message}
                        </Typography>
                    </Collapse>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    InputProps={textFieldInputProps}
                    className={classes.textField}
                    inputProps={{
                        maxLength: 32,
                    }}
                    value={detailInputs.major}
                    onChange={e => updateDetailInput('major', e.target.value)}
                    label="Major"
                    placeholder="ChemE"
                    type="text"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={textFieldInputProps}
                    className={classes.textField}
                    inputProps={{
                        maxLength: 32,
                    }}
                    value={detailInputs.hometown}
                    onChange={e => updateDetailInput('hometown', e.target.value)}
                    label="Hometown"
                    placeholder="Atlanta, GA"
                    type="text"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                />
            </Grid>
            <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    openTo="year"
                    views={['year']}
                    inputVariant="outlined"
                    disableToolbar
                    id="class"
                    label="Class"
                    value={getClass()}
                    fullWidth
                    InputProps={textFieldInputProps}
                    className={classes.textField}
                    margin="dense"
                    onChange={e => updateDetailInput('class', e && e instanceof Date ? e.getFullYear() : null)}
                    KeyboardButtonProps={{
                        'aria-label': 'change year',
                    }}
                />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
                <MuiPhoneNumber
                    variant="outlined"
                    defaultCountry="us"
                    margin="dense"
                    label="Phone #"
                    type="text"
                    InputProps={textFieldInputProps}
                    className={classes.textField}
                    inputProps={{
                        maxLength: 24,
                    }}
                    onChange={e => updateDetailInput('phone', e)}
                    value={detailInputs.phone}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        openTo="year"
                        views={['year', 'month', 'date']}
                        disableFuture
                        inputVariant="outlined"
                        disableToolbar
                        format="MM/dd/yyyy"
                        id="birthday"
                        label="Birthday"
                        value={detailInputs.birthday}
                        fullWidth
                        InputProps={textFieldInputProps}
                        className={classes.textField}
                        margin="dense"
                        onChange={e => updateDetailInput('birthday', e)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} className={classes.formButtons}>
                {isLoading ? (
                    <CircularProgress size={42} className={classes.loginLoader} />
                ) : (
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <Button color="primary" size="large" className={classes.forgetButton} onClick={() => goToDashboard()}>
                            Skip
                        </Button>
                        <Button onClick={() => updateDetails()} variant="contained" color="primary" size="large">
                            Update
                        </Button>
                    </Box>
                )}
            </Grid>
        </Grid>
    )

    const renderScreens = () => {
        const screens = [
            { state: State.LOGIN, render: getLoginScreen },
            { state: State.AUTHENTICATE, render: getAuthenticateScreen },
            { state: State.ACTIVATE, render: getActivateScreen },
            { state: State.REGISTER, render: getRegisterScreen },
            { state: State.UPDATE, render: getUpdateScreen },
        ]

        const renderedScreens = []

        for (let i = 0; i < screens.length; i++) {
            const sc = screens[i]
            const direction = sc.state === State.LOGIN ? 'right' : 'left'
            const active = state.active === sc.state

            renderedScreens.push(
                <Slide
                    key={sc.state}
                    in={active}
                    direction={direction}
                    mountOnEnter
                    unmountOnExit
                    appear={false}
                    onExited={() => setState(state => ({ active: state.next, next: null }))}
                >
                    {sc.render()}
                </Slide>,
            )
        }

        return renderedScreens
    }

    // local
    var [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        active: props.history.location.pathname.includes('/update') ? State.UPDATE : searchParamRegister ? State.REGISTER : State.LOGIN,
        next: null,
    })

    // const [marketingState, setMarketingState] = useState(MarketingState.POLLS) // MarketingState.POLLS MarketingState.RSVP
    const marketingState = MarketingState.POLLS

    const [loginOptions, setLoginOptions] = useState([])

    return (
        <Grid container className={classes.container}>
            <Box className={classnames(classes.logotypeContainer)}>
                <Fade in={state.active === State.LOGIN} appear={false}>
                    <Box>
                        <img src={logoLarge} alt="" className={classnames(classes.logotypeImageLarge, classes.logotypeImageLargeLogo)} />
                        <Typography variant="h1" className={classnames(classes.logotypeText, classes.logotypeTextLogo)}>
                            {getAppName(fraternity)}
                        </Typography>
                    </Box>
                </Fade>
                <Fade in={state.active !== State.LOGIN && state.active !== null} appear={false}>
                    <Box style={{ width: '100%', height: '100%' }}>
                        <Box className={classes.marketingBox}>
                            <img src={logoLarge} alt="" className={classnames(classes.logotypeImageLarge, classes.logotypeImageLargeCalendar)} />
                            <Typography variant="h1" className={classnames(classes.logotypeText, classes.logotypeTextCalendar)}>
                                {getAppName(fraternity)}
                            </Typography>
                            {marketingState === MarketingState.POLLS && (
                                <Box>
                                    <Box style={{ left: 16, right: 16, bottom: 100, top: 100, position: 'absolute' }}>
                                        <Poll />
                                    </Box>
                                    <Box style={{ bottom: 16, left: 0, right: 0, position: 'absolute' }}>
                                        <Typography align="center" className={classes.marketingTextTitle}>
                                            Create Polls in Seconds
                                        </Typography>
                                        <Typography align="center" className={classes.marketingTextSubtitle}>
                                            Select who can respond and view results in real-time
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {marketingState === MarketingState.RSVP && (
                                <Box>
                                    <Box style={{ left: 16, right: 16, bottom: 100, top: 100, position: 'absolute' }}>
                                        <Operation />
                                    </Box>
                                    <Box style={{ bottom: 16, left: 0, right: 0, position: 'absolute' }}>
                                        <Typography align="center" className={classes.marketingTextTitle}>
                                            Intelligent Event Management
                                        </Typography>
                                        <Typography align="center" className={classes.marketingTextSubtitle}>
                                            Create a single point of communication for events
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {marketingState === MarketingState.OPERATIONS && (
                                <Box>
                                    <Box style={{ left: 16, right: 16, bottom: 100, top: 100, position: 'absolute' }}>
                                        <Operation />
                                    </Box>
                                    <Box style={{ bottom: 16, left: 0, right: 0, position: 'absolute' }}>
                                        <Typography align="center" className={classes.marketingTextTitle}>
                                            Streamline Chapter Operations
                                        </Typography>
                                        <Typography align="center" className={classes.marketingTextSubtitle}>
                                            Hosting events from start to finish is a breeze
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <Box className={classes.marketingLogo}>
                            <img src={logoLarge} alt="" className={classnames(classes.logotypeImageLarge, classes.logotypeImageLargeLogo)} />
                            <Typography variant="h1" className={classnames(classes.logotypeText, classes.logotypeTextLogo)}>
                                {getAppName(fraternity)}
                            </Typography>
                        </Box>
                    </Box>
                </Fade>
            </Box>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <img src={logoDark} alt={getAppName(fraternity)} className={classes.logotypeImageDark} />
                    </Box>
                    <Typography variant="h1" className={classnames(classes.logotypeText, classes.sidebarLogoText)}>
                        {getAppName(fraternity)}
                    </Typography>
                    {renderScreens()}
                </div>
            </div>
        </Grid>
    )
}
