import DateFnsUtils from '@date-io/date-fns'
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { HomeOutlined as HomeIcon, WhatshotOutlined as RushIcon } from '@material-ui/icons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MuiPhoneNumber from 'material-ui-phone-number'
import React from 'react'

import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import { Button, Typography } from 'components/Wrappers'

import { isMobileDevice } from 'code/Helper'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { rusheeSource, yearTypes } from '../utils'
import { db } from 'config/firebase'

export default function AddRushee(props) {
    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [first, setFirstName] = React.useState('')
    const [last, setLastName] = React.useState('')
    const [major, setMajor] = React.useState('')
    const [hometown, setHometown] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [year, setYear] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [errorText, setErrorText] = React.useState(null)
    const [source, setSource] = React.useState(null)

    async function handleSubmit() {
        setIsLoading(true)
        const rushCollection = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')

        const rushDoc = rushCollection.doc(chapter.activeRush)
        const rusheeDoc = rushDoc.collection('rushees').doc()

        const rusheeID = rusheeDoc.id

        let rusheeFullData = {
            first: first,
            last: last,
            hometown: hometown,
            major: major,
            id: rusheeID,
            year: year,
            photoURL: '',
            eventsAttended: [],
            comments: {},
            timeline: {},
            contact: '',
            instagram: '',
            snapchat: '',
            source: source,
            email: email,
            phone: phone,
            votes: [],
            bidStatus: 0,
        }

        let gotError = false

        await rusheeDoc.set(rusheeFullData).catch(function(error) {
            setErrorText(error.message)
            setIsLoading(false)
            console.log('got error', error)
            gotError = true
        })

        if (!gotError) {
            setIsLoading(false)
            props.history.replace(`/app/applications/rush/rushee/${rusheeID}`)
        }
    }

    function handleYearsChange(event) {
        setYear(event.target.value)
    }

    function handleSourceChange(event) {
        setSource(event.target.value)
    }

    const renderEditor = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label={first ? '' : 'First'}
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 16,
                    }}
                    onChange={e => {
                        setFirstName(e.target.value)
                    }}
                    style={{ marginTop: 4 }}
                    InputLabelProps={{ shrink: false }}
                    value={first}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label={last ? '' : 'Last'}
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 16,
                    }}
                    onChange={e => {
                        setLastName(e.target.value)
                    }}
                    style={{ marginTop: 4 }}
                    InputLabelProps={{ shrink: false }}
                    value={last}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label={email ? '' : 'Email'}
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 16,
                    }}
                    onChange={e => {
                        setEmail(e.target.value)
                    }}
                    style={{ marginTop: 4 }}
                    InputLabelProps={{ shrink: false }}
                    value={email}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label="Major"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 16,
                    }}
                    onChange={e => {
                        setMajor(e.target.value)
                    }}
                    value={major}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    margin="dense"
                    label="Hometown"
                    type="text"
                    variant="outlined"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setHometown(e.target.value)
                    }}
                    value={hometown}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="year-id">Year</InputLabel>
                    <Select
                        labelId="year-id"
                        value={year}
                        onChange={event => {
                            handleYearsChange(event)
                        }}
                        fullWidth
                        label="Year"
                    >
                        {yearTypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="source-id">Source</InputLabel>
                    <Select
                        labelId="source-id"
                        value={source}
                        onChange={event => {
                            handleSourceChange(event)
                        }}
                        fullWidth
                        label="Source"
                    >
                        {rusheeSource.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
                <MuiPhoneNumber
                    defaultCountry={'us'}
                    variant="outlined"
                    margin="dense"
                    label="Phone"
                    type="text"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setPhone(e)
                    }}
                    value={phone}
                    fullWidth
                />
            </Grid>

            {errorText && (
                <Grid item xs={12}>
                    <Typography color="red" style={{ textAlign: `center` }}>
                        {errorText}
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{ marginRight: 4 }}
                            onClick={() => {
                                props.history.replace('/app/applications/rush/')
                                if (props.history.length === 0) {
                                    props.history.replace('/app/applications/rush/')
                                } else {
                                    props.history.goBack()
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {isLoading ? (
                            <Box style={{ paddingLeft: 25 }}>
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Button variant={'contained'} color={'primary'} onClick={() => handleSubmit()} style={{ marginLeft: 4 }}>
                                Add
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form>
                <Grid container spacing={2}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'My House',
                                link: '/app/dashboard/',
                                icon: <HomeIcon />,
                            },
                            {
                                name: 'Rush',
                                link: '/app/applications/rush/',
                                icon: <RushIcon />,
                                iconMobileOnly: true,
                            },
                            { name: 'Add Rushee' },
                        ]}
                        grid
                    />
                    <Grid item xs={12}>
                        {isMobileDevice() ? (
                            renderEditor()
                        ) : (
                            <Widget disableWidgetMenu inheritHeight>
                                {renderEditor()}
                            </Widget>
                        )}
                    </Grid>
                </Grid>
            </form>
        </MuiPickersUtilsProvider>
    )
}
