import { useTheme } from '@material-ui/core/styles'
import React from 'react'

import app from 'firebase/app'

import { Add as AddIcon, Close as CloseIcon, Delete as DeleteIcon, HomeOutlined as HomeIcon, FastfoodOutlined as MealsIcon } from '@material-ui/icons'

import { Box, Button as ButtonCore, DialogContentText, Divider, FormControl, Grid, IconButton, InputLabel, Select } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid';
import { cutoffs } from './data'

// components
import MiniCard from 'components/Cards/MiniCard'
import NavigationBar from 'components/NavigationBar'
import ErrorTypography from 'components/Typography/ErrorTypography'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from 'components/Wrappers'

import { AuthContext } from 'context/AuthContext'

import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function MealsSettings(props) {
    const theme = useTheme()

    const { user } = React.useContext(AuthContext)

    const [hasTried, setHasTried] = React.useState(false)

    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const [newMealDialog, setNewMealDialog] = React.useState({
        open: false,
        edit: false,
        mod: 1,
        dow: 1,
        pickups: [{ time: 12 * 60, cutoff: 11 * 60, id: uuidv4() }],
    })
    const [deletePlanDialog, setDeletePlanDialog] = React.useState({ open: false })

    const [meals, setMeals] = React.useState([])
    const [plans, setPlans] = React.useState([])

    if (!hasTried) {
        setHasTried(true)
        grabSettings()
    }

    function grabSettings() {
        const db = app.firestore()
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc('INFO')
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    let curPlans = []
                    let ps = data.plans
                    let psKeys = Object.keys(ps)

                    for (let i = 0; i < psKeys.length; i++) {
                        let planData = {
                            ...ps[psKeys[i]],
                            id: psKeys[i],
                        }

                        curPlans.push(planData)
                    }

                    curPlans.sort(function(a, b) {
                        return b.members.length - a.members.length
                    })
                    setPlans(curPlans)

                    let curMeals = []
                    let ms = data.meals
                    let msKeys = Object.keys(ms)

                    for (let i = 0; i < msKeys.length; i++) {
                        let mealData = {
                            ...ms[msKeys[i]],
                            id: msKeys[i],
                            name: getMealName(ms[msKeys[i]]),
                        }

                        curMeals.push(mealData)
                    }

                    curMeals.sort(function(a, b) {
                        if (a.dow !== b.dow) {
                            return a.dow - b.dow
                        }

                        return a.mod - b.mod
                    })

                    setMeals(curMeals)
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function getMealName(meal) {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let meals = ['Breakfast', 'Lunch', 'Dinner']

        return days[meal.dow] + ' ' + meals[meal.mod]
    }

    function deletePlan(planID) {
        let submission = {
            chapter: user.getChapter(),
            planID: planID,
        }

        setDeletePlanDialog({ ...deletePlanDialog, loading: true })

        let functionsCall = app.functions().httpsCallable('deleteMealsPlan')
        functionsCall(submission)
            .then(function(result) {
                setDeletePlanDialog({ open: false })
                let ps = [...plans]
                for (let i = 0; i < ps.length; i++) {
                    if (ps[i].id === planID) {
                        ps.splice(i, 1)
                        i--
                    }
                }

                setPlans(ps)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setDeletePlanDialog({ ...deletePlanDialog, error: message, loading: false })
            })
    }

    function updateMeal() {
        let data = newMealDialog

        data.pickups.sort((a, b) => {
            return a.time - b.time
        })

        let da = {
            mod: data.mod,
            dow: data.dow,
            pickups: data.pickups,
            name: getMealName(data),
        }

        let submission = {
            chapter: user.getChapter(),
            mealID: data.edit ? data.id : null,
            mod: data.mod,
            dow: data.dow,
            pickups: data.pickups,
        }

        setNewMealDialog({ ...newMealDialog, loading: true })

        let functionsCall = app.functions().httpsCallable('updateMeal')
        functionsCall(submission)
            .then(function(result) {
                da.id = result.data.mealID
                setNewMealDialog({ open: false })
                let ms = [...meals]
                if (data.edit) {
                    for (let i = 0; i < ms.length; i++) {
                        if (ms[i].id === data.id) {
                            ms[i] = da
                        }
                    }
                } else {
                    ms.push(da)
                }

                ms.sort(function(a, b) {
                    if (a.dow !== b.dow) {
                        return a.dow - b.dow
                    }

                    return a.mod - b.mod
                })

                setMeals(ms)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setNewMealDialog({ ...newMealDialog, error: message, loading: false })
            })
    }

    function getPickups(meal) {
        if (meal.pickups.length > 0) {
            let str = cutoffs[meal.pickups[0].time]
            for (let i = 1; i < meal.pickups.length; i++) {
                str += ', '
                str += cutoffs[meal.pickups[i].time]
            }

            return str
        }
        return 'N/A'
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Meals',
                            link: '/app/applications/meals/',
                            icon: <MealsIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'Settings',
                        },
                    ]}
                    grid
                />
                <NavigationBar
                    titles={[
                        {
                            name: 'Meals',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Add',
                            type: 'icon',
                            innerIcon: <AddIcon />,
                            onClick: () => {
                                setNewMealDialog({
                                    ...newMealDialog,
                                    edit: false,
                                    open: true,
                                    dow: 1,
                                    pickups: [{ time: 12 * 60, cutoff: 11 * 60, id: uuidv4() }],
                                    id: '',
                                })
                            },
                        },
                    ]}
                    grid
                    keep
                />
                {meals.map(meal => (
                    <Grid item xs={12} md={6} lg={4} key={meal.id}>
                        <MiniCard
                            title={meal.name}
                            subtitle={'Pickup Times: ' + getPickups(meal)}
                            leftButton={{
                                label: 'Edit',
                                onClick: () => {
                                    setNewMealDialog({
                                        ...newMealDialog,
                                        edit: true,
                                        open: true,
                                        mod: meal.mod,
                                        dow: meal.dow,
                                        id: meal.id,
                                        pickups: meal.pickups,
                                    })
                                },
                            }}
                            // TODO
                            rightButton={{ icon: <DeleteIcon />, label: 'Delete', onClick: () => console.log('todo') }}
                        />
                    </Grid>
                ))}
                <NavigationBar
                    titles={[
                        {
                            name: 'Plans',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Add',
                            type: 'icon',
                            innerIcon: <AddIcon />,
                            onClick: () => {
                                props.history.push('/app/applications/meals/settings/plans/new')
                            },
                        },
                    ]}
                    keep
                    grid
                />
                {plans.map(plan => (
                    <Grid item xs={12} md={6} lg={4} key={plan.id}>
                        <MiniCard
                            title={plan.name}
                            subtitle={
                                plan.members.length +
                                (plan.members.length === 1 ? ' member ' : ' members') +
                                ' • ' +
                                plan.meals.length +
                                (plan.meals.length === 1 ? ' meal' : ' meals')
                            }
                            leftButton={{ label: 'Edit', onClick: () => props.history.push('/app/applications/meals/settings/plans/' + plan.id) }}
                            rightButton={{
                                icon: <DeleteIcon />,
                                label: 'Delete',
                                onClick: () => setDeletePlanDialog({ open: true, id: plan.id, name: plan.name }),
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <Dialog open={newMealDialog.open} fullWidth onClose={() => setNewMealDialog({ ...newMealDialog, open: false })}>
                <DialogTitle onClose={() => setNewMealDialog({ ...newMealDialog, open: false })}>{newMealDialog.edit ? 'Edit Meal' : 'New Meal'}</DialogTitle>
                <DialogContent dividers>
                    {newMealDialog.open && (
                        <>
                            <FormControl variant="outlined" fullWidth required margin="dense">
                                <InputLabel id="meal-day-select">Day</InputLabel>
                                <Select
                                    native
                                    labelId="meal-day-select"
                                    id="meal-day-select"
                                    value={newMealDialog.dow}
                                    onChange={e => setNewMealDialog({ ...newMealDialog, dow: parseInt(e.target.value) })}
                                    label="Day *"
                                >
                                    <option value={0}>Sunday</option>
                                    <option value={1}>Monday</option>
                                    <option value={2}>Tuesday</option>
                                    <option value={3}>Wednesday</option>
                                    <option value={4}>Thursday</option>
                                    <option value={5}>Friday</option>
                                    <option value={6}>Saturday</option>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" fullWidth required margin="dense">
                                <InputLabel id="meal-meal-select">Meal</InputLabel>
                                <Select
                                    native
                                    labelId="meal-meal-select"
                                    id="meal-meal-select"
                                    value={newMealDialog.mod}
                                    onChange={e => setNewMealDialog({ ...newMealDialog, mod: parseInt(e.target.value) })}
                                    label="Meal *"
                                >
                                    <option value={0}>Breakfast</option>
                                    <option value={1}>Lunch</option>
                                    <option value={2}>Dinner</option>
                                </Select>
                            </FormControl>
                            <Typography align="center" variant="h6" style={{ fontWeight: 'bold', marginTop: '0.35em' }}>
                                Pickup Times
                            </Typography>
                            {newMealDialog.pickups.map((pickup, index) => (
                                <>
                                    <Box style={{ display: 'flex', flexDirection: 'row' }} key={'Pickup_' + index}>
                                        <Grid container spacing={1} style={{ flexGrow: 1 }}>
                                            <Grid item xs={6}>
                                                <FormControl variant="outlined" fullWidth margin="dense">
                                                    <InputLabel id={'Pickup_Cutoff_' + index}>Cutoff</InputLabel>
                                                    <Select
                                                        native
                                                        labelId={'Pickup_Cutoff_' + index}
                                                        label="Cutoff"
                                                        value={pickup.cutoff}
                                                        onChange={e => {
                                                            let curPickups = [...newMealDialog.pickups]
                                                            curPickups[index].cutoff = parseInt(e.target.value)
                                                            setNewMealDialog({ ...newMealDialog, pickups: curPickups })
                                                        }}
                                                    >
                                                        {Object.keys(cutoffs).map(cutoff => (
                                                            <option value={cutoff}>{cutoffs[cutoff]}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl variant="outlined" fullWidth margin="dense">
                                                    <InputLabel id={'Pickup_Time_' + index}>Time</InputLabel>
                                                    <Select
                                                        native
                                                        labelId={'Pickup_Time_' + index}
                                                        label="Time"
                                                        value={pickup.time}
                                                        onChange={e => {
                                                            let curPickups = [...newMealDialog.pickups]
                                                            curPickups[index].time = parseInt(e.target.value)
                                                            setNewMealDialog({ ...newMealDialog, pickups: curPickups })
                                                        }}
                                                    >
                                                        {Object.keys(cutoffs).map(cutoff => (
                                                            <option value={cutoff}>{cutoffs[cutoff]}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            style={{
                                                margin: 4,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                justifyContent: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Box>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        let curPickups = [...newMealDialog.pickups]
                                                        curPickups.splice(index, 1)
                                                        setNewMealDialog({ ...newMealDialog, pickups: curPickups })
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {!atLeastSmall && index < newMealDialog.pickups.length - 1 && (
                                        <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                                    )}
                                </>
                            ))}
                        </>
                    )}
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <ButtonCore
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() => {
                                let curPickups = [...newMealDialog.pickups]
                                curPickups.push({ time: 12 * 60, cutoff: 11 * 60 })
                                setNewMealDialog({ ...newMealDialog, pickups: curPickups })
                            }}
                            size="large"
                            disabled={!newMealDialog.pickups || newMealDialog.pickups.length >= 30}
                            style={{ marginTop: 4, marginBottom: 4 }}
                        >
                            Add Pickup
                        </ButtonCore>
                    </Box>
                    {newMealDialog.error && <ErrorTypography text={newMealDialog.error} isCentered={true} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewMealDialog({ ...newMealDialog, open: false })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => updateMeal()} color="primary" disabled={newMealDialog.loading}>
                        {newMealDialog.edit ? 'Update' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deletePlanDialog.open} onClose={() => setDeletePlanDialog({ open: false })}>
                <DialogTitle>Delete plan?</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Are you sure you want to delete "<span style={{ fontWeight: 700 }}>{deletePlanDialog.name ? deletePlanDialog.name : ''}</span>
                        "?
                    </DialogContentText>
                    {deletePlanDialog.error && <ErrorTypography text={deletePlanDialog.error} isCentered={true} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeletePlanDialog({ open: false })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deletePlan(deletePlanDialog.id)} color="red" disabled={deletePlanDialog.loading}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
