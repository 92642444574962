/**
 * Checks if a user has admin permissions for a specific feature
 * @param {Object} chapter - The chapter object containing members and permissions
 * @param {Object} user - The user object to check permissions for
 * @param {string} featureKey - The feature permission key to check (e.g. 'rushAdmin', 'financeAdmin')
 * @returns {boolean} Whether the user has admin permissions for the feature
 */
export const hasFeaturePermission = (chapter, user, featureKey) => {
    // Return false if any required params are missing
    if (!chapter?.members || !user || !featureKey) {
        return false;
    }

    const member = chapter.members[user.getId()];
    if (!member) {
        return false;
    }

    // Check if user is global admin or has specific feature permission
    return member.role === 'ADMIN' || 
           (chapter.perms?.[featureKey]?.includes(member.role) ?? false);
}

// Use the generic permission check for rush admin
export const isUserRushAdmin = (chapter, user) => {
    return hasFeaturePermission(chapter, user, 'rushAdmin');
}