import React, { useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  Chip,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Rating,
  Paper,
  Tab,
  Tabs,
  LinearProgress,
  Button
} from '@mui/material';
import {
  Event as EventIcon,
  Comment as CommentIcon,
  LocationOn as LocationIcon,
  Person as PersonIcon,
  StarBorder as StarBorderIcon,
} from '@material-ui/icons';
import useStyles from '../styles';
import { useGetRushee } from 'hooks/queries/rush/useGetRushee';
import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
const CandidateDetails = ({ rushId, rusheeId }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { user } = useContext(AuthContext)
  const chapterId = user?.getChapter()
  console.log(`chapterId: ${chapterId} rushId: ${rushId} rusheeId: ${rusheeId} information`)

  const { data: rusheeDetails, isLoading: isLoadingRusheeDetails, isError: isErrorRusheeDetails } = useGetRushee({
    chapterId,
    rushId,
    rusheeId,
  })
  console.log(`rusheeDetails: ${JSON.stringify(rusheeDetails)}`)
  // const classes = useStyles()
  // Sample data - would come from props in real implementation
  const candidateData = {
    id: 1,
    name: "Dwight Schrute",
    year: "1st year",
    major: "Agritourism",
    location: "Scranton, PA",
    username: "rjimwilson",
    fratName: "Jimbo",
    events: [
      { name: "Rush night 1", date: "2024-01-15", attended: true },
      { name: "Rush night 2", date: "2024-01-16", attended: false },
      { name: "Rush night 3", date: "2024-01-17", pending: true }
    ],
    ratings: {
      fratiness: 4.2,
      friendliness: 4.8
    },
    comments: [
      {
        author: "Kyle Tao",
        date: "2024-01-15",
        text: "I would vote to give him a bid",
        avatar: "/api/placeholder/32/32"
      }
    ]
  };

  if (isLoadingRusheeDetails) {
    return <div>Loading...</div>
  }

  if (isErrorRusheeDetails) {
    return <div>Error loading rushee details</div>
  }

  return (
    <Box>
        <Typography variant="h4" mb={2}>Candidate Profile</Typography>
        <Grid container spacing={3}>
          {/* Header Section */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Avatar 
                sx={{ width: 80, height: 80 }}
                src={rusheeDetails?.photoURL}
              />
              <Box>
                <Typography variant="h5">{rusheeDetails?.first} {rusheeDetails?.last}</Typography>
                <Typography color="textSecondary">
                  {rusheeDetails?.major} • {rusheeDetails?.year} year
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <Chip 
                    icon={<LocationIcon />} 
                    label={rusheeDetails?.hometown} 
                    size="small" 
                    variant="outlined"
                  />
                  <Chip 
                    icon={<PersonIcon />} 
                    label={rusheeDetails?.username} 
                    size="small" 
                    variant="outlined"
                  />
                </Box>
                <Box>
                  Instagram: 
                <Chip 
                    icon={<PersonIcon />} 
                    label={rusheeDetails?.instagram} 
                    size="small" 
                    variant="outlined"
                  />
                   Snap: 
                <Chip 
                    icon={<PersonIcon />} 
                    label={rusheeDetails?.snapchat} 
                    size="small" 
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Navigation Tabs */}
          <Grid item xs={12}>
            <Tabs 
              value={currentTab} 
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="Overview" />
              <Tab label="Events" />
              <Tab label="Ratings & Comments" />
            </Tabs>
          </Grid>

          {/* Tab Content */}
          <Grid item xs={12}>
            {currentTab === 0 && (
              <Box>
                <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                  <Typography variant="h6" gutterBottom>Quick Stats</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" color="textSecondary">Events Attended</Typography>
                      <Typography variant="h4">{rusheeDetails?.eventsAttended?.length}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" color="textSecondary">Overall Rating</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Rating value={4.5} precision={0.5} readOnly />
                        <Typography variant="body2" sx={{ ml: 1 }}>4.5/5</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>

                <Paper elevation={1} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Rankings</Typography>
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2">Fratiness</Typography>
                      <Typography variant="body2">4.2/5</Typography>
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={84} 
                      sx={{ height: 8, borderRadius: 4 }}
                    />
                  </Box>
                  <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2">Friendliness</Typography>
                      <Typography variant="body2">4.8/5</Typography>
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={96} 
                      sx={{ height: 8, borderRadius: 4 }}
                    />
                  </Box>
                </Paper>
              </Box>
            )}

            {currentTab === 1 && (
              <List>
                {candidateData.events.map((event, index) => (
                  <ListItem 
                    key={index}
                    secondaryAction={
                      event.pending ? (
                        <Chip label="Upcoming" color="info" size="small" />
                      ) : (
                        <Chip 
                          label={event.attended ? "Attended" : "Missed"} 
                          color={event.attended ? "success" : "error"}
                          size="small"
                        />
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <EventIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={event.name}
                      secondary={event.date}
                    />
                  </ListItem>
                ))}
              </List>
            )}

            {currentTab === 2 && (
              <Box>
                <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                  <Typography variant="h6" gutterBottom>Member Comments</Typography>
                  <List>
                    {candidateData.comments.map((comment, index) => (
                      <ListItem key={index} alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar src={comment.avatar} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="subtitle2">{comment.author}</Typography>
                              <Typography variant="caption" color="textSecondary">
                                {comment.date}
                              </Typography>
                            </Box>
                          }
                          secondary={comment.text}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>

                <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    startIcon={<CommentIcon />}
                  >
                    Add Comment
                  </Button>
                  <Button 
                    variant="outlined"
                    startIcon={<StarBorderIcon />}
                  >
                    Update Rating
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
  );
};

export default CandidateDetails;