import { useEffect } from 'react'
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'
import PropTypes from 'prop-types'
import { Capacitor } from '@capacitor/core'

export function useStatusBar({ 
  isDarkMode, 
}) {
  useEffect(() => {
    const isNative = Capacitor?.isNativePlatform?.()
    if (!isNative) return

    const style = isDarkMode ? StatusBarStyle.Dark : StatusBarStyle.Light
    StatusBar.setStyle({ style })

    return () => StatusBar.setStyle({ style: StatusBarStyle.Dark })
  }, [isDarkMode])
}

useStatusBar.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
}
