import React from 'react'

import { PopupModal } from 'react-calendly'

import app from 'firebase/app'

import MuiPhoneNumber from 'material-ui-phone-number'

import moment from 'moment-timezone'

import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Divider,
    Fade,
    FormControl,
    Grid,
    InputLabel,
    MobileStepper,
    Select,
    Slide,
    TextField,
    Typography,
} from '@material-ui/core'

import {
    ArrowForwardIosRounded as ArrowRightIcon,
    CalendarTodayRounded as CalendarIcon,
    VideocamOutlined as CallIcon,
    KeyboardArrowRightRounded as KeyboardArrowRightIcon,
    FastfoodOutlined as MealsIcon,
    ContactsOutlined as MembersIcon,
    ForumOutlined as MessagesIcon,
    TimelineRounded as PointsIcon,
    PollOutlined as PollsIcon,
    WhatshotOutlined as RushIcon,
    AssignmentOutlined as TasksIcon,
    AccessTime as TimeIcon,
} from '@material-ui/icons'
import { BoyIcon, DriveIcon, GirlIcon, ManageAccountsOutlinedIcon as ManageMembersIcon } from 'components/Icons'
import { useCalendlyEventListener } from 'react-calendly'
import ToggleItem, { ButtonLargeItem } from './components/ToggleItem'
import appIcon from '../login/assets/app_icon.png'
import { apiPrefix } from 'code/Helper'
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'
import SwipeableViews from 'react-swipeable-views'
import { TRANSITION_MEDIUM_LENGTH } from 'data/Constants'
import { subscriptionOptions as _subscriptionOptions, convertSubscriptionOptionsToObject } from './data'
import { useStatusBar } from 'hooks/capacitor/useStatusBar'
import axios from 'axios'

const Setup = props => {
    const classes = useStyles()
    const theme = useTheme()
    const db = app.firestore()
    const searchParams = new URLSearchParams(props.location.search)
    const [subscriptionOptions] = React.useState(convertSubscriptionOptionsToObject(_subscriptionOptions))
    const [popupOpen, setPopupOpen] = React.useState(false)
    useStatusBar({ isDarkMode: theme.palette.type === 'dark' })

    const getDefaultSetupData = () => {
        const data = {
            step: 0,
            contactDetails: {
                first: '',
                last: '',
                email: '',
                phone: '',
                title: '',
            },
            enabledFeatures: [],
            chapterDetails: {
                gender: null,
                organizationName: '',
                chapterName: '',
                school: '',
                timezone: 'America/New_York',
                roll: false,
                size: _subscriptionOptions[3].id,
            },
            miscDetails: {
                source: '',
            },
            /*enabledFeatures: ['calendar', 'rush'],
            contactDetails: {
                first: 'Hunter',
                last: 'Copp',
                email: 'hunter@greekconnect.app',
                phone: '+1 (404) 580-5068',
                title: 'VP',
            },
            chapterDetails: {
                gender: 'fraternity',
                organizationName: 'Delta Tau Chi',
                chapterName: 'Alpha Beta',
                school: 'Georgia',
                timezone: 'America/New_York',
                size: _subscriptionOptions[3].id,
            },
            miscDetails: {
                source: 'Search engine',
            },*/
        }

        let searchEmail = searchParams.get('email')

        if (!!searchEmail && typeof searchEmail === 'string') {
            data.contactDetails.email = searchEmail
        }

        return data
    }

    const [quoteId, setQuoteId] = React.useState(localStorage.getItem('quoteId'))
    const [quote, setQuote] = React.useState(null)

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => {
            const data = { url: e.data.payload.event.uri }
            axios.put(`${apiPrefix}/v2/quote/${quoteId}`, data).catch(e => console.log('unable to update calendly', e))
        },
    })

    const [setup, setSetup] = React.useState(getDefaultSetupData())

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    const submitQuote = async () => {
        setLoading(true)

        let data = {
            contactDetails: { ...setup.contactDetails },
            chapterDetails: { ...setup.chapterDetails },
            enabledFeatures: [...setup.enabledFeatures],
            miscDetails: { ...setup.miscDetails },
        }

        data.chapterDetails.price = data.chapterDetails.size
        data.chapterDetails.size = subscriptionOptions[data.chapterDetails.price].label

        axios
            .post(`${apiPrefix}/v2/quote`, data)
            .then(response => {
                const action = async () => {
                    try {
                        // Response should give us an id for the quote and then we can set up the data
                        // Signing in
                        /*if (setup.contactDetails.uid) {
                            await logInUser({ uid: setup.contactDetails.uid })
                        } else {
                            localStorage.setItem('cache_ignoreInfo', true)
                            await app.auth().signInWithEmailAndPassword(setup.contactDetails.email, setup.contactDetails.password)
                        }
        
                        const chapterSetupDetails = {
                            ts: new Date().getTime(),
                            complete: [],
                        }
        
                        localStorage.setItem('chapter_setup', JSON.stringify(chapterSetupDetails))
                        setLoading(false)*/
                        const quoteId = response.data
                        setPopupOpen(true)
                        setSetup(setup => ({ ...setup, step: setup.step + 1 }))
                        setQuoteId(quoteId)
                        localStorage.setItem('quoteId', quoteId)
                        setLoading(false)
                    } catch (e) {
                        setError('Created quote but unable to schedule an onboarding success call')
                    }
                }

                action()
            })
            .catch(e => {
                // Unable to create chapter
                setLoading(false)
                console.log('got error', e)
                if (typeof e.response?.data?.error === 'string') {
                    setError(e.response.data.error)
                } else {
                    setError('Unable to create your chapter, please try again or contact support at contact@greekconnect.app if the problem persists')
                }
            })
    }

    const missingContactDetails = () => {
        const details = setup.contactDetails
        return !details.first || !details.last || !details.email || !details.phone || details.phone.length < 12 || !details.title || !setup.miscDetails.source
    }

    const missingChapterDetails = () => {
        const details = setup.chapterDetails
        return !details.gender || !details.organizationName || !details.chapterName || !details.timezone || !details.school
    }

    const updateSetup = (field, value) => setSetup(setup => ({ ...setup, [field]: value }))

    const toggleListItem = (field, value) => {
        setSetup(setup => {
            if (setup[field].includes(value)) {
                return { ...setup, [field]: setup[field].filter(val => val !== value) }
            }
            return { ...setup, [field]: [...setup[field], value] }
        })
    }

    const getMeetingTime = (start, end) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const startMoment = moment.tz(start, timezone)
        const endMoment = moment.tz(end, timezone)

        return `${startMoment.format('h:mm a')} - ${endMoment.format('h:mm a')} ${startMoment.format('z, dddd, MMMM D, YYYY')}`
    }

    const handleNext = () => {
        let step = 1

        if (setup.step === numSteps - 2) {
            // setPopupOpen(true)
            submitQuote()
        } else {
            setSetup(setup => ({ ...setup, step: setup.step + step }))
        }
    }

    const steps = [
        {
            id: 'introduction',
            view: (
                <>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 16, marginBottom: 8 }}>
                        <img src={appIcon} alt="" className={classes.appIcon} />
                    </Box>
                    <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 42, fontWeight: 'bold' }} align="center">
                                <span role="img" aria-label="Wave">
                                    👋
                                </span>{' '}
                                Howdy!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 24, fontWeight: 'bold' }} align="center">
                                Welcome to Greek Connect
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 24, fontWeight: 'bold', cursor: 'pointer' }} align="center" onClick={handleNext}>
                                Let's get started
                                <ArrowRightIcon style={{ verticalAlign: 'sub', marginRight: -8 }} />
                                <ArrowRightIcon style={{ verticalAlign: 'sub', marginLeft: -8, marginRight: -8 }} />
                                <ArrowRightIcon style={{ verticalAlign: 'sub', marginLeft: -8 }} />
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            ),
            complete: true,
        },
        {
            id: 'contact_details',
            view: (
                <Container maxWidth="md" className={classes.scrollableContainer}>
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 16, height: '100%' }}>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }} align="center">
                            What are your contact details?
                        </Typography>
                        <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                        <Box className={classes.enabledFeatures}>
                            <Box style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                                <TextField
                                    fullWidth
                                    label="First"
                                    variant="outlined"
                                    margin="dense"
                                    type="text"
                                    autoComplete="given-name"
                                    required
                                    style={{ margin: 0 }}
                                    value={setup.contactDetails.first}
                                    onChange={e => setSetup(setup => ({ ...setup, contactDetails: { ...setup.contactDetails, first: e.target.value } }))}
                                />
                                <TextField
                                    fullWidth
                                    label="Last"
                                    variant="outlined"
                                    margin="dense"
                                    type="text"
                                    autoComplete="family-name"
                                    required
                                    style={{ margin: 0 }}
                                    value={setup.contactDetails.last}
                                    onChange={e => setSetup(setup => ({ ...setup, contactDetails: { ...setup.contactDetails, last: e.target.value } }))}
                                />
                            </Box>
                            <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                margin="dense"
                                type="email"
                                autoComplete="email"
                                required
                                style={{ margin: 0 }}
                                value={setup.contactDetails.email}
                                onChange={e => setSetup(setup => ({ ...setup, contactDetails: { ...setup.contactDetails, email: e.target.value } }))}
                            />
                            <MuiPhoneNumber
                                defaultCountry={'us'}
                                variant="outlined"
                                margin="dense"
                                label="Phone"
                                type="text"
                                required
                                inputProps={{
                                    maxLength: 32,
                                }}
                                onChange={value => {
                                    setSetup(setup => ({ ...setup, contactDetails: { ...setup.contactDetails, phone: value } }))
                                }}
                                value={setup.contactDetails.phone}
                                fullWidth
                            />
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                margin="dense"
                                type="text"
                                placeholder="ex. President, Vice President, Treasurer, ..."
                                required
                                style={{ margin: 0 }}
                                value={setup.contactDetails.title}
                                onChange={e => setSetup(setup => ({ ...setup, contactDetails: { ...setup.contactDetails, title: e.target.value } }))}
                            />
                            <TextField
                                fullWidth
                                label="How did you hear about us?"
                                variant="outlined"
                                margin="dense"
                                type="text"
                                required
                                style={{ margin: 0 }}
                                value={setup.miscDetails.source}
                                onChange={e => setSetup(setup => ({ ...setup, miscDetails: { ...setup.miscDetails, source: e.target.value } }))}
                            />
                        </Box>
                    </Box>
                </Container>
            ),
            complete: !missingContactDetails(),
        },
        {
            id: 'chapter_details',
            view: (
                <Container maxWidth="md" className={classes.scrollableContainer}>
                    <Box className={classes.scrollableBox}>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }} align="center">
                            What are your chapter details?
                        </Typography>
                        <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                        <Box className={classes.enabledFeatures}>
                            <Box style={{ display: 'flex', flexDirection: 'row', gap: 16, height: /*42*/ 122, alignItems: 'center' }}>
                                <ButtonLargeItem
                                    title="Fraternity"
                                    icon={<BoyIcon />}
                                    selected={setup.chapterDetails.gender === 'fraternity'}
                                    onClick={e => setSetup(setup => ({ ...setup, chapterDetails: { ...setup.chapterDetails, gender: 'fraternity' } }))}
                                />
                                <ButtonLargeItem
                                    title="Sorority"
                                    icon={<GirlIcon />}
                                    selected={setup.chapterDetails.gender === 'sorority'}
                                    onClick={e => setSetup(setup => ({ ...setup, chapterDetails: { ...setup.chapterDetails, gender: 'sorority' } }))}
                                />
                            </Box>
                            <Fade timeout={TRANSITION_MEDIUM_LENGTH} in={setup.chapterDetails.gender !== null}>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                                    <TextField
                                        fullWidth
                                        label={
                                            setup.chapterDetails.gender === null
                                                ? 'Organization Name'
                                                : setup.chapterDetails.gender === 'fraternity'
                                                ? 'Fraternity Name'
                                                : 'Sorority Name'
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        type="text"
                                        placeholder="ex. Sigma Chi, Farmhouse, Alpha Xi Delta, ..."
                                        style={{ margin: 0 }}
                                        value={setup.chapterDetails.organizationName}
                                        onChange={e =>
                                            setSetup(setup => ({ ...setup, chapterDetails: { ...setup.chapterDetails, organizationName: e.target.value } }))
                                        }
                                    />
                                    <TextField
                                        fullWidth
                                        label="School"
                                        variant="outlined"
                                        margin="dense"
                                        type="text"
                                        placeholder="ex. Georgia Tech, Stanford, ..."
                                        style={{ margin: 0 }}
                                        value={setup.chapterDetails.school}
                                        onChange={e => setSetup(setup => ({ ...setup, chapterDetails: { ...setup.chapterDetails, school: e.target.value } }))}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Chapter Name"
                                        variant="outlined"
                                        margin="dense"
                                        type="text"
                                        placeholder="ex. Delta Beta, Alpha, Beta Psi, ..."
                                        style={{ margin: 0 }}
                                        value={setup.chapterDetails.chapterName}
                                        onChange={e =>
                                            setSetup(setup => ({ ...setup, chapterDetails: { ...setup.chapterDetails, chapterName: e.target.value } }))
                                        }
                                    />
                                    <FormControl fullWidth variant="outlined" size="small" style={{ margin: 0 }}>
                                        <InputLabel id="timezone-select-label">Timezone</InputLabel>
                                        <Select
                                            native
                                            label="Timezone"
                                            labelId="timezone-select-label"
                                            variant="outlined"
                                            value={setup.chapterDetails.timezone}
                                            onChange={e =>
                                                setSetup(setup => ({ ...setup, chapterDetails: { ...setup.chapterDetails, timezone: e.target.value } }))
                                            }
                                        >
                                            <option value="Pacific/Honolulu">Hawaii</option>
                                            <option value="America/Anchorage">Alaska</option>
                                            <option value="America/Los_Angeles">Pacific</option>
                                            <option value="America/Phoenix">Arizona</option>
                                            <option value="America/Denver">Mountain</option>
                                            <option value="America/Chicago">Central</option>
                                            <option value="America/New_York">Eastern</option>
                                        </Select>
                                    </FormControl>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            width: '100%',
                                            alignItems: 'center',
                                            marginBottom: 16,
                                        }}
                                    >
                                        <Typography>Number of Members</Typography>
                                        <ButtonGroup variant="outlined">
                                            <Button
                                                disabled={
                                                    !(setup.chapterDetails.size in subscriptionOptions) || !subscriptionOptions[setup.chapterDetails.size].prev
                                                }
                                                onClick={() =>
                                                    setSetup(setup => ({
                                                        ...setup,
                                                        chapterDetails: { ...setup.chapterDetails, size: subscriptionOptions[setup.chapterDetails.size].prev },
                                                    }))
                                                }
                                                color="primary"
                                            >
                                                -
                                            </Button>
                                            <Button disabled style={{ border: '1px solid rgba(25, 118, 210, 0.5)', color: '#1976d2' }}>
                                                {setup.chapterDetails.size in subscriptionOptions ? subscriptionOptions[setup.chapterDetails.size].label : ''}
                                            </Button>
                                            <Button
                                                disabled={
                                                    !(setup.chapterDetails.size in subscriptionOptions) || !subscriptionOptions[setup.chapterDetails.size].next
                                                }
                                                onClick={() =>
                                                    setSetup(setup => ({
                                                        ...setup,
                                                        chapterDetails: { ...setup.chapterDetails, size: subscriptionOptions[setup.chapterDetails.size].next },
                                                    }))
                                                }
                                                color="primary"
                                            >
                                                +
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            </Fade>
                        </Box>
                    </Box>
                </Container>
            ),
            complete: !missingChapterDetails(),
        },
        {
            id: 'features',
            view: (
                <Container maxWidth="md" className={classes.scrollableContainer}>
                    <Box className={classes.scrollableBox}>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }} align="center">
                            Which features are you interested in using?
                        </Typography>
                        <Typography style={{ fontSize: 18, paddingTop: -12 }} align="center">
                            You can toggle features later
                        </Typography>
                        {error && (
                            <Typography style={{ fontSize: 18, paddingTop: -12, color: '#E66868' }} align="center">
                                {error}
                            </Typography>
                        )}
                        <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                        <Box className={classes.enabledFeatures}>
                            <ToggleItem
                                icon={<CalendarIcon />}
                                title="Calendar"
                                subtitle="track attendance, excuses, rsvp's, guests, and sync with external calendars"
                                checked={setup.enabledFeatures.includes('calendar')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'calendar')
                                }}
                            />
                            <ToggleItem
                                icon={<MembersIcon />}
                                title="Members"
                                subtitle="search, filter, and sort for members and save contacts to your device"
                                checked={setup.enabledFeatures.includes('members')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'members')
                                }}
                            />
                            <ToggleItem
                                icon={<PollsIcon />}
                                title="Polls"
                                subtitle="secure voting with attendance checks, easy form creation, and sharable links"
                                checked={setup.enabledFeatures.includes('polls')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'polls')
                                }}
                            />
                            <ToggleItem
                                icon={<MessagesIcon />}
                                title="Messages"
                                subtitle="create chats and channels with automatic membership filters and admin settings"
                                checked={setup.enabledFeatures.includes('messages')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'messages')
                                }}
                            />
                            <ToggleItem
                                icon={<DriveIcon />}
                                title="Drive"
                                subtitle="share organized files and links with built-in permission handling"
                                checked={setup.enabledFeatures.includes('drive')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'drive')
                                }}
                            />
                            <ToggleItem
                                icon={<ManageMembersIcon />}
                                title="Admin"
                                subtitle="manage your chapter's members with permission and account management"
                                checked={setup.enabledFeatures.includes('admin')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'admin')
                                }}
                            />
                            <ToggleItem
                                icon={<PointsIcon />}
                                title="Points"
                                subtitle="track participation, give points based on event attendance, and review requests"
                                checked={setup.enabledFeatures.includes('points')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'points')
                                }}
                            />
                            <ToggleItem
                                icon={<TasksIcon />}
                                title="Tasks"
                                subtitle="create weekly tasks with checklists, notifications, and manual or automatic approval"
                                checked={setup.enabledFeatures.includes('tasks')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'tasks')
                                }}
                            />
                            <ToggleItem
                                icon={<MealsIcon />}
                                title="Meal Plan"
                                subtitle="view weekly menu, order late plates, and set dietary restrictions"
                                checked={setup.enabledFeatures.includes('meals')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'meals')
                                }}
                            />
                            <ToggleItem
                                icon={<RushIcon />}
                                title="Rush"
                                subtitle="comment and vote on rushees, add details, and track conversations"
                                checked={setup.enabledFeatures.includes('rush')}
                                onChange={() => {
                                    toggleListItem('enabledFeatures', 'rush')
                                }}
                            />
                        </Box>
                    </Box>
                </Container>
            ),
            complete: true,
        },
        {
            id: 'schedule_success_call',
            view: (
                <>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 16, marginBottom: 8 }}>
                        <img src={appIcon} alt="" className={classes.appIcon} />
                    </Box>
                    <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 42, fontWeight: 'bold' }} align="center">
                                Chapter Created{' '}
                                <span role="img" aria-label="">
                                    🥳
                                </span>
                            </Typography>
                        </Grid>
                        {!quote ||
                            (!quote.calendly && (!quote.chapter || !quote.chapter.activationCode) && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }} align="center">
                                            One Last Step
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: 16 }} align="center">
                                            Schedule a 15 minute onboarding success call
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            onClick={() => setPopupOpen(true)}
                                            disableElevation
                                            color="primary"
                                            style={{ margin: '0 auto', display: 'flex' }}
                                        >
                                            Schedule
                                        </Button>
                                    </Grid>
                                </>
                            ))}
                        {quote && quote.calendly && (!quote.chapter || !quote.chapter.activationCode) && (
                            <>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: 24, fontWeight: 500 }} align="center">
                                        Call scheduled
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        style={{
                                            border: theme.border,
                                            borderRadius: '4px',
                                            maxWidth: 512,
                                            margin: '0 auto',
                                            padding: 12,
                                            display: 'flex',
                                            gap: 8,
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography style={{ fontSize: 20, fontWeight: 'semibold' }} align="center">
                                            {quote.calendly.name}
                                        </Typography>
                                        <Typography>
                                            <TimeIcon style={{ fontSize: '1.15em', height: '1em', width: '1em', verticalAlign: 'middle', marginRight: 4 }} />
                                            {getMeetingTime(quote.calendly.start_time, quote.calendly.end_time)}
                                        </Typography>
                                        {quote.calendly.location.join_url && (
                                            <Typography>
                                                <CallIcon
                                                    style={{ fontSize: '1.15em', height: '1em', width: '1em', verticalAlign: 'middle', marginRight: 4 }}
                                                />
                                                Google Meet
                                            </Typography>
                                        )}
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            color="primary"
                                            style={{ margin: '0 auto' }}
                                            href={quote.calendly.location.join_url}
                                            target="_blank"
                                        >
                                            Join call
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        {quote && quote.chapter && quote.chapter.activationCode && (
                            <>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }} align="center">
                                        Create your account
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: 16 }} align="center">
                                        Click below to activate your account using code
                                        <br />
                                        <span style={{ fontWeight: 'bold' }}>{quote.chapter.activationCode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        href={`/join/${quote.chapter.activationCode}`}
                                        disableElevation
                                        color="primary"
                                        style={{ margin: '0 auto', maxWidth: 384, display: 'flex' }}
                                    >
                                        Create Account
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            ),
        },
    ]

    const features = ['calendar', 'members', 'polls', 'messages', 'drive', 'admin', 'points', 'tasks', 'meals', 'rush']

    const numSteps = steps.length

    React.useEffect(() => {
        if (quoteId !== null) {
            const unsubscribe = db
                .collection('quotes')
                .doc(quoteId)
                .onSnapshot(
                    function(doc) {
                        if (doc.exists) {
                            const data = doc.data()
                            setQuote(data)
                            setSetup({ ...data, step: numSteps - 1 })
                        }
                    },
                    function(error) {
                        console.log('error', error)
                    },
                )
            return () => {
                unsubscribe()
            }
        }
        // eslint-disable-next-line
    }, [quoteId])

    return (
        <>
            <Box
                style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', paddingTop: `env(safe-area-inset-top)` }}>
                    <Box className={classes.viewsBox}>
                        <SwipeableViews
                            axis={'x'}
                            index={setup.step}
                            ignoreNativeScroll
                            onChangeIndex={step => updateSetup('step', step)}
                            disabled
                            containerStyle={{ overflow: 'visible' }}
                            springConfig={{ duration: `0.25s`, easeFunction: 'ease-in-out', delay: '0s' }}
                        >
                            {steps.map((step, index) => (
                                <div key={step.id} style={index === 0 ? null : { height: '100%' }}>
                                    {step.view}
                                </div>
                            ))}
                        </SwipeableViews>
                    </Box>
                </Box>
                <Divider />
                <Slide direction="up" timeout={TRANSITION_MEDIUM_LENGTH} in={setup.step < numSteps - 1} appear={false} unmountOnExit>
                    <MobileStepper
                        variant="progress"
                        steps={numSteps}
                        position="static"
                        activeStep={setup.step >= numSteps - 1 ? numSteps - 1 : setup.step}
                        className={classes.stepper}
                        backButton={<Box style={{ width: 78 }}></Box>}
                        nextButton={
                            <Box style={{ width: 78, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                {setup.step < numSteps - 1 && (
                                    <Button size="small" onClick={handleNext} disabled={!steps[setup.step].complete || loading}>
                                        {setup.step === numSteps - 2 ? 'Finish' : 'Next'}
                                        <KeyboardArrowRightIcon />
                                    </Button>
                                )}
                            </Box>
                        }
                    />
                </Slide>
                <Box
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `linear-gradient(0deg, #0b6abe 0%, #3493e7 100%)`,
                        opacity: setup.step === numSteps ? 1 : 0,
                        transition: 'opacity 0.25s linear',
                        zIndex: -1,
                    }}
                />
                <Box
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -2,
                    }}
                    className={classes.backgroundPaper}
                />
            </Box>
            <PopupModal
                url="https://calendly.com/greekconnect/onboarding"
                pageSettings={{
                    backgroundColor: 'transparent',
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    primaryColor: '1976d2',
                    textColor: '000000',
                }}
                prefill={{
                    name: `${setup.contactDetails.first} ${setup.contactDetails.last}`,
                    firstName: setup.contactDetails.first,
                    lastName: setup.contactDetails.last,
                    email: setup.contactDetails.email,
                    phone: setup.contactDetails.phone,
                    customAnswers: {
                        a1: setup.contactDetails.phone,
                        a2: setup.contactDetails.title,
                        a3: `${setup.chapterDetails.organizationName} @ ${setup.chapterDetails.school}`,
                        a4: setup.chapterDetails.size in subscriptionOptions ? subscriptionOptions[setup.chapterDetails.size].label : setup.chapterDetails.size,
                        a5: setup.miscDetails.source,
                        a6: setup.enabledFeatures
                            .map(feature => features.indexOf(feature) + 1)
                            .filter(f => f > 0)
                            .join(','),
                    },
                }}
                rootElement={document.getElementById('root')}
                onModalClose={() => setPopupOpen(false)}
                open={popupOpen}
            />
        </>
    )
}

export default Setup
