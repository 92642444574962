import { useQuery } from '@tanstack/react-query';
import { db } from 'config/firebase'; 
import dateUtils from 'utils/index';
export const useGetCalendarEvents = ({ 
  chapterId, 
  chapter,
  memberStatus, 
  userId, 
  startDate, 
  endDate, 
  limitCount}) => {
  return useQuery({
    queryKey: ['calendar-events', chapterId],
    queryFn: async () => {
      console.log('Query Parameters:', {
        chapterId,
        chapter,
        memberStatus,
        startDate: dateUtils.toFirestore(startDate),
        endDate: dateUtils.toFirestore(endDate)
      });

      if (!chapterId || !memberStatus) {
        throw new Error('Missing required parameters');
      }
      console.log('querying db for chapter events')
      const eventsRef = await db.collection('chapters').doc(chapterId).collection('events')
        .where(`vis.${chapter.members[userId].status}`, '==', true)
        .where(`datesArr`, 'array-contains-any', dateUtils.getDaysBetween(startDate, endDate))
        .limit(limitCount)
        .get();
        
      console.log(dateUtils.getDaysBetween(startDate, endDate))
      return eventsRef.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    },
    // enabled: Boolean(enabled && chapterId && memberStatus && userId),
    onError: (error) => {
      console.error('Calendar events query error:', error);
    }
  });
}; 