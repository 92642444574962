import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';

const VotingCard = ({ candidates }) => {
    return (
        <Card>
            <CardHeader>
              <Typography variant="h6">Candidate Voting</Typography>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                <div className="p-4 border rounded-lg">
                  <h3 className="font-medium mb-4">Current Voting Round</h3>
                  <div className="space-y-4">
                    {candidates.map(candidate => (
                      <div key={candidate.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                        <div>
                          <p className="font-medium">{candidate.name}</p>
                          <p className="text-sm text-gray-500">
                            {candidate.year} • {candidate.major}
                          </p>
                        </div>
                        <div className="flex gap-2">
                          <button className="px-4 py-2 bg-green-500 text-white rounded-lg">
                            Yes
                          </button>
                          <button className="px-4 py-2 bg-red-500 text-white rounded-lg">
                            No
                          </button>
                          <button className="px-4 py-2 border rounded-lg">
                            Abstain
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="p-4 border rounded-lg">
                  <h3 className="font-medium mb-2">Voting Progress</h3>
                  <p className="text-sm text-gray-500">15 of 35 active members have voted</p>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                    <div className="bg-blue-500 h-2.5 rounded-full w-5/12"></div>
                  </div>
                </div>
              </div>
            </CardContent>
        </Card>
    );
}

export default VotingCard;