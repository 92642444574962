import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Link,
} from '@material-ui/core'
import { Button } from 'components/Wrappers'
import DocumentUpload from 'components/DocumentUpload'
import Papa from 'papaparse'

export default function CSVUploadDialog({
    open,
    onClose,
    onSubmit,
    title,
    instructions,
    sampleFileUrl,
    validateData,
    isLoading = false,
    error,
}) {
    const [data, setData] = React.useState(null)
    const [dialogError, setDialogError] = React.useState(null)

    const displayError = error || dialogError

    const parseFile = (parseFile) => {
        if (parseFile) {
            Papa.parse(parseFile, {
                header: true,
                worker: true, // Don't bog down the main thread if its a big file
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: function(results) {
                    let data = results.data
                    
                    if (data.length === 0) {
                        setDialogError("File doesn't have any data to import, make sure it is not blank and that there is a header.")
                        setData(null)
                        return
                    }

                    // If validateData function is provided, use it to validate the data
                    if (validateData) {
                        const { isValid, error } = validateData(data)
                        if (!isValid) {
                            setDialogError(error)
                            setData(null)
                            return
                        }
                    }

                    setData(data)
                    setDialogError(null)
                },
                error: function(error) {
                    setData(null)
                    setDialogError('Unable to read CSV file. Please make sure you have valid CSV format with required headers.')
                },
            })
        } else {
            setData(null)
            setDialogError('Must submit CSV file')
        }
    }

    const handleClose = () => {
        if (!isLoading) {
            setData(null)
            setDialogError(null)
            onClose()
        }
    }

    const handleSubmit = () => {
        if (data) {
            onSubmit(data)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {Array.isArray(instructions) ? (
                    instructions.map((instruction, index) => (
                        <DialogContentText key={index}>{instruction}</DialogContentText>
                    ))
                ) : (
                    <DialogContentText>{instructions}</DialogContentText>
                )}
                
                {sampleFileUrl && (
                    <DialogContentText>
                        <Link href={sampleFileUrl}>
                            Download a sample CSV file here
                        </Link>
                    </DialogContentText>
                )}

                {isLoading ? (
                    <CircularProgress style={{ display: 'block', margin: 'auto' }} />
                ) : (
                    <>
                        <DocumentUpload
                            onUpdateFile={file => parseFile(file)}
                            size="small"
                            multiple={false}
                            fileType="text/csv"
                            fileTypeText=".csv"
                        />
                        {displayError && (
                            <DialogContentText style={{ color: '#E66868' }}>
                                {displayError}
                            </DialogContentText>
                        )}
                        {data && data.length === 1 && (
                            <DialogContentText>You are about to upload 1 record</DialogContentText>
                        )}
                        {data && data.length > 1 && (
                            <DialogContentText>You are about to upload {data.length} records</DialogContentText>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={data === null || isLoading}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    )
} 