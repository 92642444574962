import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    appId: process.env.REACT_APP_ID,
}

// Initialize app once
const firebaseApp = firebase.initializeApp(config)

const db = firebase.firestore()
const storage = firebase.storage()
const auth = firebase.auth()
const analytics = firebase.analytics()
const functions = firebase.functions()

class Firebase {
    constructor() {
        db.enablePersistence({ synchronizeTabs: true })
        .catch(err => {
            console.log('Unable to enable persistence', err)
        })

        firebase.analytics(firebaseApp)
    }
}

export default Firebase
export { db, storage, auth, analytics, functions }
