import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import { ThemeProvider } from '@material-ui/styles'
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { LayoutProvider } from './context/LayoutContext'
import { AuthProvider } from './context/AuthContext'
import { ChapterProvider } from './context/ChapterContext'
import { CrispProvider } from './context/CrispContext'
import { FraternityProvider } from './context/FraternityContext'
import { LocationProvider } from './context/LocationContext'
import { MessagesProvider } from './context/MessagesContext'
import { HeaderProvider } from './context/HeaderContext'
import Firebase, { FirebaseContext } from './components/Firebase'
import { TitleComponent } from './components/TitleComponent/TitleComponent.jsx'
import { ThemeProvider as ThemeChangeProvider, ThemeStateContext } from './context/ThemeContext'
import { CssBaseline } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import config from '../src/config'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './config/queryClient'

import builtinThemes from 'themes'

import { defineCustomElements } from '@ionic/pwa-elements/loader'

import { Capacitor } from '@capacitor/core'
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import { getAppVersion } from 'code/Helper'

import './global.scss'
import { BrowserRouter } from 'react-router-dom'
import { AnalyticsProvider } from 'services/mixpanel/analytics'

axios.defaults.baseURL = config.baseURLApi
axios.defaults.headers.common['Content-Type'] = 'application/json'
const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

if (Capacitor.isPluginAvailable('PushNotifications')) {
    StatusBar.setStyle({
        style: StatusBarStyle.Dark,
    })
}

const checkAppVersion = async () => {
    let appVersion = await getAppVersion()

    if (appVersion) {
        let prevAppVersion = localStorage.getItem('version')

        if (prevAppVersion && prevAppVersion !== appVersion) {
            sessionStorage.setItem('versionUpdated', true)
        }

        localStorage.setItem('version', appVersion)
    }
}

checkAppVersion()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <LocationProvider>
            <AnalyticsProvider>
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                    <ChapterProvider>
                        <FraternityProvider>
                            <CrispProvider>
                                <TitleComponent />
                                <LayoutProvider>
                                    <ThemeChangeProvider>
                                        <ThemeStateContext.Consumer>
                                            {theme => (
                                                <>
                                                    <ThemeProvider theme={theme}>
                                                        <ThemeProviderV5 theme={theme.palette.type === 'dark' ? builtinThemes.darkV5 : builtinThemes.defaultV5}>
                                                            <HeaderProvider>
                                                                <MessagesProvider>
                                                                    <Helmet>
                                                                        <meta
                                                                            name="theme-color"
                                                                            content={
                                                                                theme.palette.type && theme.palette.type === 'dark'
                                                                                    ? theme.palette.background.light
                                                                                    : theme.palette.primary.main
                                                                            }
                                                                            media={`(prefers-color-scheme: ${theme.palette.type ? theme.palette.type : 'light'})`}
                                                                        />
                                                                    </Helmet>
                                                                    <CssBaseline />
                                                                    <BrowserRouter>
                                                                        <App />

                                                                    </BrowserRouter>
                                                                </MessagesProvider>
                                                            </HeaderProvider>
                                                        </ThemeProviderV5>
                                                    </ThemeProvider>
                                                </>
                                            )}
                                        </ThemeStateContext.Consumer>
                                    </ThemeChangeProvider>
                                </LayoutProvider>
                            </CrispProvider>
                        </FraternityProvider>
                    </ChapterProvider>
                    </QueryClientProvider>
                </AuthProvider>
            </AnalyticsProvider>
        </LocationProvider>
    </FirebaseContext.Provider> 
)

defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
