import { useMutation, useQueryClient } from '@tanstack/react-query';
import app from 'firebase/app'

export const useAddRusheeEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ chapterId, rusheeId, eventId, rushId }) => {
      const addRusheeEventFn = app.functions().httpsCallable('addRusheeEvent');

      await addRusheeEventFn({
        chapterId,
        rusheeId,
        rushId,
        eventId,
      });
    },
    onSuccess: () => {
      // Optionally, invalidate queries or update cache here
      queryClient.invalidateQueries(['rusheeEvents']);
    },
    onError: (error) => {
      console.error('Add Rushee Event Error:', {
        code: error.code,
        message: error.message,
        details: error.details
      });
    }
  });
}; 