/**
 * Checks if a rushee attended a specific event
 * @param {Object} rushee - The rushee object containing events dictionary
 * @param {string} eventId - The ID of the event to check
 * @returns {boolean} Whether the rushee attended the event
 */
export const checkRusheeAttendance = (rushee, eventId) => {
    // Handle cases where rushee or events is undefined
    if (!rushee || !rushee.events) {
        return false;
    }

    // Check if eventId exists as a key in the rushee's events object
    return eventId in rushee.events;
}

/**
 * Gets the attendance record for a rushee at a specific event
 * @param {Object} rushee - The rushee object containing events dictionary
 * @param {string} eventId - The ID of the event to check
 * @returns {Object} The attendance record for the rushee at the event
 */
export const getRusheeAttendance = (rushee, eventId) => {
    return rushee?.events?.[eventId];
}


export const bidStatusTypes = [
    { value: 0, label: 'None' },
    { value: 1, label: 'Received' },
    { value: 2, label: 'Accepted' },
    { value: 3, label: 'Denied' },
    { value: 4, label: 'Rejected' },
    { value: 5, label: 'Voted' }
]

export const yearTypes = [
    {
        value: '1st',
        label: '1st',
    },
    {
        value: '2nd',
        label: '2nd',
    },
    {
        value: '3rd',
        label: '3rd',
    },
    {
        value: '4th',
        label: '4th',
    },
    {
        value: '5th+',
        label: '5th+',
    },
]

export const rusheeSource = [
    { value: 0, label: 'Event' },
    { value: 1, label: 'Social Media' },
    { value: 2, label: 'Referral' },
    { value: 3, label: 'Word of Mouth' },
    { value: 4, label: 'Other' },
]

export const majors = [
    "Actuarial Mathematics",
    "Aerospace Engineering",
    "Afroamerican and African Studies",
    "American Culture",
    "Anthropology",
    "Applied Exercise Science",
    "Archaeology",
    "Archaeology of the Ancient Mediterranean",
    "Architecture",
    "Art and Design",
    "Arts and Ideas in the Humanities",
    "Asian Studies",
    "Astronomy and Astrophysics",
    "Biochemistry",
    "Biological Physics",
    "Biology",
    "Biology, Health, and Society",
    "Biomedical Engineering",
    "Biomolecular Science",
    "Biophysics",
    "Biopsychology, Cognition, and Neuroscience",
    "Business",
    "Cellular and Molecular Biomedical Science",
    "Chemical Engineering",
    "Chemistry",
    "Chinese Studies",
    "Civil Engineering",
    "Classical Civilization",
    "Classical Languages and Literatures",
    "Climate and Meteorology",
    "Cognitive Science",
    "Communication and Media",
    "Community and Global Public Health",
    "Comparative Culture and Identity",
    "Comparative Literature",
    "Composition",
    "Computer Engineering",
    "Computer Science",
    "Creative Writing and Literature",
    "Culture and Media",
    "Dance",
    "Data Science",
    "Dental Hygiene",
    "Drama",
    "Earth and Environmental Sciences",
    "Ecology, Evolution, and Biodiversity",
    "Economics",
    "Electrical Engineering",
    "Elementary Teacher Education",
    "Engineering Physics",
    "English",
    "Environment",
    "Environment and Conservation",
    "Environmental Engineering",
    "Ethnic Studies",
    "Film, Television, and Media",
    "French and Francophone Studies",
    "Gender and Health",
    "General Studies",
    "German",
    "Global Environment and Health",
    "Greek (Ancient) Language and Literature",
    "Greek (Modern) Language and Culture",
    "History",
    "History of Art",
    "Honors Mathematics",
    "Human Origins, Biology, and Behavior",
    "Industrial and Operations Engineering",
    "Information Analysis and Design",
    "Interarts Performance",
    "Interdisciplinary Astronomy",
    "Interdisciplinary Chemical Sciences",
    "Interdisciplinary Physics",
    "International Security, Norms, and Cooperation",
    "International Studies",
    "Italian",
    "Japanese Studies",
    "Jazz & Contemporary Improvisation",
    "Judaic Studies",
    "Korean Studies",
    "Latin American and Caribbean Studies",
    "Latin Language and Literature",
    "Latina/Latino Studies",
    "Law, Justice, and Social Change",
    "Learning, Equity, and Problem Solving for the Public Good",
    "Linguistics",
    "Materials Science and Engineering",
    "Mathematical Sciences",
    "Mathematics",
    "Mathematics of Finance and Risk Management",
    "Mechanical Engineering",
    "Medical Anthropology",
    "Microbiology",
    "Middle East Studies",
    "Middle Eastern and North African Studies",
    "Molecular, Cellular, and Developmental Biology",
    "Movement Science",
    "Music",
    "Music Education",
    "Music Theory",
    "Musical Theatre",
    "Musicology",
    "Naval Architecture and Marine Engineering",
    "Neuroscience",
    "Nuclear Engineering and Radiological Sciences",
    "Nursing",
    "Organ",
    "Organizational Studies",
    "Performing Arts Technology",
    "Pharmaceutical Sciences",
    "Philosophy",
    "Philosophy, Politics, and Economics",
    "Physics",
    "Piano",
    "Plant Biology",
    "Polish",
    "Political Economy and Development",
    "Political Science",
    "Politics, Law, and Economy",
    "Power, Identity, and Inequality",
    "Psychology",
    "Public Health Sciences",
    "Public Policy",
    "Pure Mathematics",
    "Robotics",
    "Romance Languages and Literatures",
    "Russian",
    "Russian, East European, and Eurasian Studies",
    "Screenwriting",
    "Secondary Mathematics Teaching Certificate",
    "Secondary Teacher Education",
    "Social Theory and Practice",
    "Sociology",
    "Sociology and Social Work",
    "Sociology of Health and Medicine",
    "South Asian Studies",
    "Southeast Asian Studies",
    "Space Sciences and Engineering",
    "Spanish",
    "Sport Management",
    "Statistics",
    "Strings",
    "Structural Biology",
    "Theatre & Drama",
    "Urban Technology",
    "User Experience Design",
    "Voice & Opera",
    "Winds & Percussion",
    "Women's and Gender Studies",
    "Advertising",
    "Agricultural Science",
    "Animal Science",
    "Artificial Intelligence",
    "Cybersecurity",
    "Digital Marketing",
    "Early Childhood Education",
    "Emergency Management",
    "Entrepreneurship",
    "Fashion Design",
    "Food Science",
    "Forensic Science",
    "Game Design",
    "Genetics",
    "Graphic Design",
    "Hospitality Management",
    "Human Resource Management",
    "Industrial Design",
    "Interior Design",
    "International Business",
    "Journalism",
    "Marine Biology",
    "Marketing",
    "Network Administration",
    "Occupational Therapy",
    "Physical Therapy",
    "Real Estate",
    "Social Media Management",
    "Sports Medicine",
    "Supply Chain Management",
    "Tourism Management",
    "Urban Planning",
    "Veterinary Science",
    "Web Development",
    "Other"
];