import app from 'firebase/app'

// Utility functions for date conversions
const dateUtils = {
    // Convert Firebase Timestamp to JavaScript Date
    fromFirestore: (timestamp) => {
      if (!timestamp) return null;
      return timestamp.toDate();
    },
  
    // Convert JavaScript Date or string to Firebase Timestamp
    toFirestore: (date) => {
      if (!date) return null;
      // Handle both Date objects and string inputs
      return app.firestore.Timestamp.fromDate(new Date(date));
    },
  
    // Format date for display in UI
    formatForDisplay: (date) => {
      if (!date) return '';
      return new Date(date).toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
  
    // Format datetime for display
    formatDateTime: (date) => {
      if (!date) return '';
      return new Date(date).toLocaleString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      });
    },

    getDaysBetween: (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dates = [];
    
        // Ensure the start date is before the end date
        if (start > end) {
            throw new Error('Start date must be before end date');
        }
    
        // Loop through the dates from start to end
        for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
            // Format the date as YYYY-MM-DD
            const formattedDate = date.toISOString().split('T')[0];
            dates.push(formattedDate);
        }
    
        return dates;
    },
  
    // Format for input type="datetime-local"
    formatForInput: (date) => {
      if (!date) return '';
      // Format: YYYY-MM-DDThh:mm
      return new Date(date).toISOString().slice(0, 16);
    }
  };

  export default dateUtils