import React, { useState } from 'react';
import { Box, Grid, Tooltip, Fade } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import { Button, Select, MenuItem, TextField, Chip, Typography } from '@material-ui/core'
import CandidateDetails from './CandidateDetails';
import useStyles from '../styles';
import { Skeleton } from '@mui/material';

export default function CandidatesTab({ rushees, rushId, isLoadingRushees }) {
  const classes = useStyles()
      const [selectedCandidate, setSelectedCandidate] = useState(null);
      const [searchQuery, setSearchQuery] = useState('');
      
      // Filter rushees based on search query
      const filteredRushees = rushees?.filter(rushee => 
        rushee.first.toLowerCase().includes(searchQuery.toLowerCase()) ||
        rushee.last.toLowerCase().includes(searchQuery.toLowerCase()) ||
        rushee.year.toLowerCase().includes(searchQuery.toLowerCase()) ||
        rushee.major.toLowerCase().includes(searchQuery.toLowerCase()) ||
        rushee.hometown.toLowerCase().includes(searchQuery.toLowerCase())
      ) || [];

      if(isLoadingRushees) return <LoadingSkeleton />
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', gap: 2, flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between' }}>
                <Select
                  size="medium"
                  defaultValue="all"
                  md={12}
                >
                  <MenuItem value="all">All Status</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="cut">Cut</MenuItem>
                  <MenuItem value="bid">Bid Extended</MenuItem>
                </Select>
                <Tooltip title="Add New Candidate" placement="top">
                <Button
                  variant="contained"
                  startIcon={<PersonIcon />}
                  md={12}
                >
                 + Add
                </Button>

                </Tooltip>
              </Box>
              <TextField
                placeholder="Search candidates..."
                variant="outlined"
                size="small"
                sx={{ width: 300 }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
  
              />
            </Box>

            
              {/* Left side - Candidate List */}
                {filteredRushees?.map(rushee => (
                  <Box
                    key={rushee.id} elevation={2} sx={{ mb: 2, p: 2 }} className={classes.box} variant='outlined' onClick={() =>{ 
                    setSelectedCandidate(rushee)}}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8} md={8}>
                        <Typography variant="h6">{rushee?.first} {rushee?.last}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {rushee?.year} • {rushee?.major} • {rushee?.hometown}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={4} md={4} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'right'}}>
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse'}}>
                          <StarIcon color="yellow" />
                          <Typography>{rushee?.rating || 'No rating'}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                          {rushee?.tags?.map(tag => (
                            <Chip key={tag} label={tag} size="small" />
                          ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
          </Grid>

          {/* Right side - Candidate Details */}
          <Grid 
                item 
                xs={0}
                md={8}
                sx={{ 
                  display: { 
                    xs: 'none',  // Hidden on mobile
                    md: 'block'  // Visible on medium and larger screens
                  },
                  width: {
                    xs: 0  // Force width to 0 on mobile
                  }
                }}
              >
                <Box
                  className={selectedCandidate ? classes.base : ''}
                  variant='outlined'
                  sx={{
                    display: { 
                      xs: 'none',
                      md: 'block'
                    }
                  }}
                >
                  <Fade in={selectedCandidate !== null} timeout={500}>
                    <Box>
                      {selectedCandidate && (
                        <Fade key={selectedCandidate.id} in={true} timeout={700}>
                          <Box>
                            <CandidateDetails rushId={rushId} rusheeId={selectedCandidate.id } />
                            </Box>
                        </Fade>
                      )}
                    </Box>
                  </Fade>
                </Box>
          </Grid>
        </Grid>
      </>
    )
}

const CandidateDetailSkeleton = () => (
  <Box p={2}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="circle" width={80} height={80} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" width="70%" height={32} />
        <Skeleton variant="text" width="40%" height={24} />
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Skeleton variant="text" width="30%" height={24} />
          <Skeleton variant="rect" height={100} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Skeleton variant="text" width="40%" height={24} />
          <Skeleton variant="rect" height={80} />
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const CandidatesSkeleton = () => (
  <Box>
    {[...Array(6)].map((_, index) => (
      <Box 
        key={index} 
        mb={2}
        p={2}
        border={1}
        borderColor="grey.300"
        borderRadius="borderRadius"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="text" width="60%" height={24} />
            <Skeleton variant="text" width="40%" height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={100} height={36} />
          </Grid>
        </Grid>
      </Box>
    ))}
  </Box>
);

const LoadingSkeleton = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={4}>
      <Box 
        border={1}
        borderColor="grey.300"
        borderRadius="borderRadius"
        height="100%"
      >
        <CandidatesSkeleton />
      </Box>
    </Grid>
    <Grid item xs={12} md={8}>
      <Box 
        border={1}
        borderColor="grey.300"
        borderRadius="borderRadius"
        height="100%"
      >
        <CandidateDetailSkeleton />
      </Box>
    </Grid>
  </Grid>
);
