/**
 * Filters events to find those categorized as "Rush"
 * @param {Object} chapterData - The chapter data object containing events
 * @returns {Array} Array of rush events with their IDs
 */
export const getEventsByCategory = (chapterData, category) => {
    // Return empty array if no chapter data or events
    if (!chapterData?.events) {
        return [];
    }

    // Filter events for those with Rush category
    return Object.entries(chapterData.events)
        .filter(([_, event]) => {
            // Check if event has categories
            if (!Array.isArray(event.categories)) {
                return false;
            }
            
            // Check for Rush category (case insensitive)
            return event.categories.some(c => 
                c.name.toLowerCase() === category.toLowerCase()
            );
        })
        // Transform to array of events with their IDs
        .map(([id, event]) => ({
            id,
            ...event
        }));
}