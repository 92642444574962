import { Add as AddIcon, DeleteOutline, HomeOutlined as HomeIcon, CalendarToday as CalendarIcon } from '@material-ui/icons'
import { GithubPicker } from 'react-color'
import {
    Grid,
    Tooltip,
    CircularProgress,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core'
import React, { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import MuiAlertTitle from '@mui/material/AlertTitle'
import { isMobileDevice } from 'code/Helper'

import { Typography, Button } from 'components/Wrappers'
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import app from 'firebase/app'
import { v4 as uuidv4 } from 'uuid'
// styles
import useStyles from '../styles'

import { colors } from '../mock'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import FormSubmission from 'components/Forms/FormSubmission'

export default function CalendarSettings(props) {
    var classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { chapter, requestChapterUpdate } = React.useContext(ChapterContext)

    const [open, setOpen] = useState(false)
    const [newCategoryColor, setNewCategoryColor] = useState(colors[2])
    const [newCategoryName, setNewCategoryName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorText, setErrorText] = useState(null)

    const [categories, setCategories] = useState([])
    const [hasTried, setHasTried] = useState(false)

    const [autoDeletion, setAutoDeletion] = useState(30)

    if (!hasTried) {
        setHasTried(true)
        if (chapter) {
            setCategories(chapter.categories)
        } else {
            getCalendarCategories()
        }
    }

    function getCalendarCategories() {
        let submission = {
            chapter: user.getChapter(),
        }

        let functionsCall = app.functions().httpsCallable('getCalendarCategories')
        functionsCall(submission)
            .then(function(result) {
                setCategories(result.data.categories)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                //var details = error.details
                console.log('AN ERROR OCCURRED', code, message)
            })
    }

    function handleSubmit() {
        let submission = {
            chapter: user.getChapter(),
            name: newCategoryName,
            color: newCategoryColor,
            id: uuidv4(),
        }

        let functionsCall = app.functions().httpsCallable('addCalendarCategory')
        functionsCall(submission)
            .then(function(result) {
                setCategories(result.data.categories)
                requestChapterUpdate()
                setIsLoading(false)
                setOpen(false)
                setNewCategoryName('')
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                console.log('AN ERROR OCCURRED', code, message)
                setIsLoading(false)
                setErrorText(message)
            })

        setIsLoading(true)
    }

    function alreadyContainsCategory(newName) {
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].name === newName) {
                return true
            }
        }
        return false
    }

    const [deleteDialog, setDeleteDialog] = useState({
        isOpen: false,
    })

    function askDeleteCategory(category) {
        setDeleteDialog({ isOpen: true, name: category.name, id: category.id })
    }

    function deleteCategory() {
        if (deleteDialog.id && categories) {
            let categoryID = deleteDialog.id

            let submission = {
                chapter: user.getChapter(),
                categoryID: categoryID,
            }

            let functionsCall = app.functions().httpsCallable('deleteCalendarCategory')
            functionsCall(submission)
                .then(function(result) {
                    setCategories(result.data.categories)
                    requestChapterUpdate()
                })
                .catch(function(error) {
                    // Getting the Error details.
                    var code = error.code
                    var message = error.message
                    var details = error.details
                    console.log('AN ERROR OCCURRED', code, message, details)

                    let cats = categories
                    for (let i = 0; i < cats.length; i++) {
                        if (cats[i].isRemoving) {
                            cats[i].isRemoving = false
                        }
                    }
                    setCategories(cats)
                })

            let cats = categories
            for (let i = 0; i < cats.length; i++) {
                let c = cats[i]
                if (c.id === categoryID) {
                    //cats.splice(i, 1)
                    //i = cats.Length
                    c.isRemoving = true
                }
            }

            setCategories(cats)

            setDeleteDialog({ isOpen: false })
        }
    }

    const renderCategories = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.tableHeader}>
                    <Typography variant="h6" style={{ fontWeight: 500 }}>
                        Categories
                    </Typography>
                    <Tooltip title="New Category">
                        <IconButton size="small" onClick={() => setOpen(true)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Grid>
            <Grid item xs={12} className={classes.tableTopRow}>
                <Box display="flex" alignItems="left">
                    <div className={classes.emptyIcon} />
                    <Box display="flex" alignItems="left" justifyContent="space-evenly" flexGrow="1">
                        <Typography className={classes.columnHeader}>Name</Typography>
                        <Typography className={classes.columnHeader}>Color</Typography>
                    </Box>
                </Box>
            </Grid>
            {categories.map(category => (
                <Grid item xs={12} className={classes.tableRow} key={category.id}>
                    <Box display="flex" alignItems="left">
                        {category.isRemoving && !category?.system ? (
                            <CircularProgress size={30} />
                        ) : category?.system ? (
                            <IconButton size="small" disabled>
                                <DeleteOutline />
                            </IconButton>
                        ) : (
                            <Tooltip title="Delete Category">
                                <IconButton size="small" onClick={() => askDeleteCategory(category)}>
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Box display="flex" alignItems="left" justifyContent="space-evenly" flexGrow="1">
                            <Typography className={classes.rowText}>{category.name}{category?.system ? '*' : ''}</Typography>
                            <div className={classes.colorRowParent}>
                                <div
                                    style={{
                                        backgroundColor: category.color,
                                    }}
                                    className={classes.colorRow}
                                />
                            </div>
                        </Box>
                    </Box>
                </Grid>
            ))}
            <Grid item xs={12}>
                <MuiAlert severity="info" sx={{ margin: 2 }}>
                    <MuiAlertTitle>System Categories</MuiAlertTitle>
                    *System categories cannot be deleted. They are special categories used in other parts of the app (ex: identifying rush events and tracking attendance for rushees).
                </MuiAlert>
            </Grid>
        </Grid>
    )

    const renderSettings = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.tableHeader}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ fontWeight: 500 }}>
                                Settings
                            </Typography>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <Typography style={{ fontWeight: 500 }}>Event Deletion</Typography>
                            <Typography variant="caption">Number of days after an event has started that it will automatically delete.</Typography>
                        </Grid>
                        <Grid item sm={3} xs={12} style={{ display: `flex`, flexDirection: `column`, justifyContent: `center` }}>
                            <Box className={classes.tableFooter}>
                                <Select
                                    value={autoDeletion}
                                    renderValue={value => {
                                        return <div style={{ padding: '0px 5px' }}>{value + ' days '}</div>
                                    }}
                                    onChange={event => setAutoDeletion(event.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={60}>60</MenuItem>
                                    <MenuItem value={90}>90</MenuItem>
                                    <MenuItem value={120}>120</MenuItem>
                                </Select>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <FormSubmission
                    isLoading={isLoading}
                    onCancel={() => props.history.push('/app/admin/roles')}
                    onSubmit={() => handleSubmit()}
                    submitText="Update"
                    errorText={errorText}
                    hideCancel={true}
                />
            </Grid>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Calendar',
                            link: '/app/calendar',
                            iconMobileOnly: true,
                            icon: <CalendarIcon />,
                        },
                        { name: 'Settings' },
                    ]}
                    grid
                />
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        renderCategories()
                    ) : (
                        <Widget disableWidgetMenu inheritHeight noBodyPadding bodyClass={classes.widgetBottomPadding}>
                            {renderCategories()}
                        </Widget>
                    )}
                </Grid>
                {false && (  // TODO: Add back in
                    <Grid item xs={12}>
                        {isMobileDevice() ? (
                            renderSettings()
                        ) : (
                            <Widget disableWidgetMenu inheritHeight noBodyPadding bodyClass={classes.widgetBottomPadding}>
                                {renderSettings()}
                            </Widget>
                        )}
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={open}
                onClose={() => {
                    if (!isLoading) {
                        setOpen(false)
                    }
                }}
            >
                <DialogTitle>New Category</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        required
                        inputProps={{
                            maxLength: 32,
                        }}
                        value={newCategoryName}
                        onChange={event => {
                            setNewCategoryName(event.target.value)
                            if (event.target.value === '') {
                                setErrorText('Name cannot be blank')
                            } else if (alreadyContainsCategory(event.target.value)) {
                                setErrorText('Category with name already exists')
                            } else {
                                setErrorText(null)
                            }
                        }}
                    />
                    <div
                        style={{
                            margin: `4px auto`,
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <GithubPicker
                            triangle="hide"
                            width="237px"
                            style={{ margin: `0 auto` }}
                            colors={colors}
                            color={newCategoryColor}
                            onChangeComplete={(color, event) => {
                                setNewCategoryColor(color.hex)
                                console.log('new color', color.hex)
                            }}
                        />
                    </div>
                    {isLoading ? (
                        <>
                            <CircularProgress
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    marginTop: 12,
                                }}
                            />
                        </>
                    ) : (
                        errorText && (
                            <DialogContentText
                                style={{
                                    color: '#E66868',
                                    textAlign: `center`,
                                    marginTop: 12,
                                    marginBottom: 0,
                                }}
                            >
                                {errorText}
                            </DialogContentText>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (!isLoading) {
                                setOpen(false)
                            }
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            //addMembers()
                            console.log('new category', newCategoryName, newCategoryColor)
                            if (newCategoryName.length === 0) {
                                setErrorText('Name cannot be blank')
                            } else if (alreadyContainsCategory(newCategoryName)) {
                                setErrorText('Category with name already exists')
                            } else {
                                setIsLoading(true)
                                handleSubmit()
                            }
                        }}
                        color="primary"
                        disabled={isLoading}
                    >
                        Add Category
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialog.isOpen} onClose={() => setDeleteDialog({ isOpen: false })}>
                <DialogTitle>Delete {deleteDialog.name}?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete "<span style={{ fontWeight: 700 }}>{deleteDialog.name}</span>
                        "?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog({ isOpen: false })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteCategory()} color="red">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
