import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import useStyles from '../styles';

const AnalyticsTab = () => {
  const classes = useStyles()
    return (
      <>        
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Card className={classes.card}>
                  <CardContent className="p-4">
                    <h3 className="text-sm text-gray-500">Total Candidates</h3>
                    <p className="text-2xl font-bold mt-2">156</p>
                    <p className="text-xs text-green-500 mt-1">↑ 12% vs last rush</p>
                  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} md={6} lg={4}>
                <Card className={classes.card}>
                  <CardContent className="p-4">
                    <h3 className="text-sm text-gray-500">Event Attendance</h3>
                    <p className="text-2xl font-bold mt-2">78%</p>
                    <p className="text-xs text-green-500 mt-1">↑ 5% vs last event</p>
                  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} md={6} lg={4}>
                <Card className={classes.card}>
                  <CardContent className="p-4">
                    <h3 className="text-sm text-gray-500">Average Rating</h3>
                    <p className="text-2xl font-bold mt-2">4.2/5.0</p>
                    <p className="text-xs text-gray-500 mt-1">Based on 450 ratings</p>
                  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} md={6} lg={4}>
                <Card className={classes.card}>
                  <CardContent className="p-4">
                    <h3 className="text-sm text-gray-500">Conversion Rate</h3>
                    <p className="text-2xl font-bold mt-2">35%</p>
                    <p className="text-xs text-blue-500 mt-1">On track with target</p>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* Funnel Analysis */}
            <Card>
              <CardHeader>
                <Typography>Rush Funnel Analysis</Typography>
              </CardHeader>
              <CardContent>
                <Box sx={{ width: '100%', height: 400 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={[
                        { stage: 'Interested', count: 200 },
                        { stage: 'First Event', count: 156 },
                        { stage: 'Multiple Events', count: 98 },
                        { stage: 'Interview', count: 65 },
                        { stage: 'Bid Extended', count: 45 },
                        { stage: 'Bid Accepted', count: 35 }
                      ]}
                      layout="vertical"
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis dataKey="stage" type="category" width={100} />
                      <Tooltip />
                      <Bar dataKey="count" fill="#3b82f6" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>

            {/* Event Attendance Trends */}
            <Card>
              <CardHeader>
                <Typography>Event Attendance Trends</Typography>
              </CardHeader>
              <CardContent>
                <Box sx={{ width: '100%', height: 400 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={[
                        { event: 'Open House', attendance: 120 },
                        { event: 'Info Session', attendance: 98 },
                        { event: 'Social Event', attendance: 85 },
                        { event: 'Service Day', attendance: 75 },
                        { event: 'Interview Day', attendance: 65 }
                      ]}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="event" />
                      <YAxis />
                      <Tooltip />
                      <Line type="monotone" dataKey="attendance" stroke="#3b82f6" />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>

            {/* Demographics Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Class Year Distribution */}
              <Card>
                <CardHeader>
                  <Typography>Class Year Distribution</Typography>
                </CardHeader>
                <CardContent>
                  <Box sx={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                      <PieChart margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <Pie
                          data={[
                            { name: 'Freshman', value: 45 },
                            { name: 'Sophomore', value: 35 },
                            { name: 'Junior', value: 20 }
                          ]}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                        >
                          <Cell fill="#3b82f6" />
                          <Cell fill="#60a5fa" />
                          <Cell fill="#93c5fd" />
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>

              {/* Major Distribution */}
              <Card>
                <CardHeader>
                  <Typography>Major Distribution</Typography>
                </CardHeader>
                <CardContent>
                  <Box sx={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                      <BarChart
                        data={[
                          { major: 'Engineering', count: 45 },
                          { major: 'Business', count: 38 },
                          { major: 'Sciences', count: 30 },
                          { major: 'Arts', count: 25 },
                          { major: 'Other', count: 18 }
                        ]}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="major" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#3b82f6" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </div>

            {/* Success Factor Correlations */}
            <Card>
              <CardHeader>
                <Typography>Success Factor Correlations</Typography>
                <Typography variant="body2" color="textSecondary">
                  Analysis of factors contributing to bid acceptance
                </Typography>
              </CardHeader>
              <CardContent>
                <Box sx={{ width: '100%', height: 400 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={[
                        { events: 1, acceptance: 20, rating: 3.2, engagement: 30 },
                        { events: 2, acceptance: 45, rating: 3.8, engagement: 55 },
                        { events: 3, acceptance: 65, rating: 4.2, engagement: 70 },
                        { events: 4, acceptance: 78, rating: 4.5, engagement: 85 },
                        { events: 5, acceptance: 85, rating: 4.7, engagement: 90 }
                      ]}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="events" label={{ value: 'Events Attended', position: 'bottom' }} />
                      <YAxis label={{ value: 'Success Rate (%)', angle: -90, position: 'insideLeft' }} />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="acceptance" name="Bid Acceptance %" stroke="#3b82f6" />
                      <Line type="monotone" dataKey="engagement" name="Engagement Score" stroke="#10b981" />
                      <Line type="monotone" dataKey="rating" name="Avg Rating x20" stroke="#f59e0b" />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>

            {/* Historical Benchmarking */}
            <Card>
              <CardHeader>
                <Typography>Rush Class Benchmarking</Typography>
                <p className="text-sm text-gray-500">5-year historical comparison</p>
              </CardHeader>
              <CardContent>
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={[
                        { 
                          year: '2021', 
                          totalCandidates: 140,
                          eventAttendance: 72,
                          bidRate: 30,
                          acceptanceRate: 75,
                          avgRating: 4.0,
                          benchmark: 85
                        },
                        { 
                          year: '2022', 
                          totalCandidates: 145,
                          eventAttendance: 75,
                          bidRate: 32,
                          acceptanceRate: 78,
                          avgRating: 4.1,
                          benchmark: 88
                        },
                        { 
                          year: '2023', 
                          totalCandidates: 150,
                          eventAttendance: 76,
                          bidRate: 33,
                          acceptanceRate: 76,
                          avgRating: 4.2,
                          benchmark: 90
                        },
                        { 
                          year: '2024', 
                          totalCandidates: 148,
                          eventAttendance: 78,
                          bidRate: 34,
                          acceptanceRate: 77,
                          avgRating: 4.3,
                          benchmark: 91
                        },
                        { 
                          year: '2025', 
                          totalCandidates: 156,
                          eventAttendance: 78,
                          bidRate: 35,
                          acceptanceRate: 78,
                          avgRating: 4.2,
                          benchmark: 92
                        }
                      ]}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="totalCandidates" name="Total Candidates" fill="#3b82f6" />
                      <Bar dataKey="eventAttendance" name="Event Attendance %" fill="#10b981" />
                      <Bar dataKey="bidRate" name="Bid Rate %" fill="#f59e0b" />
                      <Line type="monotone" dataKey="benchmark" name="Chapter Benchmark" stroke="#ef4444" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
        </>
    );
}

export default AnalyticsTab;