import { AppBar, Box, Fade, IconButton, InputBase, Menu, MenuItem, Toolbar } from '@material-ui/core'
import {
    PersonOutline as AccountIcon,
    ArrowBack as ArrowBackIcon,
    Close as CloseIcon,
    BrightnessLow as DarkIcon,
    HomeOutlined as HomeIcon,
    Menu as MenuIcon,
    NotificationsNone as NotificationsIcon,
    SettingsOutlined as SettingsIcon,
} from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import IconButtonMUI from '@mui/material/IconButton'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'

import { WhatsNewIcon } from 'components/Icons'

// styles
import useStyles from './styles'

// components
import { Avatar, Typography } from '../Wrappers/Wrappers'

import { getAppName } from 'code/App'
import { AuthContext } from 'context/AuthContext'
import { FraternityContext } from 'context/FraternityContext'
import { HeaderContext } from 'context/HeaderContext'

import { animated, useSpring } from '@react-spring/web'

// context
import { toggleSidebar, useLayoutDispatch, useLayoutState } from 'context/LayoutContext'
import { toggleTheme, useThemeDispatch } from 'context/ThemeContext'
import { useAnalytics } from 'services/mixpanel/analytics'

export default function Header({ showBack, section, title, variant, path, showAvatar, onGoBack, showHome, showWhatsNew, ...props }) {
    const analytics = useAnalytics()
    var classes = useStyles()
    var theme = useTheme()

    // global
    var layoutState = useLayoutState()
    var layoutDispatch = useLayoutDispatch()
    //var userDispatch = useUserDispatch();
    var themeDispatch = useThemeDispatch()

    let { fraternity } = React.useContext(FraternityContext)

    const { icons } = React.useContext(HeaderContext)

    // local
    var [profileMenu, setProfileMenu] = useState(null)

    const onSearch = React.useRef(null)

    const [search, _setSearch] = useState(null)

    const setSearch = search => {
        if (onSearch && onSearch.current) {
            onSearch.current(search === null ? '' : search)
        }

        _setSearch(search)
    }

    useEffect(() => {
        if (search !== null) {
            setSearch(null)
        }
    }, [section])

    const { user, signOut } = React.useContext(AuthContext)

    function signOutHere(history) {
        signOut()
        analytics.reset();        
    }

    function viewMyProfile(history) {
        setProfileMenu(null)
        if (user && user.getId()) {
            history.push('/app/profile/' + user.getId())
        }
    }

    function viewSettings(history) {
        setProfileMenu(null)
        history.push('/app/settings')
    }

    function viewNotifications(history) {
        setProfileMenu(null)
        history.push('/app/notifications/')
    }

    const baseBarColor = theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.primary.main

    const prevBackgroundColor = React.useRef(props.color ? props.color : baseBarColor)

    const { backgroundColor } = useSpring({
        from: { backgroundColor: prevBackgroundColor.current },
        backgroundColor: props.color ? props.color : baseBarColor,
        onChange: props => {
            prevBackgroundColor.current = props.value.backgroundColor
        },
        config: { duration: theme.transitionDuration },
    })

    if (variant === 'mobile') {
        return (
            <AppBar position="fixed" className={classes.appBarBorder}>
                <animated.div className={classes.appBar} style={{ backgroundColor: backgroundColor }}>
                    <TransitionGroup style={{ position: 'relative' }}>
                        <Fade key={`${section}${search !== null ? '.searching' : ''}`} timeout={theme.transitionDuration}>
                            {search !== null ? (
                                <Toolbar className={classes.toolbar}>
                                    <InputBase
                                        placeholder="Search..."
                                        inputProps={{ 'aria-label': 'Search' }}
                                        onChange={e => setSearch(e.target.value)}
                                        value={search}
                                        style={{ flexGrow: 1, color: 'inherit', caretColor: 'white' }}
                                        autoFocus
                                    />
                                    <IconButton color="inherit" onClick={() => setSearch(null)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            ) : (
                                <Toolbar className={classes.toolbar}>
                                    <Box className={classes.logotypeMobileContainer}>
                                        <Typography variant="h6" weight="medium" className={classes.logotype}>
                                            {title}
                                        </Typography>
                                    </Box>
                                    {showBack ? (
                                        <IconButton
                                            onClick={() => {
                                                if (onGoBack) {
                                                    onGoBack()
                                                }
                                                if (props.history.length > 0) {
                                                    props.history.goBack()
                                                } else if (path && path.backPath) {
                                                    props.history.replace(path.backPath)
                                                } else {
                                                    props.history.replace('/app/dashboard')
                                                }
                                            }}
                                        >
                                            <ArrowBackIcon
                                                classes={{
                                                    root: classes.headerIconCollapse,
                                                }}
                                            />
                                        </IconButton>
                                    ) : (
                                        showHome && (
                                            <IconButton
                                                onClick={() => {
                                                    props.history.replace('/app/dashboard')
                                                }}
                                            >
                                                <HomeIcon
                                                    classes={{
                                                        root: classes.headerIconCollapse,
                                                    }}
                                                />
                                            </IconButton>
                                        )
                                    )}
                                    <div className={classes.grow} />
                                    {icons &&
                                        icons.map(icon => (
                                            <IconButton
                                                color="inherit"
                                                key={icon.name}
                                                onClick={
                                                    icon.toggleSearch
                                                        ? () => {
                                                              setSearch('')
                                                              onSearch.current = icon.toggleSearch
                                                          }
                                                        : icon.onClick
                                                }
                                                aria-label={icon.name}
                                            >
                                                {icon.innerIcon}
                                            </IconButton>
                                        ))}
                                    {showAvatar && (
                                        <IconButton
                                            aria-haspopup="true"
                                            size="small"
                                            color="inherit"
                                            aria-controls="profile-menu"
                                            onClick={e => /*setProfileMenu(e.currentTarget)*/ props.onClickProfile()}
                                            style={{ padding: 6 }}
                                        >
                                            <Avatar color="primary" alt="User Profile Picture" src={user.getPhoto()} classes={{ root: classes.headerIcon }}>
                                                {user.getInitials()}
                                            </Avatar>
                                        </IconButton>
                                    )}
                                    <Menu
                                        id="profile-menu"
                                        open={Boolean(profileMenu)}
                                        anchorEl={profileMenu}
                                        onClose={() => setProfileMenu(null)}
                                        className={classes.headerMenu}
                                        classes={{ paper: classes.profileMenu }}
                                        disableAutoFocusItem
                                    >
                                        <div className={classes.profileMenuUser}>
                                            <Typography variant="h4" weight="semibold">
                                                {user.getFirst()} {user.getLast()}
                                            </Typography>
                                        </div>
                                        <MenuItem
                                            className={classNames(classes.profileMenuItem, classes.headerMenuItem)}
                                            onClick={() => viewMyProfile(props.history)}
                                        >
                                            <AccountIcon className={classes.profileMenuIcon} /> Account
                                        </MenuItem>
                                        <MenuItem
                                            className={classNames(classes.profileMenuItem, classes.headerMenuItem)}
                                            onClick={() => viewNotifications(props.history)}
                                        >
                                            <NotificationsIcon className={classes.profileMenuIcon} /> Notifications
                                        </MenuItem>
                                        <MenuItem
                                            className={classNames(classes.profileMenuItem, classes.headerMenuItem)}
                                            onClick={() => viewSettings(props.history)}
                                        >
                                            <SettingsIcon className={classes.profileMenuIcon} /> Settings
                                        </MenuItem>
                                        <div className={classes.profileMenuUser}>
                                            <Typography className={classes.profileMenuLink} color="primary" onClick={() => signOutHere(props.history)}>
                                                Sign Out
                                            </Typography>
                                        </div>
                                    </Menu>
                                </Toolbar>
                            )}
                        </Fade>
                    </TransitionGroup>
                </animated.div>
            </AppBar>
        )
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <animated.div className={classes.appBar} style={{ backgroundColor: backgroundColor }}>
                <Toolbar className={classes.toolbar}>
                    <IconButtonMUI
                        color="inherit"
                        onClick={() => toggleSidebar(layoutDispatch)}
                        className={classNames(classes.headerMenuButtonCollapse)}
                        sx={{ display: { xs: 'inline-block', md: 'none' } }}
                    >
                        {layoutState.isSidebarOpened ? (
                            <ArrowBackIcon
                                classes={{
                                    root: classNames(classes.headerIcon, classes.headerIconCollapse),
                                }}
                            />
                        ) : (
                            <>
                                <MenuIcon
                                    classes={{
                                        root: classNames(classes.headerIcon, classes.headerIconCollapse),
                                    }}
                                />
                            </>
                        )}
                    </IconButtonMUI>
                    <Typography variant="h6" weight="medium" className={classNames(classes.logotype, classes.hiddenOnSmall)}>
                        {getAppName(fraternity)}
                    </Typography>
                    <div className={classes.grow} />
                    <IconButton aria-haspopup="true" color="inherit" aria-controls="profile-menu" onClick={e => setProfileMenu(e.currentTarget)}>
                        <Avatar color="primary" alt="User Profile Picture" src={user.getPhoto()} classes={{ root: classes.headerIcon }}>
                            {user.getInitials()}
                        </Avatar>
                    </IconButton>
                    <Typography
                        block
                        variant="body2"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 8,
                            marginRight: 8,
                        }}
                    >
                        <Typography variant="body2" weight={'bold'}>
                            {user.getFirst()}
                        </Typography>
                    </Typography>
                    <Menu
                        id="profile-menu"
                        open={Boolean(profileMenu)}
                        anchorEl={profileMenu}
                        onClose={() => setProfileMenu(null)}
                        className={classes.headerMenu}
                        classes={{ paper: classes.profileMenu }}
                        disableAutoFocusItem
                    >
                        <div className={classes.profileMenuUser}>
                            <Typography variant="h4" weight="semibold">
                                {user.getFirst()} {user.getLast()}
                            </Typography>
                        </div>
                        <MenuItem className={classNames(classes.profileMenuItem, classes.headerMenuItem)} onClick={() => viewMyProfile(props.history)}>
                            <AccountIcon className={classes.profileMenuIcon} /> Account
                        </MenuItem>
                        <MenuItem className={classNames(classes.profileMenuItem, classes.headerMenuItem)} onClick={() => viewNotifications(props.history)}>
                            <NotificationsIcon className={classes.profileMenuIcon} /> Notifications
                        </MenuItem>
                        <MenuItem className={classNames(classes.profileMenuItem, classes.headerMenuItem)} onClick={() => viewSettings(props.history)}>
                            <SettingsIcon className={classes.profileMenuIcon} /> Settings
                        </MenuItem>
                        <MenuItem
                            className={classNames(classes.profileMenuItem, classes.headerMenuItem)}
                            onClick={() => {
                                setProfileMenu(null)
                                showWhatsNew()
                            }}
                        >
                            <WhatsNewIcon className={classes.profileMenuIcon} /> What's New
                        </MenuItem>
                        <MenuItem className={classNames(classes.profileMenuItem, classes.headerMenuItem)} onClick={() => toggleTheme(themeDispatch)}>
                            <DarkIcon className={classes.profileMenuIcon} /> Toggle Theme
                        </MenuItem>
                        <div className={classes.profileMenuUser}>
                            <Typography className={classes.profileMenuLink} color="primary" onClick={() => signOutHere(props.history)}>
                                Sign Out
                            </Typography>
                        </div>
                    </Menu>
                </Toolbar>
            </animated.div>
        </AppBar>
    )
}
