import { storage } from 'config/firebase'
import Resizer from 'react-image-file-resizer'

export const uploadURI = async (uri, path) => {
    
    await storage
        .ref(path)
        .put(uri, { cacheControl: 'private, max-age=15552000' })

    return await storage
        .ref(path)
        .getDownloadURL()
}

export const resizeImage = async (image, width, height, type, quality) => {
    return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
            image, //is the file of the new image that can now be uploaded...
            width, // is the maxWidth of the  new image
            height, // is the maxHeight of the  new image
            type, // is the compressFormat of the  new image
            quality, // is the quality of the  new image
            0, // is the rotation of the  new image
            uri => {
                resolve(uri)
            },
            'blob', // is the output type of the new image
        )
    })
}
