import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    base: {
        backgroundColor: 'white',
        borderRadius: 2,
        border: '1px solid #e0e0e0',
        padding: 16,
    },
    box: {
        background: `${theme.palette.background.paper}`,
        borderRadius: 2,
        border: '1px solid black',
        padding: 16,
        transition: 'all 0.2s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f5f5f5',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            transform: 'translateY(-2px)'
        },
        '&:active': {
            transform: 'translateY(0)',
            boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
            backgroundColor: '#eeeeee'
        }
    }
}))
