import React, { useState, useContext } from 'react'
import { functions } from 'config/firebase'
import { Grid, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Add as AddIcon, MoreVert as ExpandIcon, HomeOutlined as HomeIcon, Settings as AdminIcon } from '@material-ui/icons'

import NavigationBar from 'components/NavigationBar'
import { FraternityContext } from 'context/FraternityContext'
import { ChapterContext } from 'context/ChapterContext'
import { AuthContext } from 'context/AuthContext'
import { Button } from 'components/Wrappers'
import ErrorTypography from 'components/Typography/ErrorTypography'
import TinyCard from 'components/Cards/TinyCard'

import { isMobileDevice as _isMobileDevice } from 'code/Helper'
import RoleMenu from './RoleMenu'

export default function Roles(props) {
    const theme = useTheme()

    const [isMobileDevice] = useState(_isMobileDevice())

    const { fraternity } = useContext(FraternityContext)
    const { chapter, requestChapterUpdate } = useContext(ChapterContext)
    const { user } = useContext(AuthContext)
    const [roles, setRoles] = useState(null)
    const [roleMenu, setRoleMenu] = useState(null)
    const [roleSelection, setRoleSelection] = useState(null)
    const [deleteDialog, setDeleteDialog] = useState({ isOpen: false })

    if (roles === null) {
        getRoles()
    }

    function getRoles() {
        let newRoles = []
        let anyDefault = false
        if (user.isHQ()) {
            if (fraternity && fraternity.roles) {
                let rolesMap = fraternity.roles
                for (let roleId in rolesMap) {
                    let newRole = rolesMap[roleId]
                    newRole.id = roleId

                    if (newRole.default) {
                        anyDefault = true
                    }

                    newRoles.push(newRole)
                }
            }
        } else {
            if (chapter && chapter.roles) {
                let rolesMap = chapter.roles
                for (let roleId in rolesMap) {
                    let newRole = rolesMap[roleId]
                    newRole.id = roleId

                    if (newRole.default) {
                        anyDefault = true
                    }

                    newRoles.push(newRole)
                }
            }
        }

        newRoles = newRoles.concat(getDefaultRoles(anyDefault))
        newRoles.sort(function(a, b) {
            return a.title.localeCompare(b.title)
        })
        setRoles(newRoles)
    }

    function getDefaultRoles(anyDefault) {
        let adminRole = {
            title: 'Admin',
            id: 'ADMIN',
        }

        if (!anyDefault) {
            adminRole.default = true
        }

        return [adminRole]
    }

    function deleteRole() {
        setDeleteDialog({
            isOpen: true,
            title: deleteDialog.title,
            id: deleteDialog.id,
            isLoading: true,
        })

        let functionsCall = functions.httpsCallable('updateRole')
        functionsCall({ chapter: user.getChapter(), id: deleteDialog.id, action: 'delete' })
            .then(async function(response) {
                await requestChapterUpdate()

                let newRoles = []
                for (let i = 0; i < roles.length; i++) {
                    let r = roles[i]
                    if (r.id !== deleteDialog.id) {
                        newRoles.push(r)
                    }
                    
                }

                setDeleteDialog({
                    isOpen: false,
                })
                setRoles(newRoles)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setDeleteDialog({
                    isOpen: true,
                    title: deleteDialog.title,
                    id: deleteDialog.id,
                    isLoading: false,
                    errorText: message,
                })
            })
    }

    function askDeleteRole(role) {
        setDeleteDialog({
            isOpen: true,
            title: role.title,
            id: role.id,
        })
    }

    function setRowAsDefault(role) {
        if (role) {
            let submission = {
                chapter: user.getChapter(),
                id: role.id,
            }

            let newRoles = []
            for (let i = 0; i < roles.length; i++) {
                let r = roles[i]
                if (r.id === role.id) {
                    r.default = true
                } else {
                    r.default = false
                }
                newRoles.push(r)
            }

            let functionsCall = functions.httpsCallable('setDefaultRole')
            functionsCall(submission).then(() => {
                requestChapterUpdate()
            }).catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
            })

            setRoles(newRoles)
        }
    }

    function renderRoles() {
        if (roles) {
            return roles.map((role, index) => (
                <Grid item lg={4} md={6} sm={6} xs={12} key={role.id}>
                    <TinyCard
                        disabled={role.id === 'ADMIN'}
                        onClick={role.id !== 'ADMIN' ? () => {
                            props.history.push(user.isHQ() ? '/app/hq/roles/edit/' + role.id : '/app/admin/roles/edit/' + role.id)
                        } : null}
                        text={role.title}
                        description={role.default ? <span style={{ fontWeight: 'bold', color: theme.palette.green.main }}>Default</span> : <br />}
                        buttons={
                            [
                                {
                                    onClick: (e) => {
                                        setRoleMenu(e.currentTarget)
                                        setRoleSelection(role.id)
                                    },
                                    icon: <ExpandIcon />,
                                    id: `${role.id}.edit`,
                                },
                            ]
                        }
                        variant={isMobileDevice ? 'outlined' : 'default'}
                    />
                </Grid>
            ))
        }
        return <></>
    }

    const renderedMenu = (role) => (
        <RoleMenu
            open={roleMenu !== null && roleSelection === role.id}
            target={roleMenu}
            key={role.id}
            type={isMobileDevice ? 'sheet' : 'menu'}
            onClose={() => {
                setRoleSelection(null)
                setRoleMenu(null)
            }}
            role={role}
            onSelectAction={(action) => {
                if (action === 'set_default' && !role.default) {
                    setRowAsDefault(role)
                } else if (action === 'delete' && role.id !== 'ADMIN') {
                    askDeleteRole(role)
                }
                setRoleSelection(null)
                setRoleMenu(null)
            }}
        />
    )

    const renderRoleMenus = () => {
        if (roles) {
            return roles.map(role => renderedMenu(role))
        }

        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    grid
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Admin',
                            link: '/app/admin',
                            iconMobileOnly: true,
                            icon: <AdminIcon />,
                        },
                        {
                            name: 'Roles',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Add',
                            type: 'icon',
                            innerIcon: <AddIcon />,
                            onClick: () => {
                                props.history.push('/app/admin/roles/add')
                            },
                        },
                    ]}
                />
                {renderRoles()}
            </Grid>
            {renderRoleMenus()}
            <Dialog open={deleteDialog.isOpen} onClose={() => setDeleteDialog({ isOpen: false })}>
                <DialogTitle>Delete role?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete "<span style={{ fontWeight: 700 }}>{deleteDialog.title}</span>
                        "?
                    </DialogContentText>
                    {deleteDialog.errorText && <ErrorTypography text={deleteDialog.errorText} isCentered={true} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog({ isOpen: false })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteRole()} color="red" disabled={deleteDialog.isLoading}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
