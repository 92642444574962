import {
    Box,
    Checkbox,
    CircularProgress,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField as Input,
    LinearProgress,
    Slider,
    Tooltip,    
    Select,
    MenuItem,
    InputLabel,
    TextField,
    FormGroup,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid';
import {
    CommentOutlined as CommentsIcon,
    PermContactCalendarOutlined as ContactIcon,
    DeleteOutlined as DeleteIcon,
    EditOutlined as EditIcon,
    HomeOutlined as HomeIcon,
    Instagram as InstagramIcon,
    LocationOnOutlined as LocationIcon,
    PhoneOutlined as PhoneIcon,
    WhatshotOutlined as RushIcon,
    SchoolOutlined as SchoolIcon,
    SendRounded as SendIcon,
    ThumbDownRounded as ThumbsDownIcon,
    ThumbUpRounded as ThumbsUpIcon,
    Timeline as TimelineIcon,
    HowToVoteOutlined as VotingIcon,
    Person as PersonIcon,
    CheckCircle as PresentIcon,
     HelpOutline as InfoIcon,
} from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import { BackpackOutlinedIcon as BackpackIcon, CalendarOutlinedIcon as CalendarIcon, SnapchatIcon } from 'components/Icons'
import React, { useState, useContext, useMemo, useEffect } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import MUIAvatar from '@mui/material/Avatar'

import { Rushee } from 'objects/Rushee'

import FormSection from 'components/Forms/FormSection'

import { isMobileDevice } from 'code/Helper'
import { timeAgo } from 'code/TimeAgo'
import NavigationBar from 'components/NavigationBar'
import Paper from 'components/Paper'
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from 'components/Wrappers'
import templatePerson from '../templatePerson.svg'
import useStyles from './styles'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import { v4 as uuidv4 } from 'uuid';
import { isUserRushAdmin } from 'utils/authorization'
import { getEventsByCategory } from 'utils/categories'
import { getDateFromYMD, getEventTiming } from 'code/TimeAgo'
import { checkRusheeAttendance } from '../utils'
import { useAddRusheeEvent } from 'hooks/mutations/rush/useAddRusheeEvent'
import { useRemoveRusheeEvent } from 'hooks/mutations/rush/useRemoveRusheeEvent'
import { db } from 'config/firebase'; 
import { getRusheeAttendance } from '../utils'
import { useUpdateRushee } from 'hooks/mutations/rush/useUpdateRushee'
import { bidStatusTypes } from '../utils'
import Autocomplete from '@mui/material/Autocomplete'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGetRushes } from 'hooks/queries/rush/useGetRushes';

export default function ViewRushee(props) {
    const classes = useStyles()
    const theme = useTheme()
    const rusheeID = props.match.params.id

    const { currentUser, user } = useContext(AuthContext)
    const rusheeSubmission = {
        chapter: user.getChapter(),
        rusheeID: rusheeID,
    }
    const { chapter, requestChapterUpdate } = useContext(ChapterContext)
    let item = localStorage.getItem('cache_rushee_' + rusheeID)
    const [rushee, setRushee] = useState(item ? JSON.parse(item) : null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [hasTried, setHasTried] = useState(false)
    const [newMemberName, setNewMemberName] = useState(chapter ? chapter.newMemberShortname : 'AM')
    const [numBrothers, setNumBrothers] = useState(chapter ? Object.keys(chapter.members).length : 50)
    const [voteFields, setVoteFields] = useState(null)
    const [commentsEnabled, setCommentsEnabled] = useState(false)
    const [timelineEnabled, setTimelineEnabled] = useState(false)
    const [previewVoting, setPreviewVoting] = useState(false)
    const [isVotingEnabled, setIsVotingEnabled] = useState(false)
    const [isVotingOpen, setIsVotingOpen] = useState(false)
    const [comment, setComment] = useState('')
    const [hasTypedComment, setHasTypedComment] = useState(false)
    const [timeline, setTimeline] = useState('')
    const [hasTypedTimeline, setHasTypedTimeline] = useState(false)
    const [isLoadingComment, setIsLoadingComment] = useState(false)
    const [isLoadingTimeline, setIsLoadingTimeline] = useState(false)
    const [isUpdatingVote, setIsUpdatingVote] = useState(false)
    const [isLoadingRushee, setIsLoadingRushee] = useState(false)
    const [usersVote, setUsersVote] = useState(null)
    const [loadingEventId, setLoadingEventId] = useState(null)
    const addRusheeEvent = useAddRusheeEvent()
    const removeRusheeEvent = useRemoveRusheeEvent()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
    const [bidStatus, setBidStatus] = useState(rushee?.bidStatus || 0)
    const updateRusheeMutation = useUpdateRushee({
        chapterId: user?.getChapter(),
        rushId: chapter?.activeRush,
        rusheeId: rusheeID
    })
    const {data: activeRush } = useGetRushes({
        chapterId: user?.getChapter(),
        rushId: chapter.activeRush,
        isActive: true
    })
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if(activeRush?.startDate) {
            const timestamp = new app.firestore.Timestamp(
                activeRush.startDate.seconds,
                activeRush.startDate.nanoseconds
            );
            setStartDate(timestamp.toDate());
        }
        if(activeRush?.endDate) {
            const timestamp = new app.firestore.Timestamp(
                activeRush.endDate.seconds,
                activeRush.endDate.nanoseconds
            );
            setEndDate(timestamp.toDate());
        }
    }, [activeRush]); // Only run when activeRush changes
    
    const rushEvents = useMemo(() => {
        const events = getEventsByCategory(chapter, 'rush')
            .map(e => ({
                id: e.id,
                name: e.name,
                rusheesAttended: e.rusheesAttended,
                eventTiming: getEventTiming(e, { includeLength: true, notRelative: true, longText: true }),
                startDate: getDateFromYMD(e.date.sd, chapter.settings.timezone),
                endDate: getDateFromYMD(e.date.ed, chapter.settings.timezone),
                attended: checkRusheeAttendance(rushee, e.id),
                confirmedBy: (() => {
                    const attendance = getRusheeAttendance(rushee, e.id);
                    if (!attendance?.confirmedBy?.name || !attendance?.timestamp) return '';
                    try {
                        const timestamp = attendance.timestamp instanceof Date 
                            ? attendance.timestamp 
                            : attendance.timestamp?.toDate?.() 
                                ? attendance.timestamp.toDate()
                                : new Date(attendance.timestamp);
                        return `${attendance.confirmedBy.name} @ ${timestamp.toLocaleString()}`;
                    } catch (error) {
                        console.error('Error formatting timestamp:', error);
                        return attendance.confirmedBy.name;
                    }
                })()
            }))
            .filter(event => {
                if (!startDate || !endDate) return true; // Show all if no date range set
                return event.startDate >= startDate && event.endDate <= endDate;
            })
            .sort((a, b) => a.startDate - b.startDate);

        return events;
    }, [chapter, rushee, startDate, endDate]);

    const rushEventsCount = rushEvents.length 
    const rushEventsAttended = rushEvents.filter(e => e.attended).length

    function getRushee() {
        setIsLoadingRushee(true)
        let activeRush = chapter.activeRush

        let rushDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(activeRush)

        let rusheeDoc = rushDoc.collection('rushees').doc(rusheeSubmission.rusheeID)

        rushDoc
            .get()
            .then(function(rushDoc) {
                if (rushDoc.exists) {
                    let rushData = rushDoc.data()
                    rusheeDoc
                        .get()
                        .then(function(doc) {
                            if (doc.exists) {
                                let data = doc.data()
                                updateRushee(data)
                                setNewMemberName(chapter.newMemberShortname)
                                setNumBrothers(Object.keys(chapter.members).length)
                                setVoteFields(rushData.voteFields)
                                setCommentsEnabled(rushData.commentsEnabled)
                                setTimelineEnabled(rushData.featureTimeline)
                                setPreviewVoting(rushData.previewVoting)
                                setIsVotingEnabled(rushData.votingEnabled)
                                setIsAdmin(isUserRushAdmin(chapter, user))
                                grabUsersVotes(doc.data(), rushData.voteFields)
                                setIsLoadingRushee(false)
                                setHasTried(true)
                            }
                        })
                        .catch(function(error) {
                            console.log('Error getting document:', error)
                        })
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function updateRushee(rushee) {
        let data = { ...rushee }
        data.timeline = data.timeline ? data.timeline : {}
        setRushee(data)
    }

    function getUsersVote(rushee, voteFields) {
        let usersVotes = {}
        let alreadyVoted = false

        if (currentUser && currentUser.uid) {
            let votes = rushee.votes
            for (let i = 0; i < votes.length; i++) {
                let vote = votes[i]
                if (vote.memberID === currentUser.uid) {
                    alreadyVoted = true
                    if (Array.isArray(voteFields)) {
                        for (let i = 0; i < voteFields.length; i++) {
                            let field = voteFields[i]
                            usersVotes[field.id] = vote[field.id]
                        }
                    }
                }
            }
        }

        if (!alreadyVoted && Array.isArray(voteFields)) {
            for (let i = 0; i < voteFields.length; i++) {
                let field = voteFields[i]
                if (field.type === 'CHECKBOX') {
                    usersVotes[field.id] = false
                } else {
                    usersVotes[field.id] = 5
                }
            }
        }

        return usersVotes
    }

    function grabUsersVotes(rushee, voteFields) {
        setUsersVote(getUsersVote(rushee, voteFields))
    }

    if (!hasTried) {
        setHasTried(true)
        getRushee()
    }

    function getBidStatus() {
        if (rushee) {
            return bidStatusTypes[rushee.bidStatus].label
        }
        return null
    }

    function voteOnRushee() {
        const rusheeDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(chapter.activeRush)
            .collection('rushees')
            .doc(rusheeSubmission.rusheeID)

        let votes = rushee.votes
        let shouldCastVote = true
        let found = false

        let appendedVote = { ...usersVote, memberID: user.getId() }

        if (currentUser && currentUser.uid) {
            for (let i = 0; i < votes.length; i++) {
                let vote = votes[i]
                if (vote.memberID === currentUser.uid) {
                    let sameVote = true
                    for (let j = 0; j < voteFields.length; j++) {
                        let origVote = vote[voteFields[j].id]
                        let curVote = usersVote[voteFields[j].id]
                        if (origVote !== curVote) {
                            sameVote = false
                        }
                    }
                    if (!sameVote) {
                        found = true
                        votes[i] = appendedVote
                    } else {
                        shouldCastVote = false
                    }
                    i = votes.length
                }
            }
        }

        if (shouldCastVote) {
            setIsUpdatingVote(true)

            let changes = {}

            if (found) {
                changes.votes = votes
            } else {
                changes.votes = app.firestore.FieldValue.arrayUnion(appendedVote)
                votes.push(appendedVote)
            }

            rusheeDoc
                .update(changes)
                .then(function(result) {
                    setIsUpdatingVote(false)
                    setIsVotingOpen(false)
                    let r = JSON.parse(JSON.stringify(rushee))
                    r.votes = votes
                    updateRushee(r)
                    getRushee()
                })
                .catch(function(error) {
                    var code = error.code
                    var message = error.message
                    setIsUpdatingVote(false)
                    console.log('AN ERROR OCCURRED', code, message)
                })
        }
    }

    async function toggleDislike(commentID) {
        const rusheeDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(chapter.activeRush)
            .collection('rushees')
            .doc(rusheeSubmission.rusheeID)

        let r = JSON.parse(JSON.stringify(rushee))
        let comments = r.comments
        let comment = comments[commentID]
        let memberID = currentUser.uid

        let alreadyExists = comment !== null && comment.dislikes.includes(memberID)

        await rusheeDoc.update({
            [`comments.${commentID}.likes`]: app.firestore.FieldValue.arrayRemove(currentUser.uid),
            [`comments.${commentID}.dislikes`]: alreadyExists
                ? app.firestore.FieldValue.arrayRemove(currentUser.uid)
                : app.firestore.FieldValue.arrayUnion(currentUser.uid),
        })

        if (comment !== null) {
            if (comment.likes.includes(memberID)) {
                //Remove it
                comment.likes = comment.likes.filter(function(value, index, arr) {
                    return value !== memberID
                })
            }

            if (alreadyExists) {
                //Remove it
                comment.dislikes = comment.dislikes.filter(function(value, index, arr) {
                    return value !== memberID
                })
            } else {
                //Add dislike
                comment.dislikes.push(memberID)
            }
            comments[commentID] = comment
            r.comments = comments
            updateRushee(r)
        }
    }

    async function toggleLike(commentID) {
        const rusheeDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(chapter.activeRush)
            .collection('rushees')
            .doc(rusheeSubmission.rusheeID)

        let r = JSON.parse(JSON.stringify(rushee))
        let comments = r.comments
        let comment = comments[commentID]
        let memberID = currentUser.uid

        let alreadyExists = comment !== null && comment.likes.includes(memberID)

        await rusheeDoc.update({
            [`comments.${commentID}.dislikes`]: app.firestore.FieldValue.arrayRemove(currentUser.uid),
            [`comments.${commentID}.likes`]: alreadyExists
                ? app.firestore.FieldValue.arrayRemove(currentUser.uid)
                : app.firestore.FieldValue.arrayUnion(currentUser.uid),
        })

        if (comment !== null) {
            if (comment.dislikes.includes(memberID)) {
                //Remove it
                comment.dislikes = comment.dislikes.filter(function(value, index, arr) {
                    return value !== memberID
                })
            }

            if (alreadyExists) {
                //Remove it
                comment.likes = comment.likes.filter(function(value, index, arr) {
                    return value !== memberID
                })
            } else {
                //Add dislike
                comment.likes.push(memberID)
            }
            comments[commentID] = comment
            r.comments = comments
            updateRushee(r)
        }
    }

    function addComment() {
        setIsLoadingComment(true)

        const rusheeDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(chapter.activeRush)
            .collection('rushees')
            .doc(rusheeSubmission.rusheeID)

        const commentID = uuidv4()

        const data = {
            comment,
            dislikes: [],
            likes: [],
            timestamp: app.firestore.Timestamp.fromDate(new Date()),
            sender: {
                first: user.first,
                last: user.last,
                memberID: user.id,
                photoURL: user.photoURL ? user.photoURL : '',
            },
        }

        rusheeDoc
            .update({
                [`comments.${commentID}`]: data,
            })
            .then(function() {
                setComment('')
                setHasTypedComment(false)

                let r = JSON.parse(JSON.stringify(rushee))
                r.comments[commentID] = data
                updateRushee(r)

                setIsLoadingComment(false)
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoadingComment(false)
            })
    }

    function addTimeline() {
        let timelineSubmission = {
            chapter: user.getChapter(),
            timeline: timeline,
            rusheeID: rusheeID,
            timelineID: uuidv4(),
        }

        setIsLoadingTimeline(true)

        var addTL = app.functions().httpsCallable('addRusheeTimeline')
        addTL(timelineSubmission)
            .then(function(result) {
                setTimeline('')
                setHasTypedTimeline(false)
                updateRushee(result.data.rushee)
                setIsLoadingTimeline(false)
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoadingTimeline(false)
            })
    }

    function getBrothersMetDataTable() {
        if (rushee && rushee.votes && numBrothers > 0) {
            if (chapter) {
                let votes = []
                for (let i = 0; i < rushee.votes.length; i++) {
                    let vote = rushee.votes[i]
                    let memberIds = Object.keys(chapter.members)
                    for (let j = 0; j < memberIds.length; j++) {
                        let member = chapter.members[memberIds[j]]
                        member.id = memberIds[j]
                        if (member.id === vote.memberID) {
                            votes.push(member)
                        }
                    }
                }
                return votes
            }
        }

        return []
    }

    function getPercentBrothersMet() {
        if (rushee && rushee.votes && numBrothers > 0) {
            var numBrothersMet = rushee.votes.length
            if (numBrothersMet > numBrothers) {
                numBrothersMet = numBrothers
            }

            return numBrothersMet
        }

        return 0
    }

    function canPreviewVotes() {
        if (previewVoting || isAdmin) {
            return true
        }

        if (currentUser && currentUser.uid && rushee && rushee.votes && voteFields) {
            let votes = rushee.votes
            for (let i = 0; i < votes.length; i++) {
                let vote = votes[i]
                if (vote.memberID === currentUser.uid) {
                    return true
                }
            }
        }

        return false
    }

    function containsVoting() {
        if (voteFields) {
            return true
        }

        return false
    }

    function getVoteFields() {
        if (voteFields && rushee.votes) {
            let rusheeObj = new Rushee(rushee)
            return rusheeObj.getVoteFields(voteFields)
        }
        return null
    }

    function updateCommentStatus(val) {
        setHasTypedComment(true)
        setComment(val)
    }

    function updateTimelineStatus(val) {
        setHasTypedTimeline(true)
        setTimeline(val)
    }

    function getBrothersMetData() {
        if (rushee && rushee.votes && rushee.votes.length > 0) {
            var numBrothersMet = rushee.votes.length
            var numBrothersNotMet = numBrothers - numBrothersMet
            if (numBrothersNotMet < 0) {
                numBrothersNotMet = 0
            }
            return [
                { name: 'Met', value: numBrothersMet, color: 'green' },
                {
                    name: "Haven't Met",
                    value: numBrothersNotMet,
                    color: 'gray',
                },
            ]
        }
        return [
            { name: 'Met', value: 0, color: 'green' },
            { name: "Haven't Met", value: 1, color: 'gray' },
        ]
    }

    function getComments() {
        if (!rushee) {
            return <></>
        }

        let r = new Rushee(rushee)

        let comments = r.getSortedComments()
        return (
            <>
                {comments.map(comment => (
                    <Box 
                        display="flex" 
                        my={2} 
                        style={{ marginTop: 8, marginBottom: 8 }} 
                        key={comment[0]}
                    >
                        <Avatar src={comment[1]?.sender?.photoURL} style={{ marginRight: 8 }} />
                        <Typography block style={{ flexGrow: 1 }}>
                            <Box fontWeight={'fontWeightBold'} display="flex" alignItems="center" fontSize={'.77rem'} style={{ flexGrow: 1 }}>
                                <Link
                                    onClick={() => viewProfile(comment[1].sender.memberID)}
                                    color="primary"
                                    style={{
                                        cursor: 'pointer',
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                    }}
                                >
                                    {comment[1].sender.first} {comment[1].sender.last}
                                </Link>{' '}
                                <Box
                                    fontSize={'.7rem'}
                                    fontWeight="fontWeightRegular"
                                    ml={1}
                                    style={{
                                        paddingTop: 3,
                                        paddingBottom: 1,
                                    }}
                                >
                                    {timeAgo(new Date(0).setUTCSeconds(comment[1].timestamp.seconds))}
                                </Box>
                                <Box fontSize={'.7rem'} fontWeight="fontWeightRegular" ml={1}>
                                    <IconButton aria-label="like comment" onClick={e => toggleLike(comment[0])} style={{ padding: 2 }}>
                                        {currentUser && comment[1].likes.includes(currentUser.uid) ? (
                                            <ThumbsUpIcon
                                                style={{
                                                    fontSize: 16,
                                                    color: '#1e88e5',
                                                }}
                                            />
                                        ) : (
                                            <ThumbsUpIcon style={{ fontSize: 16 }} />
                                        )}
                                    </IconButton>
                                </Box>
                                {comment[1].likes.length > 0 && (
                                    <Box
                                        fontSize={'.7rem'}
                                        fontWeight="fontWeightRegular"
                                        ml={1}
                                        style={{
                                            paddingTop: 3,
                                            paddingBottom: 1,
                                            marginLeft: 2,
                                        }}
                                    >
                                        {comment[1].likes.length}
                                    </Box>
                                )}
                                <Box fontSize={'.7rem'} fontWeight="fontWeightRegular" ml={1}>
                                    <IconButton aria-label="like comment" onClick={e => toggleDislike(comment[0])} style={{ padding: 2 }}>
                                        {currentUser && comment[1].dislikes.includes(currentUser.uid) ? (
                                            <ThumbsDownIcon
                                                style={{
                                                    fontSize: 16,
                                                    color: '#1e88e5',
                                                }}
                                            />
                                        ) : (
                                            <ThumbsDownIcon style={{ fontSize: 16 }} />
                                        )}
                                    </IconButton>
                                </Box>
                                {comment[1].dislikes.length > 0 && (
                                    <Box
                                        fontSize={'.7rem'}
                                        fontWeight="fontWeightRegular"
                                        ml={1}
                                        style={{
                                            paddingTop: 3,
                                            paddingBottom: 1,
                                            marginLeft: 2,
                                        }}
                                    >
                                        {comment[1].dislikes.length}
                                    </Box>
                                )}
                            </Box>
                            <Box fontSize={'.8rem'}>{comment[1].comment}</Box>
                        </Typography>
                        {isAdmin && (
                            <IconButton 
                                aria-label="delete comment" 
                                onClick={() => deleteComment(comment[0])} 
                                size="small" 
                                className={classes.deleteButton}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                ))}
            </>
        )
    }

    function getTimeline() {
        if (!rushee) {
            return <></>
        }

        let r = new Rushee(rushee)

        let timeline = r.getSortedTimeline()

        return (
            <>
                {timeline.map(tl => (
                    <>
                        <Box display="flex" my={2} style={{ marginTop: 8, marginBottom: 8 }} key={tl[0]}>
                            <Avatar src={tl[1].sender.photoURL} style={{ marginRight: 8 }} />
                            <Typography block style={{ flexGrow: 1 }}>
                                <Box fontWeight={'fontWeightBold'} display="flex" alignItems="center" fontSize={'.77rem'} style={{ flexGrow: 1 }}>
                                    <Link
                                        onClick={() => viewProfile(tl[1].sender.memberID)}
                                        color="primary"
                                        style={{
                                            cursor: 'pointer',
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                        }}
                                    >
                                        {tl[1].sender.first} {tl[1].sender.last}
                                    </Link>{' '}
                                    <Box
                                        fontSize={'.7rem'}
                                        fontWeight="fontWeightRegular"
                                        ml={1}
                                        style={{
                                            paddingTop: 3,
                                            paddingBottom: 1,
                                        }}
                                    >
                                        {timeAgo(new Date(0).setUTCSeconds(tl[1].timestamp.seconds))}
                                    </Box>
                                </Box>
                                <Box fontSize={'.8rem'}>{tl[1].timeline}</Box>
                            </Typography>
                            {isAdmin && (
                                <IconButton aria-label="delete timeline" onClick={e => deleteTimeline(tl[0])} size="small" className={classes.deleteButton}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Divider className={classes.divider} />
                    </>
                ))}
            </>
        )
    }

    function getRenderedBidStatus() {
        var status = getBidStatus()

        if (status) {
            if (status === 'Accepted') {
                return (
                    <>
                        <Grid item>
                            <Chip className={classes.chip} color="green" label="Accepted" />
                        </Grid>
                    </>
                )
            } else if (status === 'Received') {
                return (
                    <>
                        <Grid item>
                            <Chip className={classes.chip} color="red" label="Received" />
                        </Grid>
                    </>
                )
            } else if (status === 'Voted') {
                return (
                    <>
                        <Grid item>
                            <Chip className={classes.chip} color="blue" label="Voted" />
                        </Grid>
                    </>
                )
            } else if (status === 'Denied') {
                return (
                    <>
                        <Grid item>
                            <Chip className={classes.chip} color="gray" label="Denied" />
                        </Grid>
                    </>
                )
            } else if (status === 'Rejected') {
                return (
                    <>
                        <Grid item>
                            <Chip className={classes.chip} color="bluegray" label="Rejected" />
                        </Grid>
                    </>
                )
            }
        }

        return null
    }

    function deleteComment(commentID) {
        //rushee.comments
        if (!rushee || !rushee.comments) {
            return
        }

        let comments = rushee.comments

        if (!(commentID in comments)) {
            return
        }

        delete comments[commentID]
        let newRushee = { ...rushee }
        newRushee.comments = comments
        updateRushee(newRushee)

        const rusheeDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(chapter.activeRush)
            .collection('rushees')
            .doc(rusheeSubmission.rusheeID)

        rusheeDoc.update({ [`comments.${commentID}`]: app.firestore.FieldValue.delete() }).catch(function(error) {
            var code = error.code
            var message = error.message
            var details = error.details
            console.log('AN ERROR OCCURRED', code, message, details)
        })
    }

    function deleteTimeline(timelineID) {
        if (!rushee || !rushee.timeline) {
            return
        }

        let timeline = rushee.timeline

        if (!(timelineID in timeline)) {
            return
        }

        delete timeline[timelineID]
        let newRushee = { ...rushee }
        newRushee.timeline = timeline
        updateRushee(newRushee)

        //Remove timeline from this stuff
        let timelineSubmission = {
            chapter: user.getChapter(),
            timelineID: timelineID,
            rusheeID: rusheeID,
        }

        var deleteTimeline = app.functions().httpsCallable('removeRusheeTimeline')
        deleteTimeline(timelineSubmission).catch(function(error) {
            var code = error.code
            var message = error.message
            var details = error.details
            console.log('AN ERROR OCCURRED', code, message, details)
        })
    }

    function viewProfile(memberID) {
        props.history.push('/app/profile/' + memberID)
    }

    function getAttendance() {
        if (rushee && rushee.eventsAttended) {
            let len = rushee.eventsAttended.length
            if (len > 0) {
                let text = 'Attended '
                for (let i = 0; i < len; i++) {
                    let e = rushee.eventsAttended[i]
                    text = text + e.name
                    if (i < len - 2) {
                        //At least 2 more
                        text = text + ', '
                    } else if (i < len - 1) {
                        //1 more
                        if (i > 0) {
                            text = text + ', and '
                        } else {
                            text = text + ' and '
                        }
                    }
                }

                return (
                    <Typography>
                        <CalendarIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                        {text}
                    </Typography>
                )
            }
        }

        return <></>
    }

    function formatAttendancePercentage(attended, total) {
        if (!total) return '0%'
        return `${Math.round((attended / total) * 100)}%`
    }

    const handleAttendanceChange = async (eventId, newValue) => {
        setLoadingEventId(eventId)
        try {
            if (newValue) {
                await addRusheeEvent.mutateAsync({
                    chapterId: user.getChapter(),
                    rusheeId: rusheeID,
                    eventId: eventId,
                    rushId: chapter.activeRush
                })
                getRushee()
            } else {
                await removeRusheeEvent.mutateAsync({
                    chapterId: user.getChapter(),
                    rusheeId: rusheeID,
                    eventId: eventId,
                    rushId: chapter.activeRush
                })
                getRushee()
            }

            // After successful update, refresh the chapter data
            await requestChapterUpdate();
        } catch (error) {
            console.error('Failed to update attendance:', error)
        } finally {
            setLoadingEventId(null)
        }
    }

    const handleCellEditStart = (params, event) => {
        // For attendance column
        if (params.field === 'attended') {
            if (!isAdmin && !params.row.value   ) {
                event.defaultMuiPrevented = true;  // Prevent editing for non-admins
            }
        }
    };

    const renderRushee = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={4} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {rushee && (
                                <Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <MUIAvatar
                                            src={rushee && rushee.photoURL ? rushee.photoURL : templatePerson}
                                            sx={
                                                isMobileDevice()
                                                    ? { width: '100%', maxWidth: 384, aspectRatio: '1', height: 'unset', margin: '0 auto', borderRadius: '4px' }
                                                    : {
                                                          maxWidth: 256,
                                                          width: '100%',
                                                          aspectRatio: '1',
                                                          height: 'unset',
                                                          borderRadius: '4px',
                                                          margin: 'auto',
                                                      }
                                            }
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                        <Grid item sm={8} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Box
                                style={{
                                    transition: `all 0.5s ease`,
                                    WebkitTransition: `all 0.5s ease`,
                                }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {rushee && rushee.first && (
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 4 }}>
                                            <Typography
                                                variant="h3"
                                                style={{ fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                            >
                                                {rushee.first} {rushee.last}
                                            </Typography>
                                            {rushee && (
                                                <>
                                                    {rushee.newMemberNumber ? (
                                                        <Chip className={classes.chip} color="yellow" label={newMemberName + ' ' + rushee.newMemberNumber} />
                                                    ) : (
                                                        getRenderedBidStatus()
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    {rushee && rushee.major && (
                                        <Typography>
                                            <SchoolIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.major}
                                        </Typography>
                                    )}
                                    {rushee && rushee.hometown && (
                                        <Typography>
                                            <LocationIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.hometown}
                                        </Typography>
                                    )}
                                    {rushee && rushee.year && (
                                        <Typography>
                                            <BackpackIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.year} year
                                        </Typography>
                                    )}
                                    {rushee && rushee.phone && (
                                        <Typography component={Link} href={'sms:' + user.phone}>
                                            <PhoneIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.phone}
                                        </Typography>
                                    )}
                                    {rushee && rushee.email && (
                                        <Typography>
                                            <ContactIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.email}
                                        </Typography>
                                    )}
                                    {rushee && rushee.instagram && (
                                        <Typography component={Link} href={`https://instagram.com/${rushee.instagram}`} target="_blank">
                                            <InstagramIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.instagram}
                                        </Typography>
                                    )}
                                    {rushee && rushee.snapchat && (
                                        <Typography component={Link} href={`https://snapchat.com/add/${rushee.snapchat}`} target="_blank">
                                            <SnapchatIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.snapchat}
                                        </Typography>
                                    )}
                                    {rushee && rushee.contact && (
                                        <Typography>
                                            <ContactIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                            {rushee.contact} *Deprecating - use chapter contact instead
                                        </Typography>
                                    )}
                                    
                                    <Grid item flexDirection="row" md={6} xs={12}>
                                        <FormGroup disabled={!isAdmin || isLoadingRushee}>
                                        <FormControl variant="outlined" fullWidth margin="dense">
                                            
                                                <Box sx={{ flex: 1, gap: 2 }}>
                                                    <InputLabel id="bid-status-id">Bid Status</InputLabel>
                                                    <Select
                                                        labelId="bid-status-id"
                                                        value={bidStatus}
                                                        onChange={event => {
                                                            handleBidStatusChange(event)
                                                        }}
                                                        fullWidth
                                                        label="Bid Status"
                                                        disabled={!isAdmin || isLoadingRushee}
                                                    >
                                                        {bidStatusTypes.map(option => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                            </Box>
                                        </FormControl>
                                        <FormControl variant="outlined" fullWidth margin="dense">
                                        {bidStatus === 2 && (
                                                        <TextField
                                                            margin="dense"
                                                            label={`${newMemberName} #`}
                                                            type="number"
                                                            variant="outlined"
                                                            required
                                                            inputProps={{
                                                                maxLength: 3,
                                                            }}
                                                            onChange={async (e) => {
                                                                try {
                                                                    await updateRusheeMutation.mutateAsync({
                                                                        rusheeId: rusheeID,
                                                                        chapterId: user.getChapter(),
                                                                        rushId: chapter.activeRush,
                                                                        data: {
                                                                            newMemberNumber: e.target.value
                                                                        }
                                                                    });
                                                                    getRushee();
                                                                } catch (error) {
                                                                    console.error('Failed to update new member number:', error);
                                                                }
                                                            }}
                                                            value={rushee?.newMemberNumber || ''}
                                                            disabled={!isAdmin || isLoadingRushee}
                                                            fullWidth
                                                        />
                                                )}
                                        </FormControl>
                                        <FormControl variant="outlined" fullWidth margin="dense">
                                        <Autocomplete
                                            key={`chapter-contact-${rusheeID}`}
                                            value={rushee?.chapterContact || null}
                                            margin="dense"
                                            options={Object.entries(chapter.members).map(([id, member]) => ({
                                                ...member,
                                                id
                                            }))}
                                            getOptionLabel={(option) => 
                                                option ? `${option.first} ${option.last}` : ''
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {`${option.first} ${option.last}`}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="dense"
                                                    label="Chapter Contact" 
                                                    error={!!updateRusheeMutation.error}
                                                    helperText={updateRusheeMutation.error ? "Failed to update chapter contact" : ""}
                                                />
                                            )}
                                            onChange={async (event, newValue) => {
                                                if (!isAdmin) return;
                                                try {
                                                    await updateRusheeMutation.mutateAsync({
                                                        rusheeId: rusheeID,
                                                        chapterId: user.getChapter(),
                                                        rushId: chapter.activeRush,
                                                        data: {
                                                            chapterContact: newValue
                                                        }
                                                    })
                                                    getRushee()
                                                } catch (error) {
                                                    console.error('Failed to update chapter contact:', error)
                                                }
                                            }}
                                            disabled={!isAdmin || isLoadingRushee}
                                            sx={{'& .MuiAutocomplete-input': {
                                                padding: '2px 4px !important',
                                                height: '1.2em',
                                            }}}
                                            isOptionEqualToValue={(option, value) =>
                                                option && value && option.id === value.id
                                            }
                                        />
                                        </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {getAttendance()}
                                    {isLoadingRushee && (
                                        <Box className={classes.loadingRushee}>
                                            <CircularProgress size={28} />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {isVotingEnabled && numBrothers > 0 && (
                    <>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection label={'Voting Statistics'} icon={<VotingIcon />} showHeader={true}>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <Box display="flex" alignItems="center">
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Grid item sm={6} xs={12}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    style={{
                                                        flexDirection: 'column',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            padding: 6,
                                                            position: 'relative',
                                                            minHeight: 80,
                                                        }}
                                                    >
                                                        {numBrothers > 0 && (
                                                            <Typography 
                                                                variant={'caption'} 
                                                                style={{ 
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    fontWeight: 900,
                                                                    zIndex: 1
                                                                }}
                                                            >
                                                                {getPercentBrothersMet()}
                                                            </Typography>
                                                        )}
                                                        <ResponsiveContainer width="100%" height={80}>
                                                            {numBrothers > 0 && (
                                                                <PieChart>
                                                                    <Pie 
                                                                        data={getBrothersMetData()} 
                                                                        innerRadius={30} 
                                                                        outerRadius={50} 
                                                                        dataKey="value"
                                                                        cx="50%"
                                                                        cy="50%"
                                                                    >
                                                                        {getBrothersMetData().map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={theme.palette[entry.color].main} stroke={''} />
                                                                        ))}
                                                                    </Pie>
                                                                </PieChart>
                                                            )}
                                                        </ResponsiveContainer>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={6} xs={12} style={{ marginBottom: 8 }}>
                                                <Grid container spacing={1} flex-direction="column">
                                                    {containsVoting() &&
                                                        canPreviewVotes() &&
                                                        getVoteFields().map((field, index) => (
                                                            <Grid item xs={12} className={classes.progressSection} key={index}>
                                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                    <Typography>{field.name}</Typography>
                                                                    <Typography sx={{ textAlign: 'right', minWidth: '70px' }}>
                                                                        
                                                                        ({ field.type === 'CHECKBOX' ? `${field.trueCount} of ${field.totalCount}` : field.value.toFixed(2)+'%'})
                                                                    </Typography>
                                                                </Box>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={field.value}
                                                                    classes={{
                                                                        barColorPrimary: classes.progressBar,
                                                                    }}
                                                                    className={classes.progress}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    {containsVoting() && !canPreviewVotes() && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <Typography>You must vote to view the voting results</Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            style={{
                                                flexGrow: 3,
                                                maxWidth: 256,
                                                margin: '0 auto',
                                            }}
                                            disabled={isUpdatingVote}
                                            onClick={() => setIsVotingOpen(true)}
                                        >
                                            Vote
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <Typography variant="h6" sx={{ m: 5 }}  fontWeight="bold">
                                        <PersonIcon style={{ verticalAlign: 'top' }} />
                                        Met & Voted ({getPercentBrothersMet()})
                                    </Typography>
                                    <DataGrid
                                        density='compact'
                                        checkboxSelection={false}
                                        rows={getBrothersMetDataTable()}
                                        columns={[
                                            {
                                                field: 'first',
                                                headerName: 'First',
                                                editable: false,
                                                sortable: true,
                                                flex: 1,
                                            },
                                            {
                                                field: 'last',
                                                headerName: 'Last',
                                                editable: false,
                                                sortable: true,
                                                flex: 1,
                                            }
                                        ]}
                                        initialState={{
                                        pagination: {
                                            paginationModel: {
                                            pageSize: 5,
                                            },
                                        },
                                        }}
                                        pageSizeOptions={[5]}
                                        disableRowSelectionOnClick
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <Divider className={classes.divider} style={{ marginTop: 15, marginBottom: 15 }}/>
                                <Typography variant="h6" fontWeight="bold">
                                        <CalendarIcon style={{ verticalAlign: 'top' }} />
                                        Attendance ({formatAttendancePercentage(rushEventsAttended, rushEventsCount)})
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>              
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(newValue) => setStartDate(newValue)}
                                                disabled={true}
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        helperText: !isAdmin ? "Only admins can filter dates" : ""
                                                    }
                                                }}
                                            />
                                            <DatePicker
                                                label="End Date"
                                                value={endDate}
                                                onChange={(newValue) => setEndDate(newValue)}
                                                disabled={true}
                                                slotProps={{
                                                    textField: {
                                                        size: "small"
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip title="Admins can update the date range in the active Rush settings.">
                                                <InfoIcon />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <DataGrid
                                        density='compact'
                                        checkboxSelection={false}
                                        rows={rushEvents}
                                        columns={[
                                            {
                                                field: 'id',
                                                headerName: 'ID',
                                                editable: false,
                                                sortable: true,
                                                flex: 1
                                            },
                                            {
                                                field: 'name',
                                                headerName: 'Event',
                                                editable: false,
                                                sortable: true,
                                                flex: 1,
                                            },
                                            {
                                                field: 'eventTiming',
                                                headerName: 'Date',
                                                editable: false,
                                                sortable: true,
                                                filterable: false,
                                                flex: 1
                                            },
                                            {
                                                field: 'attended',
                                                headerName: 'Attendance',
                                                editable: true,
                                                filterable: true,
                                                type: 'boolean',
                                                sortable: true,
                                                flex: 1,
                                                renderCell: (params) => {
                                                    if (loadingEventId === params.row.id) {
                                                        return (
                                                            <CircularProgress 
                                                                size={20} 
                                                                sx={{ margin: '0 auto' }} 
                                                            />
                                                        )
                                                    }
                                                    
                                                    if (params.value === true) {
                                                        return (
                                                            <>
                                                            <PresentIcon style={{ color: 'green' }} />
                                                            <Tooltip title="Only rush admins can delete attendance">
                                                                <div>
                                                                    <Button 
                                                                        onClick={() => handleAttendanceChange(params.id, false)}
                                                                        disabled={!isAdmin}
                                                                    >
                                                                        Clear
                                                                    </Button>
                                                                </div>
                                                            </Tooltip>
                                                            </>                                                                                                                      
                                                        )
                                                    }
                                                    return (
                                                        <>
                                                            <Button onClick={() => handleAttendanceChange(params.id, true)}>Mark Present</Button>
                                                        </>
                                                    )
                                                },
                                                preProcessEditCellProps: (params) => {
                                                    const { value } = params.props
                                                    handleAttendanceChange(params.id, value)
                                                    return { ...params.props }
                                                }
                                            }, 
                                            {
                                                field: 'confirmedBy',
                                                headerName: 'Confirmed By',
                                                editable: false,
                                                sortable: false,
                                                flex: 1,
                                                renderCell: (params) => {
                                                    return (
                                                        <Typography>{params?.value || ''}</Typography>
                                                    )
                                                }
                                            }
                                        ]}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false,
                                                    confirmedBy: !isMobile && !isMobileDevice(),
                                                    eventTiming: !isMobile && !isMobileDevice()
                                                },
                                            },
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5]}
                                        disableRowSelectionOnClick
                                        disableSelectionOnClick
                                        onCellEditStart={handleCellEditStart}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                    </>
                )}
                {rushee && timelineEnabled && (
                    <>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection label="Timeline" icon={<TimelineIcon />} showHeader={true}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {getTimeline()}
                                    <Box display="flex" mt={3} className={classes.addCommentBox}>
                                        <FormControl style={{ flexGrow: 1 }}>
                                            <Input
                                                placeholder="Write your timeline..."
                                                required
                                                inputProps={{
                                                    maxLength: 280,
                                                }}
                                                multiline
                                                onChange={e => {
                                                    updateTimelineStatus(e.target.value)
                                                }}
                                                variant="outlined"
                                                maxRows={3}
                                                value={timeline}
                                                margin="dense"
                                                style={{ marginLeft: 2 }}
                                            />
                                            <FormHelperText error id="component-error-text">
                                                {timeline.length === 0 && hasTypedTimeline && "Your timeline can't be blank"}
                                            </FormHelperText>
                                        </FormControl>
                                        <Box sx={{ marginLeft: 4, display: `flex`, flexDirection: `column`, justifyContent: `center` }}>
                                            {isLoadingTimeline ? (
                                                <Box className={classes.commentProgress}>
                                                    <CircularProgress size={28} style={{ margin: `auto 0` }} />
                                                </Box>
                                            ) : (
                                                <IconButton aria-label="submit" disabled={timeline.length === 0} onClick={e => addTimeline()} size="small">
                                                    <SendIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </FormSection>
                        </Grid>
                    </>
                )}
                {rushee && commentsEnabled && (
                    <>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection label="Comments" icon={<CommentsIcon />} showHeader={true}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {getComments()}
                                    <Box display="flex" mt={3} className={classes.addCommentBox}>
                                        <FormControl style={{ flexGrow: 1 }}>
                                            <Input
                                                placeholder="Write your comment..."
                                                required
                                                inputProps={{
                                                    maxLength: 280,
                                                }}
                                                multiline
                                                onChange={e => {
                                                    updateCommentStatus(e.target.value)
                                                }}
                                                variant="outlined"
                                                maxRows={3}
                                                value={comment}
                                                margin="dense"
                                                style={{ marginLeft: 2 }}
                                            />
                                            <FormHelperText error id="component-error-text">
                                                {comment.length === 0 && hasTypedComment && "Your comment can't be blank"}
                                            </FormHelperText>
                                        </FormControl>
                                        <Box sx={{ marginLeft: 4, display: `flex`, flexDirection: `column`, justifyContent: `center` }}>
                                            {isLoadingComment ? (
                                                <Box className={classes.commentProgress}>
                                                    <CircularProgress size={28} style={{ margin: `auto 0` }} />
                                                </Box>
                                            ) : comment.length === 0 ? (
                                                <IconButton aria-label="send message" disabled size="small">
                                                    <SendIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton aria-label="send message" onClick={e => addComment()} size="small">
                                                    <SendIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </FormSection>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }

    const getRightButtons = () => {
        if (rushee && isAdmin) {
            return [
                {
                    name: 'Edit',
                    type: 'icon',
                    innerIcon: <EditIcon />,
                    onClick: () => {
                        props.history.push('/app/applications/rush/rushee/' + rusheeID + '/edit/')
                    },
                },
            ]
        }

        return []
    }

    const handleBidStatusChange = async (event) => {
        const newStatus = event.target.value
        setBidStatus(newStatus)
        
        try {
            await updateRusheeMutation.mutateAsync({
                rusheeId: rusheeID,
                chapterId: user.getChapter(),
                rushId: chapter.activeRush,
                data: {
                    bidStatus: newStatus
                }
            })
            getRushee()
        } catch (error) {
            console.error('Failed to update bid status:', error)
            // Revert to previous status on error
            setBidStatus(rushee?.bidStatus || 0)
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Rush',
                            link: '/app/applications/rush/',
                            icon: <RushIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'Rushee',
                        },
                    ]}
                    key={rushee && isAdmin ? 'admin' : 'not'}
                    rightButtons={getRightButtons()}
                    grid
                />
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        <Box>{renderRushee()}</Box>
                    ) : (
                        <Paper>
                            <Box style={{ padding: '1rem' }}>{renderRushee()}</Box>
                        </Paper>
                    )}
                </Grid>
            </Grid>
            <Dialog open={isVotingOpen} onClose={() => setIsVotingOpen(false)} disableClose={isUpdatingVote}>
                <DialogTitle onClose={() => setIsVotingOpen(false)} disableClose={isUpdatingVote}>
                    Vote
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        You can only vote once per rushee, voting again will overwrite your previous vote. When you vote, you are also marking that you've met
                        the rushee.
                    </DialogContentText>
                    {voteFields &&
                        usersVote &&
                        voteFields.map(field => (
                            <React.Fragment key={field.id}>
                                {field.type === 'CHECKBOX' && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={usersVote[field.id]}
                                                onChange={event => {
                                                    if (event.target.name !== undefined) {
                                                        setUsersVote({
                                                            ...usersVote,
                                                            [event.target.name]: event.target.checked,
                                                        })
                                                    }
                                                }}
                                                name={field.id}
                                                color="primary"
                                            />
                                        }
                                        label={field.name}
                                    />
                                )}

                                {field.type === 'SLIDER' && (
                                    <>
                                        <DialogContentText style={{ marginBottom: 0 }}>
                                            {field.name}
                                        </DialogContentText>
                                        <Slider
                                            value={usersVote[field.id]}
                                            onChange={(event, newValue) => {
                                                if (usersVote[field.id] !== newValue) {
                                                    setUsersVote({
                                                        ...usersVote,
                                                        [field.id]: newValue,
                                                    })
                                                }
                                            }}
                                            id={field.id}
                                            valueLabelDisplay="on"
                                            min={1}
                                            max={10}
                                            marks={true}
                                            step={1}
                                        />
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsVotingOpen(false)} disabled={isUpdatingVote} color="primary">
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={() => {
                            voteOnRushee()
                        }}
                        loading={isUpdatingVote}
                        color="primary"
                    >
                        Vote
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
