import { useMutation } from '@tanstack/react-query';
import { db } from 'config/firebase'
import { queryClient } from 'lib/queryClient';
import { queryKeys } from 'lib/queryKeys';
export const useUpdateRushee = ({chapterId, rushId, rusheeId}) => {
  return useMutation({
    mutationFn: updateRushee,
    onSuccess: () => {
        queryClient.invalidateQueries({
            queryKey: [queryKeys.rushee.detail, chapterId, rushId, rusheeId]
        })
        queryClient.invalidateQueries({
            queryKey: [queryKeys.rushee.all, chapterId, rushId]
        })
    },
  });
}; 

const updateRushee = async ({rusheeId, chapterId, rushId, data}) => {
    const rusheeRef = db
        .collection('chapters')
        .doc(chapterId)
        .collection('rushes')
        .doc(rushId)
        .collection('rushees')
        .doc(rusheeId)

    await rusheeRef.update(data)
    return rusheeRef
}

