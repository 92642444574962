import { Button } from '@material-ui/core'
import {
    NotificationsNone as NotificationsIcon,
    ThumbUp as ThumbUpIcon,
    ShoppingCart as ShoppingCartIcon,
    LocalOffer as TicketIcon,
    BusinessCenter as DeliveredIcon,
    SmsFailed as FeedbackIcon,
    DiscFull as DiscIcon,
    Email as MessageIcon,
    Error as DefenceIcon,
    AccountBox as CustomerIcon,
    Done as ShippedIcon,
    Publish as UploadIcon,
    InsertDriveFile as ReportIcon,
    Announcement as AnnouncementIcon,
    Message as NewsletterIcon,
    InsertDriveFileOutlined as UploadFileIcon,
    CreateNewFolderOutlined as NewFolderIcon,
    FolderOutlined as UploadFolderIcon,
    Delete as DeleteIcon,
    Settings as SettingsIcon,
    Archive as ArchiveIcon,
    Update as UpdateIcon,
} from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import React from 'react'
import classnames from 'classnames'
import tinycolor from 'tinycolor2'

import { Typography } from '../Wrappers'
import useStyles from './styles'

const typesIcons = {
    'e-commerce': <ShoppingCartIcon />,
    notification: <NotificationsIcon />,
    offer: <TicketIcon />,
    info: <ThumbUpIcon />,
    message: <MessageIcon />,
    feedback: <FeedbackIcon />,
    customer: <CustomerIcon />,
    shipped: <ShippedIcon />,
    delivered: <DeliveredIcon />,
    defence: <DefenceIcon />,
    report: <ReportIcon />,
    upload: <UploadIcon />,
    disc: <DiscIcon />,
    announcement: <AnnouncementIcon />,
    newsletter: <NewsletterIcon />,
    new_folder: <NewFolderIcon />,
    upload_file: <UploadFileIcon />,
    upload_folder: <UploadFolderIcon />,
    delete: <DeleteIcon />,
    settings: <SettingsIcon />,
    archive: <ArchiveIcon />,
    update: <UpdateIcon />,
}

export default function Notification({ variant, ...props }) {
    var classes = useStyles()
    var theme = useTheme()

    const icon = getIconByType(props.type)
    const iconWithStyles = React.cloneElement(icon, {
        classes: {
            root: classes.notificationIcon,
        },
        style: {
            color:
                variant !== 'contained' &&
                theme.palette[props.color] &&
                theme.palette[props.color].main,
        },
    })

    return (
        <div
            className={classnames(
                classes.notificationContainer,
                props.className,
                {
                    [classes.notificationContained]: variant === 'contained',
                    [classes.notificationContainedShadowless]: props.shadowless,
                }
            )}
            style={{
                backgroundColor:
                    variant === 'contained' &&
                    theme.palette[props.color] &&
                    theme.palette[props.color].main,
            }}
        >
            <div
                className={classnames(classes.notificationIconContainer, {
                    [classes.notificationIconContainerContained]:
                        variant === 'contained',
                    [classes.notificationIconContainerRounded]:
                        variant === 'rounded',
                })}
                style={{
                    backgroundColor:
                        variant === 'rounded' &&
                        theme.palette[props.color] &&
                        tinycolor(theme.palette[props.color].main)
                            .setAlpha(0.15)
                            .toRgbString(),
                }}
            >
                {iconWithStyles}
            </div>
            <div className={classes.messageContainer}>
                <Typography
                    className={classnames({
                        [classes.containedTypography]: variant === 'contained',
                    })}
                    variant={props.typographyVariant}
                    size={
                        variant !== 'contained' &&
                        !props.typographyVariant &&
                        'md'
                    }
                >
                    {props.message}
                </Typography>
                {props.extraButton && props.extraButtonClick && (
                    <Button
                        onClick={props.extraButtonClick}
                        disableRipple
                        className={classes.extraButton}
                    >
                        {props.extraButton}
                    </Button>
                )}
            </div>
        </div>
    )
}

// ####################################################################
function getIconByType(type = 'offer') {
    return typesIcons[type]
}
