import React, { useContext, useState } from 'react'
import { Grid, Card, CardHeader, CardContent, Typography, Button, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import NavigationBar from 'components/NavigationBar'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import useStyles from '../styles';
import { useGetRushes } from 'hooks/queries/rush/useGetRushes'
import { AuthContext } from 'context/AuthContext'
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function Reporting(props) {
    const { id } = props.match.params // TODO: pull this data fresh vs from context
    const { user } = useContext(AuthContext)
    const { data: rush, isLoading: isLoadingRush, isError: isErrorRush } = useGetRushes({chapterId: user.getChapter(), rushId: id})
    const theme = useTheme()

    const classes = useStyles()
    const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if(isLoadingRush) {
        return <Grid container spacing={2}><Grid item xs={12}></Grid></Grid>
    }
    if(isErrorRush) {
        return <Grid container spacing={2}><Grid item xs={12}></Grid></Grid>
    }
  const processRushData = (rushees) => {
    // Calculate total applicants
    const totalApplicants = rushees.length;
  
    // Calculate funnel data
    const bidStatusCounts = rushees.reduce((acc, rushee) => {
      const status = getBidStatus(rushee.bidStatus);
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});
  
    // Calculate conversion rate (Accepted / Total)
    const acceptedCount = bidStatusCounts['Accepted'] || 0;
    const conversionRate = (acceptedCount / totalApplicants * 100).toFixed(1);
  
    // Calculate active rushees (those not in final states)
    const activeRushees = rushees.filter(rushee => 
      !['Accepted', 'Denied', 'Rejected'].includes(getBidStatus(rushee.bidStatus))
    ).length;
  
    // Calculate disqualification rate (Denied + Rejected / Total)
    const countDisqualified = ((bidStatusCounts['Denied'] || 0) + (bidStatusCounts['Rejected'] || 0))
    const disqualificationRate = (countDisqualified / totalApplicants * 100).toFixed(1);
  
    // Calculate chapter contact distribution
    const chapterContactStats = rushees.reduce((acc, rushee) => {
      if (rushee.chapterContact) {
        const contactId = rushee.chapterContact.id;
        if (!acc[contactId]) {
          acc[contactId] = {
            name: `${rushee.chapterContact.first} ${rushee.chapterContact.last}`,
            count: 0,
            role: rushee.chapterContact.role
          };
        }
        acc[contactId].count++;
      }
      return acc;
    }, {});

    // calculate the average number of events attended per rushee
    const averageEventsAttended = rushees.reduce((acc, rushee) => acc + rushee.eventCount, 0) / rushees.length

    // Process funnel stages for visualization
    const funnelStages = [
      { stage: 'Total', count: totalApplicants },
      { stage: 'Received', count: bidStatusCounts['Received'] || 0 },
      { stage: 'Voted', count: bidStatusCounts['Voted'] || 0 },
      { stage: 'Accepted', count: bidStatusCounts['Accepted'] || 0 }
    ];

    // Calculate team leader performance data
    const teamLeaderStats = rushees.reduce((acc, rushee) => {
      if (!rushee.chapterContact) return acc;
      
      const contactId = rushee.chapterContact.id;
      const contactName = `${rushee.chapterContact.first} ${rushee.chapterContact.last}`;
      
      if (!acc[contactId]) {
        acc[contactId] = {
          id: contactId,  // Add ID for filtering
          name: contactName,
          assignedRushees: 0,
          activeRushees: 0,
          completedRushees: 0,
          totalEvents: 0,
          acceptedCount: 0,
          rushees: []  // Store all rushees for filtering
        };
      }
      
      acc[contactId].assignedRushees++;
      acc[contactId].totalEvents += rushee.eventCount || 0;
      acc[contactId].rushees.push(rushee);  // Store rushee for filtering
      
      const bidStatus = getBidStatus(rushee.bidStatus);
      
      // Count active rushees (not in final states)
      if (!['Accepted', 'Denied', 'Rejected'].includes(bidStatus)) {
        acc[contactId].activeRushees++;
      } else {
        acc[contactId].completedRushees++;  // Count completed rushees
      }
      
      // Count accepted rushees for conversion rate
      if (bidStatus === 'Accepted') {
        acc[contactId].acceptedCount++;
      }
      
      return acc;
    }, {});

    // Transform the stats into the required format
    const teamLeaderData = Object.values(teamLeaderStats).map(leader => ({
      id: leader.id,  // Include ID in the transformed data
      name: leader.name,
      assignedRushees: leader.assignedRushees,
      activeRushees: leader.activeRushees,
      completedRushees: leader.completedRushees,
      averageEventsAttended: leader.assignedRushees > 0 
        ? Math.round((leader.totalEvents / leader.assignedRushees) * 10) / 10 
        : 0,
      conversionRate: leader.assignedRushees > 0 
        ? Math.round((leader.acceptedCount / leader.assignedRushees) * 100) 
        : 0,
      rushees: leader.rushees  // Include rushees for filtering
    }));

    return {
      totalApplicants,
      conversionRate,
      activeRushees,
      chapterContactStats,
      funnelStages,
      bidStatusCounts,
      averageEventsAttended,
      disqualificationRate,
      teamLeaderData,
      // Add filtered rushees based on selected team leader
      filteredRushees: selectedTeamLeader 
        ? teamLeaderData.find(leader => leader.id === selectedTeamLeader)?.rushees || []
        : rushees
    };
  };

  let stats = {}
  
  
  // Helper function to get bid status string
  const getBidStatus = (status) => {
    const statusMap = {
      0: 'None',
      1: 'Received',
      2: 'Accepted',
      3: 'Denied',
      4: 'Rejected',
      5: 'Voted'
    };
    return statusMap[status] || '';
  };
  if(rush) {
    stats = processRushData(rush.rushees)
    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Rush',
                            link: `/app/applications/rush`,
                        },
                        {
                            name: 'Reporting',
                        },
                    ]}
                    grid={true}
                />
                 <Grid item xs={12} md={6} lg={3} >
                    <Card className={classes.card}>
                        <CardContent style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            minHeight: '120px'  // Add fixed height to ensure vertical centering
                        }}>
                            <h3 className="text-sm text-gray-500">Total Candidates</h3>
                            <p className="text-2xl font-bold mt-2">{stats.totalApplicants}</p>
                            {/* <p className="text-xs text-green-500 mt-1">↑ 12% vs last rush</p> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card className={classes.card}>
                        <CardContent style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            minHeight: '120px'  // Add fixed height to ensure vertical centering
                        }}>
                            <h3 className="text-sm text-gray-500">Avg Rushee Events Attended</h3>
                            <p className="text-2xl font-bold mt-2">{(stats.averageEventsAttended).toFixed(1)}</p>
                            {/* <p className="text-xs text-green-500 mt-1">↑ 12% vs last rush</p> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card className={classes.card} style={{ 
                        backgroundColor: theme.palette.error.light,
                        opacity: 0.9 
                    }}>
                        <CardContent style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            minHeight: '120px'
                        }}>
                            <h3 className="text-sm text-gray-500">Disqualication Rate</h3>
                            <p className="text-2xl font-bold mt-2">{stats.disqualificationRate}%</p>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card className={classes.card} style={{ 
                        backgroundColor: theme.palette.success.light,
                        opacity: 0.9 
                    }}>
                        <CardContent style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            minHeight: '120px'
                        }}>
                            <h3 className="text-sm text-gray-500">Conversion Rate</h3>
                            <p className="text-2xl font-bold mt-2">{stats.conversionRate}%</p>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card>
              <CardHeader
                title={
                  <h3 className="flex items-center gap-2">
                    <PersonIcon className="h-5 w-5" />
                    Chapter Contact Metrics
                  </h3>
                }
              />
              <CardContent>
                <div style={{ width: '100%' }}>
                  <DataGrid
                    rows={stats.teamLeaderData}
                    columns={isMobile ? mobileColumns : desktopColumns}
                    pageSize={isMobile ? 10 : 5}
                    rowsPerPageOptions={isMobile ? [10, 20] : [5, 10, 20]}
                    disableSelectionOnClick
                    autoHeight
                    onRowClick={(params) => {
                      setSelectedTeamLeader(selectedTeamLeader === params.row.id ? null : params.row.id);
                    }}
                    sx={{
                      '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&.Mui-selected': {
                          backgroundColor: theme.palette.action.selected,
                          '&:hover': {
                            backgroundColor: theme.palette.action.selected,
                          },
                        },
                      },
                    }}
                    selectionModel={selectedTeamLeader ? [selectedTeamLeader] : []}
                  />
                </div>
              </CardContent>
            </Card>
                </Grid>
                </Grid>
                    {selectedTeamLeader && (
                      <Card style={{ marginTop: '20px' }}>
                        <CardHeader 
                          title={`Rushees for ${stats.teamLeaderData.find(l => l.id === selectedTeamLeader)?.name}`}
                        />
                        <CardContent>
                          <Grid container spacing={2}>
                            {stats.filteredRushees.map((rushee, index) => (
                              <Grid item xs={12} md={6} lg={4} key={index}>
                                <Card variant="outlined">
                                  <CardHeader
                                    title={`${rushee.first} ${rushee.last}`}
                                    subheader={getBidStatus(rushee.bidStatus)}
                                    action={
                                      <div style={{ 
                                        padding: '4px 8px', 
                                        borderRadius: '4px',
                                        backgroundColor: rushee.bidStatus === 2 ? theme.palette.success.light : 
                                                      rushee.bidStatus === 3 || rushee.bidStatus === 4 ? theme.palette.error.light :
                                                      theme.palette.grey[200]
                                      }}>
                                        {getBidStatus(rushee?.bidStatus)}
                                      </div>
                                    }
                                  />
                                  <CardContent>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                      {/* Contact Info */}
                                      <div>
                                        <Typography variant="subtitle2" color="textSecondary">Contact Info</Typography>
                                        <Typography>Email: {rushee.email}</Typography>
                                        <Typography>Hometown: {rushee.hometown}</Typography>
                                        <Typography>Instagram: {rushee.instagram }</Typography>
                                        <Typography>Snapchat: {rushee.snapchat}</Typography>
                                      </div>

                                      {/* Academic Info */}
                                      <div>
                                        <Typography variant="subtitle2" color="textSecondary">Academic Info</Typography>
                                        <Typography>Major: {rushee.major}</Typography>
                                        <Typography>Year: {rushee.year}</Typography>
                                      </div>

                                      {/* Voting Stats */}
                                      <div>
                                        <Typography variant="subtitle2" color="textSecondary">Rush Candidate Activity</Typography>
                                        <div style={{ display: 'flex', gap: '16px', marginTop: '4px' }}>
                                          <div>
                                            <Typography variant="h6" style={{ color: theme.palette.success.main }}>
                                              {rushee?.voteCount || 0}
                                            </Typography>
                                            <Typography variant="caption">Votes Cast</Typography>
                                          </div>
                                          <div>
                                            <Typography variant="h6" style={{ color: theme.palette.success.main }}>
                                              {rushee?.commentCount || 0}
                                            </Typography>
                                            <Typography variant="caption">Comments</Typography>
                                          </div>
                                          <div>
                                            <Typography variant="h6" style={{ color: theme.palette.success.main }}>
                                              {rushee?.eventCount || 0}
                                            </Typography>
                                            <Typography variant="caption">Events Attended</Typography>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ marginTop: '16px' }}
                                    onClick={() => props.history.push(`/app/applications/rush/rushee/${rushee.id}`)}
                                  >
                                    View Full Profile
                                  </Button>
                                  </CardContent>
                                  
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </CardContent>
                      </Card>
                    )}

            </>
        )
    }
}

const desktopColumns = [
  { field: 'name', headerName: 'Team Leader', flex: 1 },
    { field: 'assignedRushees', headerName: 'Assigned Rushees', type: 'number', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'activeRushees', headerName: 'Active Rushees', type: 'number', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'completedRushees', headerName: 'Completed Rushees', type: 'number', width: 150, align: 'center', headerAlign: 'center' },
    { 
        field: 'averageEventsAttended', 
        headerName: 'Avg Events Attended', 
        type: 'number', 
        width: 180, 
        align: 'center', 
        headerAlign: 'center' 
    },
    { 
        field: 'conversionRate', 
        headerName: 'Conversion Rate', 
        type: 'number', 
        width: 150, 
        align: 'center', 
        headerAlign: 'center',
        valueFormatter: (params) => `${params.value}%`
    },
];

const mobileColumns = [
    {
        field: 'name',
        headerName: 'Team Leader Stats',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ 
                padding: '16px',
                margin: 5,
                width: '100%'
            }}>
                <Typography variant="subtitle2">{params.row.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {params.row.assignedRushees} Assigned • {params.row.activeRushees} Active
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {params.row.averageEventsAttended} Avg Events • {params.row.conversionRate}% Conv.
                </Typography>
            </Box>
        ),
    }
];