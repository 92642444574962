import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import React, { createContext, useContext } from 'react';

// Initialize mixpanel
mixpanel.init('c764bbc44c8418cd10018b21c3b2de83', {
    debug: process.env.NODE_ENV !== 'production',
    persistence: 'localStorage',
    //api_host: ' https://mixpanelproxy-jlickqjoza-uc.a.run.app',
    api_host: 'https://proxy.greekconnect.app',
    api_method: 'POST',
    cross_site_cookie: false,
    track_pageview: false
    // track_pageview: "url-with-path"
});

// Analytics helper functions
export const Analytics = {
    identify: (userId, traits = {}) => {
        mixpanel.identify(userId);
        mixpanel.people.set(traits);
    },

    track: (name, props) => {
        mixpanel.track(name, props);
    },

    pageView: (pageName, props = {}) => {
        mixpanel.track_pageview()
        //mixpanel.track('Page View', {
        //    page: pageName,
        //    ...props
        // });
    },

    setUserProfile: (props) => {
        mixpanel.people.set(props);
    },

    reset: () => {
        mixpanel.reset();
    }
};

// React Hook for tracking page views
export const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        // Only track if we have a valid location
        if (location) {
            Analytics.pageView(location.pathname, {
                search: location.search,
                hash: location.hash
            });
        }
    }, [location]);
};

// Analytics Provider Component
const AnalyticsContext = createContext(null);

export const AnalyticsProvider = ({ children }) => {
    return (
        <AnalyticsContext.Provider value={Analytics}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => {
    const context = useContext(AnalyticsContext);
    if (context === null) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
};