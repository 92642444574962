import {
    Box,
    Button as ButtonCore,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Select,
    Switch,
    TextField,
    Link,
} from '@material-ui/core'
import {
    PeopleOutlined as AttendanceIcon,
    CalendarToday as CalendarIcon,
    CategoryOutlined as CategoryIcon,
    Close as CloseIcon,
    AttachMoney as CostIcon,
    DeleteOutline as DeleteIcon,
    Subject as DescriptionIcon,
    HomeOutlined as HomeIcon,
    Link as LinkIcon,
    LocationOnOutlined as LocationIcon,
    NotificationsNoneOutlined as NotificationsAlarm,
    Timeline as PointsIcon,
    MailOutlined as RSVPIcon,
    SyncOutlined as SortIcon,
    Sync as SyncIcon,
    VisibilityOutlined as VisibilityIcon,
} from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { convertObjectToList, isMobileDevice, markSetupStepComplete } from 'code/Helper'
import { DriveIcon, ItineraryIcon, RSVPGroupsIcon, RSVPWaitlistIcon } from 'components/Icons'
import app from 'firebase/app'
import React, {useState, useContext} from 'react'

import moment from 'moment-timezone'

import { getDateFromYMD, getFirebaseDate, getYMDFromDate } from 'code/TimeAgo'
import { compileVisibility, defaultVisibilityOptions, expandVisibility, visibilityOptions } from 'data/Visibility'
import { alarmsEventDeadline, alarmsEventStart, alarmsRSVPDeadline, alarmsSignInOpen, excuseDeadlines, rsvpWaitlistDeadline, times } from '../mock'

import FormSection from 'components/Forms/FormSection'
import NavigationBar from 'components/NavigationBar'
import SelectAutocomplete from 'components/SelectAutocomplete'
import ErrorTypography from 'components/Typography/ErrorTypography'
import Widget from 'components/Widget'
import { Button, Typography } from 'components/Wrappers'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import useStyles from './styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import DateRangePicker from 'components/DateRangePicker/DateRangePicker'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

/**
 * Notes - 
 * if duplicating an existing event, the event data is sent in the props.location.data
 * if editing an existing event, the event data is pulled from firestore
 */
export default function NewEvent(props) {
    const theme = useTheme()
    const classes = useStyles()

    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))
    const atLeastLarge = useMediaQuery(theme.breakpoints.up('lg'))

    const { user } = useContext(AuthContext)
    const { chapter, requestChapterUpdate, requestChapterUpdateWithDelay } = useContext(ChapterContext)

    let eventID = props?.match?.params?.id
    let isNew = eventID ? false : true
    const [eventExists, setEventExists] = useState(isNew ? true : false)
    const [hasGrabbed, setHasGrabbed] = useState(isNew ? true : false)

    const [eventCost, setEventCost] = useState(getDefaultValue('cost', null))
    const [eventName, setEventName] = useState(getDefaultValue('name', ''))
    const [error, setError] = useState('')
    const [eventLocation, setEventLocation] = useState(getDefaultValue('location', ''))
    
    const [eventAddress, setEventAddress] = useState(getDefaultValue('address', ''))
    const [eventAlarms, setEventAlarms] = useState(arrayifyAlarms())
    const [eventDescription, setEventDescription] = useState(getDefaultValue('description', ''))
    var [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState(getDefaultValue('categories', []))
    const [maxAttendees, setMaxAttendees] = useState(getDefaultSubfield('rsvp', 'maxAttendees', null))
    const [rsvpGuests, setRSVPGuests] = useState(getExpandedRsvpGuestsInitial())
    const [rsvpDeadline, setRSVPDeadline] = useState(0)
    const [rsvpWaitlist, setRSVPWaitlist] = useState({
        enabled: false, // If enabled, someone can drop as long as rsvp's are open
        max: 10,
        deadline: 60 * 10,
        autoApprove: false, // Do the people on waitlist get automatically approved => not completed
    })
    const [rsvpGroups, setRSVPGroups] = useState({
        enabled: false, // If enabled, someone can drop as long as rsvp's are open
        groups: [], // List of id, label, numSpots
    })
    const [rsvpDisplayAttendees, setRSVPDisplayAttendees] = useState(getDefaultSubfield('rsvp', 'vis', 0))
    const [signInType, setSignInType] = useState(getDefaultSubfield('signIn', 'type', 0))
    const [signInStart, setSignInStart] = useState(getDefaultSubfield('signIn', 'start', 5))
    const [signInPassword, setSignInPassword] = useState('')
    const [excuseDeadline, setExcuseDeadline] = useState(getDefaultSubfield('excuses', 'deadline', -180))
    const [categoryOptions, setCategoryOptions] = useState(chapter ? chapter.categories : null)
    const [pointOptions] = useState(chapter && chapter.points ? convertObjectToList(chapter.points) : [])
    const [visibility, setVisibility] = useState(expandVisibility(getDefaultValue('visibility', compileVisibility(defaultVisibilityOptions))))
    const [links, setLinks] = useState(getDefaultValue('links', []))
    const [points, setPoints] = useState(getDefaultValue('points', []))
    const [syncing, setSyncing] = useState(getDefaultValue('sync', true))
    const [placeData, setPlaceData] = useState(props.location?.data ? props.location.data?.googleMapsPlace ?? null : null);

    function arrayifyAlarms() {
        let result = { ...getDefaultValue('alarms', {}) }
        return compileAlarms(result)
    }

    function getExpandedRsvpGuestsInitial() {
        let result = { ...getDefaultSubfield('rsvp', 'guests', { enabled: false, min: 0, max: 10, fields: [] }) }
        result.fields = expandRsvpGuestFields(result.fields ?? [])
        return result
    }

    const editors = [{ id: '0', name: 'You' }]

    const [datesError] = useState([null, null])

    const [grabbingEvent, setGrabbingEvent] = useState(false)
    const guestFieldOptions = [
        { id: 0, name: 'Name' },
        { id: 1, name: 'Phone' },
        { id: 2, name: 'Email' },
        { id: 3, name: 'Birthday' },
    ]

    function hasPointsAdminInner() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role))
            }
        }

        return false
    }

    function hasPointsCreateInner() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role)) ||
                    (Array.isArray(chapter.perms['pointsCreate']) && chapter.perms['pointsCreate'].includes(mem.role))
                )
            }
        }

        return false
    }

    const hasPointsAdmin = hasPointsAdminInner()
    const hasPointsCreate = hasPointsCreateInner()

    const filteredPointOptions = pointOptions
        .filter(
            option =>
                hasPointsAdmin || (user && option.authorId === user.getId()) || (user && Array.isArray(option.admins) && option.admins.includes(user.getId())),
        )
        .map(a => a.id)

    const [date, setDate] = useState(getDefaultStart())

    if (categoryOptions === null) {
        getCalendarCategories()
        setCategoryOptions([])
    }

    function getDefaultValue(type, fallback) {
        return props.location?.data ? props.location.data[type] ?? fallback : fallback
    }

    function getDefaultSubfield(field, subfield, fallback) {
        return props.location?.data && props.location.data[field] ? props.location.data[field][subfield] ?? fallback : fallback
    }

    function getDefaultStart() {
        if (props.location.data !== undefined && props.location.data.date !== undefined) {
            if (props.location.data.name) {
                return props.location.data.date
            } else if (!props.location.data.date.isSame(moment(), 'day')) {
                let data = { ad: false }
                let curDate = props.location.data.date
                let hours = 9

                let day = getYMDFromDate(curDate)
                data.sd = day
                data.ed = day
                data.st = hours * 60
                data.et = (hours + 1) * 60

                return data
            }
        }

        let data = { ad: false }
        let curDate = moment()
        let hours = curDate.hours() + 1
        if (hours >= 22) {
            hours = 9
            curDate.add(1, 'days')
        }

        let day = getYMDFromDate(curDate)
        data.sd = day
        data.ed = day
        data.st = hours * 60
        data.et = (hours + 1) * 60

        return data
    }

    function getCalendarCategories() {
        let submission = {
            chapter: user.getChapter(),
        }

        let functionsCall = app.functions().httpsCallable('getCalendarCategories')
        functionsCall(submission)
            .then(function(result) {
                setCategoryOptions(result.data.categories)
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                setError(message)
                console.log('AN ERROR OCCURRED', code, message)
            })
    }

    if (!isNew && !grabbingEvent) {
        getCalendarEvent()
    }

    function compileAlarms(alarms) {
        if (alarms !== undefined) {
            let compiled = []

            if (alarms.eventStart !== undefined && alarms.eventStart !== null) {
                compiled.push({ ...alarms.eventStart, type: 0 })
            }

            if (alarms.excuseDeadline !== undefined && alarms.excuseDeadline !== null) {
                compiled.push({ ...alarms.excuseDeadline, type: 1 })
            }

            if (alarms.rsvpDeadline !== undefined && alarms.rsvpDeadline !== null) {
                compiled.push({ ...alarms.rsvpDeadline, type: 2 })
            }

            if (alarms.signIn !== undefined && alarms.signIn !== null) {
                compiled.push({ ...alarms.signIn, type: 3 })
            }

            return compiled
        } else {
            return []
        }
    }

    function getCalendarEvent() {
        setGrabbingEvent(true)
        const db = app.firestore()
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('events')
            .doc(eventID)
            .get()
            .then(function(doc) {
                setHasGrabbed(true)
                if (doc.exists) {
                    setEventExists(true)

                    let e = doc.data()
                    setEventAlarms(compileAlarms(e.alarms))
                    setEventName(e.name)
                    setPlaceData(e?.googleMapsPlace ?? {label: e?.location, value: e?.location }  ?? { label: e?.address, value: e?.address} ?? null)
                    setEventLocation(e.location)
                    setEventAddress(e.address)
                    setEventDescription(e.description)
                    setCategories(e.categories)
                    setEventCost(e.cost)
                    setLinks(e.links ? e.links : [])
                    setPoints(e.points ? e.points : [])
                    setCheckboxState({
                        usesSignIn: e.signIn.enabled,
                        canRSVP: e.rsvp.enabled,
                        excusesAllowed: e.excuses.enabled,
                        usesItinerary: !!e.itinerary,
                    })
                    setSyncing(e.sync !== undefined && e.sync === false ? false : true)

                    setRSVPDeadline(e.rsvp.deadline ?? 0)
                    setRSVPGroups(e.rsvp.groups && e.rsvp.groups.enabled ? e.rsvp.groups : { enabled: false, groups: [] })

                    if (e.rsvp.waitlist && e.rsvp.waitlist.enabled) {
                        setRSVPWaitlist(e.rsvp.waitlist)
                    }

                    if (e.itinerary && Array.isArray(e.itinerary)) {
                        setItinerary(e.itinerary)
                    }

                    if (e.date.sd !== undefined) {
                        setDate(e.date)
                    } else {
                        let startDate = getFirebaseDate(e)
                        let endDate = moment(startDate)
                            .add(e.length, 'minutes')
                            .toDate()
                        let sd = getYMDFromDate(startDate)
                        let ed = e.length === 0 ? sd : getYMDFromDate(endDate)

                        let startTime = moment(startDate).diff(moment(startDate).startOf('day'), 'minutes')
                        let endTime = moment(endDate).diff(moment(endDate).startOf('day'), 'minutes')

                        let date = {
                            sd: sd,
                            ed: ed,
                            ad: e.length === 0,
                            st: e.length === 0 ? undefined : startTime,
                            et: e.length === 0 ? undefined : endTime,
                        }
                        setDate(date)
                    }

                    setMaxAttendees(e.rsvp.maxAttendees)
                    setRSVPDisplayAttendees(e.rsvp.vis ? e.rsvp.vis : 0)
                    setSignInType(e.signIn.type ? e.signIn.type : 0)
                    setSignInStart(e.signIn.start ? e.signIn.start : 0)
                    setExcuseDeadline(e.excuses.deadline ? e.excuses.deadline : 0)

                    if (e.signIn.password) {
                        setSignInPassword(e.signIn.password)
                    } else {
                        const db = app.firestore()
                        db.collection('chapters')
                            .doc(user.getChapter())
                            .collection('events')
                            .doc(eventID)
                            .collection('data')
                            .doc('meta')
                            .get()
                            .then(function(doc) {
                                if (doc.exists) {
                                    let data = doc.data()
                                    if (data.password) {
                                        setSignInPassword(data.password)
                                    }
                                }
                            })
                            .catch(function(error) {
                                console.log('got an error', error.message)
                            })
                    }

                    if (e.rsvp.guests !== undefined && e.rsvp.guests.fields !== undefined) {
                        let fields = expandRsvpGuestFields(e.rsvp.guests.fields)

                        setRSVPGuests({ enabled: false, min: 0, max: 10, ...e.rsvp.guests, fields: fields })
                    }

                    let vis = e.visibility
                    if (vis) {
                        setVisibility(expandVisibility(vis))
                    }
                } else {
                    setEventExists(false)
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                setEventExists(false)
            })
    }

    function expandRsvpGuestFields(fields) {
        let compiledFields = []

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i]

            if (field === 0) {
                compiledFields.push({ id: 0, name: 'Name' })
            }

            if (field === 1) {
                compiledFields.push({ id: 1, name: 'Phone' })
            }

            if (field === 2) {
                compiledFields.push({ id: 2, name: 'Email' })
            }

            if (field === 3) {
                compiledFields.push({ id: 3, name: 'Birthday' })
            }
        }

        return compiledFields
    }

    const [checkboxState, setCheckboxState] = useState({
        usesSignIn: getDefaultSubfield('signIn', 'enabled', false),
        canRSVP: getDefaultSubfield('rsvp', 'enabled', false),
        excusesAllowed: getDefaultSubfield('excuses', 'enabled', false),
        usesItinerary: !!getDefaultValue('itinerary', false),
    })

    const handleChange = event => {
        setCheckboxState({
            ...checkboxState,
            [event.target.name]: event.target.checked,
        })
    }

    const createEvent = () => {
        let vis = visibility.map(a => a.id)

        let actualEditors = []
        for (let i = 0; i < editors.length; i++) {
            actualEditors.push(editors[i].id)
        }

        let alarms = []
        for (let i = 0; i < eventAlarms.length; i++) {
            let alarm = eventAlarms[i]
            let dupe = false
            for (let j = 0; j < alarms.length; j++) {
                let compAlarm = alarms[j]
                if (alarm.type === compAlarm.type) {
                    // && alarm.format === compAlarm.format && alarm.time === compAlarm.time) {
                    dupe = true
                }
            }

            if (dupe) {
                setError('You cannot have duplicate alarms (must all have different types)')
                return
            }

            alarms.push(alarm)
        }

        //Compile rsvp guests
        let rsvpGuests2 = { ...rsvpGuests }
        rsvpGuests2.fields = rsvpGuests2.fields.map(a => a.id).sort()

        const submission = {
            chapter: user.getChapter(),
            name: eventName,
            location: eventLocation.label, // set legacy value to google maps label
            address: eventAddress.label,// set legacy value to google maps label
            googleMapsPlace: placeData,
            googleMapsLink: `https://www.google.com/maps/place?q=place_id:${placeData?.value?.place_id}`,
            alarms: alarms,
            editors: actualEditors,
            description: eventDescription,
            date: date,
            cost: eventCost,
            categories: categories,
            links: links,
            points: points,
            rsvp: {
                enabled: checkboxState.canRSVP,
                guests: rsvpGuests2.enabled ? rsvpGuests2 : { enabled: false },
                deadline: rsvpDeadline, // null or number of minutes
                waitlist: rsvpWaitlist.enabled
                    ? { enabled: true, max: rsvpWaitlist.max, deadline: rsvpWaitlist.deadline, autoApprove: rsvpWaitlist.autoApprove }
                    : { enabled: false },
                groups: rsvpGroups.enabled ? rsvpGroups : { enabled: false },
            },
            signIn: {
                enabled: checkboxState.usesSignIn,
            },
            excuses: {
                enabled: checkboxState.excusesAllowed && checkboxState.usesSignIn,
                deadline: checkboxState.excusesAllowed && checkboxState.usesSignIn ? excuseDeadline : undefined,
            },
            visibility: vis,
            sync: syncing,
        }

        if (checkboxState.usesItinerary && Array.isArray(itinerary) && itinerary.length > 0) {
            submission.itinerary = itinerary
        }

        if (submission.rsvp.enabled) {
            if (maxAttendees !== undefined && maxAttendees !== null && typeof maxAttendees === 'number' && !isNaN(maxAttendees)) {
                submission.rsvp.maxAttendees = maxAttendees
            } else {
                submission.rsvp.maxAttendees = null
            }

            submission.rsvp.vis = rsvpDisplayAttendees
        }

        let signInPassword2 = checkboxState.usesSignIn && signInType === 1 ? signInPassword : undefined
        let signInType2 = signInType
        if (signInPassword2 === null || (typeof signInPassword === 'string' && signInPassword.length === 0)) {
            signInPassword2 = undefined
            if (signInType2 === 1) {
                signInType2 = 0
            }
        }

        if (signInPassword2 !== undefined) {
            submission.signIn.password = signInPassword2
        }

        submission.signIn.start = signInStart
        submission.signIn.type = signInType2
        if (!isNew) {
            submission.eventID = eventID
        }

        let functionsCall = app.functions().httpsCallable('updateCalendarEvent2')
        functionsCall(submission)
            .then(function(result) {
                requestChapterUpdate()
                setIsLoading(false)

                markSetupStepComplete('calendar')

                if (isNew || props.history.length === 0) {
                    props.history.replace('/app/calendar/event/' + result.data.eventID)
                } else {
                    props.history.goBack()
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                setError(message)
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoading(false)
            })

        setIsLoading(true)
    }

    function getNavBarLinks() {
        if (!isNew) {
            return [
                {
                    name: 'My House',
                    link: '/app/dashboard/',
                    icon: <HomeIcon />,
                },
                {
                    name: 'Calendar',
                    link: '/app/calendar/',
                    icon: atLeastSmall ? null : <CalendarIcon />,
                },
                {
                    name: 'Event',
                    link: '/app/calendar/event/' + eventID,
                },
                { name: 'Edit' },
            ]
        } else {
            return [
                {
                    name: 'My House',
                    link: '/app/dashboard/',
                    icon: <HomeIcon />,
                },
                {
                    name: 'Calendar',
                    link: '/app/calendar/',
                    icon: atLeastSmall ? null : <CalendarIcon />,
                },
                { name: 'New Event' },
            ]
        }
    }

    function getRightBarButtons() {
        if (!isNew && eventExists) {
            return [
                {
                    name: 'Delete',
                    type: 'icon',
                    innerIcon: <DeleteIcon />,
                    onClick: () => {
                        setDeleteDialogOpen(true)
                    },
                },
            ]
        }

        return []
    }

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [errorText, setErrorText] = useState(null)

    function deleteEvent() {
        let submission = {
            chapter: user.getChapter(),
            eventID: eventID,
        }

        let functionsCall = app.functions().httpsCallable('deleteCalendarEvent')
        functionsCall(submission)
            .then(async function(result) {
                await requestChapterUpdateWithDelay()
                props.history.push('/app/calendar/')
            })
            .catch(function(error) {
                var message = error.message
                setIsLoading(false)
                setErrorText(message)
            })
        setIsLoading(true)
    }

    function getSignInTypeHelperText() {
        if (signInType === 0) {
            return 'Members will sign in without verification'
        } else if (signInType === 1) {
            return 'Password must be entered to sign in'
        } else if (signInType === 2) {
            return 'QR Code must be scanned to sign in'
        } else if (signInType === 3) {
            return 'Admins manually sign in members'
        } else if (signInType === 4) {
            return 'The PIN must be entered to sign in'
        }
        return ''
    }

    function getAlarmTimings(type) {
        if (type === 0) {
            return alarmsEventStart
        }
        if (type === 1) {
            return alarmsEventDeadline
        }
        if (type === 2) {
            return alarmsRSVPDeadline
        }
        return alarmsSignInOpen
    }

    function updateRSVPGuests(min, max) {
        if (min !== null) {
            if (min < 0) {
                min = 0
            }

            if (min > 10) {
                min = 10
            }
        } else {
            min = 0
        }

        if (max !== null) {
            if (max < 1) {
                max = 1
            }

            if (max > 10) {
                max = 10
            }
        } else {
            max = 10
        }

        if (min !== null && max !== null) {
            let hasMinChanged = min !== rsvpGuests.min
            if (hasMinChanged && max < min) {
                max = min
            } else if (min > max) {
                min = max
            }
        }

        setRSVPGuests({ ...rsvpGuests, min: min, max: max })
    }

    const getEventDays = () => {
        let days = []

        let curDate = moment(getDateFromYMD(date.sd)).startOf('day')

        let endDate = moment(getDateFromYMD(date.ed)).endOf('day')

        do {
            days.push({
                str: curDate.format('dddd (MMMM Do)'),
                val: curDate.format('YYYY-MM-DD'),
            })
            curDate = moment(curDate).add(1, 'day')
        } while (curDate.isBefore(endDate))

        return days
    }
    const [itinerary, setItinerary] = useState(getDefaultValue('itinerary', []))      
    const renderEditor = () => (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    autoFocus={isNew}
                    margin="dense"
                    id="event-name"
                    label={eventName ? '' : 'Name'}
                    InputLabelProps={{ shrink: false }}
                    type="text"
                    value={eventName}
                    onChange={e => {
                        setEventName(e.target.value)
                    }}
                    inputProps={{
                        maxLength: 64,
                        'aria-labelledby': 'event-name-label',
                    }}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <DateRangePicker date={date} setDate={date => setDate(date)} />
            </Grid>
            {datesError[0] !== null && datesError[0] !== true && (
                <Grid item xs={12}>
                    <ErrorTypography text={datesError[0]} isCentered={true} />
                </Grid>
            )}
            {datesError[1] !== null && datesError[1] !== true && (
                <Grid item xs={12}>
                    <ErrorTypography text={datesError[1]} isCentered={true} />
                </Grid>
            )}
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={date.ad}
                            onChange={e => {
                                setDate({ ...date, ad: e.target.checked })
                            }}
                            color="primary"
                        />
                    }
                    label="All Day"
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Location" icon={<LocationIcon />}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            {process.env.REACT_APP_GOOGLE_PLACES_API_KEY ? (
                                <GooglePlacesAutocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                                    minLengthAutocomplete={3}
                                    debounce={500}
                                    value={placeData}
                                    styles={{
                                        marginTop: 4,
                                        input: {
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            '&:focus': {
                                                borderColor: '#3f51b5',
                                                boxShadow: '0 0 0 0.2rem rgba(63, 81, 181, 0.25)',
                                            },
                                        },
                                    }}
                                    selectProps={{
                                        value: placeData,
                                        onChange: (val) => {
                                            setPlaceData(val)
                                            setEventAddress(val)
                                            setEventLocation(val)
                                        },
                                        styles: {
                                            container: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                            }),
                                            control: (provided, state) => ({
                                                ...provided, 
                                                marginTop: '4px',
                                                borderColor: state.isFocused ? '#1976d2' : '#c4c4c4',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                lineHeight: '1.4375em',
                                                width: '100%',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: 'black'
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                color: 'black',
                                                backgroundColor: 'white',
                                                '&:hover': {
                                                    backgroundColor: '#f5f5f5'
                                                }
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: 'white',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'black'
                                            })
                                        }
                                    }}
                                    apiOptions={{ language: 'en', region: 'us' }}
                                    withSessionToken={true}
                                />
                            ) : (
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label="Location"
                                    type="text"
                                    value={eventLocation?.label || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setPlaceData({ label: value, value: value })
                                        setEventAddress({ label: value, value: value })
                                        setEventLocation({ label: value, value: value })
                                    }}
                                    fullWidth
                                    style={{ marginTop: 4 }}
                                />
                            )}
                        </Grid>
                        {placeData?.value?.place_id && (
                            <Grid item xs={12} md={12}>
                                <Link
                                    href={`https://www.google.com/maps/place?q=place_id:${placeData?.value?.place_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        gap: 1,
                                        textDecoration: 'none'
                                    }}
                                >
                                    View on Google Maps
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Links" icon={<LinkIcon />}>
                    {links.map((link, index) => (
                        <React.Fragment key={'Link_' + index}>
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid container spacing={1} style={{ flexGrow: 1 }}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label={link.name ? '' : 'Name'}
                                            type="text"
                                            onChange={e => {
                                                let curLinks = [...links]
                                                curLinks[index].name = e.target.value
                                                setLinks(curLinks)
                                            }}
                                            value={link.name}
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            style={{ marginTop: 4 }}
                                            InputLabelProps={{ shrink: false }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label={link.url ? '' : 'URL'}
                                            type="url"
                                            onChange={e => {
                                                let curLinks = [...links]
                                                curLinks[index].url = e.target.value
                                                setLinks(curLinks)
                                            }}
                                            value={link.url}
                                            inputProps={{
                                                maxLength: 256,
                                            }}
                                            style={{ marginTop: 4 }}
                                            InputLabelProps={{ shrink: false }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Box
                                    style={{
                                        margin: 4,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                let curLinks = [...links]
                                                curLinks.splice(index, 1)
                                                setLinks(curLinks)
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            {!atLeastSmall && index < links.length - 1 ? (
                                <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                            ) : (
                                <Divider style={{ backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }} />
                            )}
                        </React.Fragment>
                    ))}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ButtonCore
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    let newLinks = [...links]
                                    newLinks.push({ name: '', url: '' })
                                    setLinks(newLinks)
                                }}
                                size="large"
                                disabled={links.length >= 10}
                                style={{ marginTop: 4, marginBottom: 4 }}
                            >
                                Add Link
                            </ButtonCore>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Notifications" icon={<NotificationsAlarm />}>
                    {eventAlarms.map((alarm, index) => (
                        <React.Fragment key={'Alarm_' + index}>
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid container spacing={1} style={{ flexGrow: 1 }}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                            <Select
                                                native
                                                aria-label="Type"
                                                value={alarm.type ?? ''}
                                                onChange={event => {
                                                    let curAlarms = [...eventAlarms]
                                                    curAlarms[index] = { ...curAlarms[index], type: parseInt(event.target.value) }
                                                    setEventAlarms(curAlarms)
                                                }}
                                            >
                                                <option value={0}>Event Start</option>
                                                <option value={1}>Excuse Deadline</option>
                                                {/*<option value={2}>RSVP Deadline</option>*/}
                                                <option value={3}>Sign In Open</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                            <Select
                                                native
                                                aria-label="Format"
                                                value={alarm.format ?? ''}
                                                onChange={event => {
                                                    let curAlarms = [...eventAlarms]
                                                    curAlarms[index] = { ...curAlarms[index], format: parseInt(event.target.value) }
                                                    setEventAlarms(curAlarms)
                                                }}
                                            >
                                                <option value={0}>send notification</option>
                                                {/*<MenuItem value={1}>trigger iCal alarm</MenuItem>*/}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                            <Select
                                                native
                                                aria-label="Time"
                                                value={alarm.time ?? ''}
                                                onChange={event => {
                                                    let curAlarms = [...eventAlarms]
                                                    curAlarms[index] = { ...curAlarms[index], time: parseInt(event.target.value) }
                                                    setEventAlarms(curAlarms)
                                                }}
                                            >
                                                {getAlarmTimings(alarm.type).map(time => (
                                                    <option value={time.value} key={time.value}>
                                                        {time.text}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Box
                                    style={{
                                        margin: 4,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                let curAlarms = [...eventAlarms]
                                                curAlarms.splice(index, 1)
                                                setEventAlarms(curAlarms)
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            {!atLeastSmall && index < eventAlarms.length - 1 ? (
                                <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                            ) : (
                                <Divider style={{ backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }} />
                            )}
                        </React.Fragment>
                    ))}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ButtonCore
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    let newAlarms = [...eventAlarms]
                                    newAlarms.push({ type: 0, format: 0, time: 0 })
                                    setEventAlarms(newAlarms)
                                }}
                                size="large"
                                disabled={eventAlarms.length >= 4}
                                style={{ marginTop: 4, marginBottom: 4 }}
                            >
                                Add Notification
                            </ButtonCore>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label="Itinerary"
                    icon={<ItineraryIcon />}
                    showHeader
                    control={<Switch color="primary" checked={checkboxState.usesItinerary} name="usesItinerary" onChange={handleChange} size="small" />}
                    enabled={checkboxState.usesItinerary}
                >
                    <Grid container spacing={1} style={{ marginTop: 4 }}>
                        {itinerary.map((it, index) => (
                            <Grid item xs={12} key={it.id}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: atLeastLarge ? 'row' : 'column',
                                        gap: atLeastLarge ? 8 : 0,
                                    }}
                                >
                                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                        {getEventDays().length > 1 && (
                                            <Box>
                                                <Select
                                                    native
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={it.time.sd ?? ''}
                                                    onChange={e => {
                                                        setItinerary(itinerary => {
                                                            let it = [...itinerary]
                                                            it[index].time.sd = e.target.value
                                                            return it
                                                        })
                                                    }}
                                                    style={{ marginTop: 4, marginBottom: 4 }}
                                                >
                                                    {getEventDays().map(day => (
                                                        <option value={day.val} key={day.val}>
                                                            {day.str}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </Box>
                                        )}
                                        <Box style={{ marginLeft: 4 }}>
                                            <Select
                                                native
                                                variant="outlined"
                                                margin="dense"
                                                value={it.time.st ?? ''}
                                                onChange={e => {
                                                    setItinerary(itinerary => {
                                                        let it = [...itinerary]
                                                        it[index].time.st = e.target.value
                                                        return it
                                                    })
                                                }}
                                                style={{ marginTop: 4, marginBottom: 4 }}
                                            >
                                                {times.map(time => (
                                                    <option value={time.val} key={time.val}>
                                                        {time.str}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Box>
                                    {it.type === 'double' && (
                                        <>
                                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography variant="body1"> to </Typography>
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box style={{ marginRight: 4 }}>
                                                    <Select
                                                        native
                                                        variant="outlined"
                                                        margin="dense"
                                                        value={it.time.et ?? ''}
                                                        onChange={e => {
                                                            setItinerary(itinerary => {
                                                                let it = [...itinerary]
                                                                it[index].time.et = e.target.value
                                                                return it
                                                            })
                                                        }}
                                                        style={{ marginTop: 4, marginBottom: 4 }}
                                                    >
                                                        {times.map(time => (
                                                            <option value={time.val} key={time.val}>
                                                                {time.str}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </Box>
                                                {getEventDays().length > 1 && (
                                                    <Box>
                                                        <Select
                                                            native
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={it.time.ed ?? ''}
                                                            onChange={e => {
                                                                setItinerary(itinerary => {
                                                                    let it = [...itinerary]
                                                                    it[index].time.ed = e.target.value
                                                                    return it
                                                                })
                                                            }}
                                                            style={{ marginTop: 4, marginBottom: 4 }}
                                                        >
                                                            {getEventDays().map(day => (
                                                                <option value={day.val} key={day.val}>
                                                                    {day.str}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                )}
                                            </Box>
                                        </>
                                    )}
                                    <Box
                                        style={
                                            atLeastLarge
                                                ? { flexGrow: 1, display: 'flex', flexDirection: 'row' }
                                                : { width: '100%', display: 'flex', flexDirection: 'row' }
                                        }
                                    >
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label={it.label ? '' : 'Label'}
                                            type="text"
                                            onChange={e => {
                                                setItinerary(itinerary => {
                                                    let it = [...itinerary]
                                                    it[index].label = e.target.value
                                                    return it
                                                })
                                            }}
                                            value={it.label}
                                            inputProps={{
                                                maxLength: 32,
                                            }}
                                            style={{ marginTop: 4 }}
                                            InputLabelProps={{ shrink: false }}
                                            fullWidth
                                        />
                                        <Box
                                            style={{
                                                margin: 4,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                justifyContent: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Box>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setItinerary(itinerary => {
                                                            let it = [...itinerary]
                                                            it.splice(index, 1)
                                                            return it
                                                        })
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 16 }}>
                                <ButtonCore
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={() => {
                                        setItinerary(it => {
                                            let itinerary = [...it]
                                            itinerary.sort((a, b) => {
                                                let aVal = typeof a.time.sd === 'string' ? a.time.sd : a.time.sd.val
                                                let bVal = typeof b.time.sd === 'string' ? b.time.sd : b.time.sd.val

                                                if (aVal !== bVal) {
                                                    return aVal.localeCompare(bVal)
                                                }
                                                return a.time.st - b.time.st
                                            })

                                            return itinerary
                                        })
                                    }}
                                    disabled={itinerary.length === 0}
                                    style={{ marginTop: 8 }}
                                    startIcon={<SortIcon />}
                                >
                                    Sort
                                </ButtonCore>
                                <ButtonCore
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={() => {
                                        setItinerary(it => {
                                            let itinerary = [...it]

                                            if (it.length > 0) {
                                                itinerary.push({
                                                    id: uuidv4(),
                                                    type: 'single',
                                                    time: {
                                                        sd: it[it.length - 1].time.sd,
                                                        st: it[it.length - 1].time.st < 1425 ? it[it.length - 1].time.st + 15 : it[it.length - 1].time.st,
                                                    },
                                                    label: '',
                                                })
                                            } else {
                                                itinerary.push({
                                                    id: uuidv4(),
                                                    type: 'single',
                                                    time: {
                                                        sd: getEventDays()[0],
                                                        st: 540,
                                                    },
                                                    label: '',
                                                })
                                            }

                                            return itinerary
                                        })
                                    }}
                                    disabled={itinerary.length >= 20}
                                    style={{ marginTop: 8 }}
                                >
                                    Add
                                </ButtonCore>
                                <ButtonCore
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={() => {
                                        setItinerary(it => {
                                            let itinerary = [...it]

                                            if (it.length > 0) {
                                                itinerary.push({
                                                    id: uuidv4(),
                                                    type: 'double',
                                                    time: {
                                                        sd: it[it.length - 1].time.sd,
                                                        st: it[it.length - 1].time.st < 1410 ? it[it.length - 1].time.st + 15 : it[it.length - 1].time.st - 15,

                                                        ed: it[it.length - 1].time.sd,
                                                        et: it[it.length - 1].time.st < 1410 ? it[it.length - 1].time.st + 30 : it[it.length - 1].time.st,
                                                    },
                                                    label: '',
                                                })
                                            } else {
                                                itinerary.push({
                                                    id: uuidv4(),
                                                    type: 'double',
                                                    time: {
                                                        sd: getEventDays()[0],
                                                        st: 540,
                                                        ed: getEventDays()[0],
                                                        et: 555,
                                                    },
                                                    label: '',
                                                })
                                            }

                                            return itinerary
                                        })
                                    }}
                                    disabled={itinerary.length >= 20}
                                    style={{ marginTop: 8 }}
                                >
                                    Add Timed
                                </ButtonCore>
                            </Box>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Visibility" icon={<VisibilityIcon />} showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectAutocomplete
                                inputLabelProps={{ shrink: false }}
                                style={{ marginTop: 4 }}
                                variant="outlined"
                                value={visibility}
                                onUpdate={(event, value) => {
                                    setVisibility(value)
                                }}
                                hideTitleOnUse={true}
                                title={'Visibility'}
                                freeSolo={false}
                                aria-label="Visibility"
                                events={visibilityOptions(true)}
                                disabled={!isNew}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Description" icon={<DescriptionIcon />} showHeader={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                type="text"
                                onChange={e => {
                                    setEventDescription(e.target.value)
                                }}
                                value={eventDescription}
                                InputProps={{
                                    multiline: true,
                                    rows: 4,
                                }}
                                style={{ marginTop: 4 }}
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                    maxLength: 1024,
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            {false && (
                <Grid item xs={12}>
                    <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                </Grid>
            )}
            {false && (
                <Grid item xs={12}>
                    <FormSection label="Drive" icon={<DriveIcon />} showHeader={true}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    type="text"
                                    onChange={e => {
                                        setEventDescription(e.target.value)
                                    }}
                                    value={eventDescription}
                                    InputProps={{
                                        multiline: true,
                                        rows: 4,
                                    }}
                                    style={{ marginTop: 4 }}
                                    InputLabelProps={{ shrink: false }}
                                    inputProps={{
                                        maxLength: 1024,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Cost" icon={<CostIcon />}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CurrencyTextField
                                variant="outlined"
                                margin="dense"
                                value={eventCost}
                                currencySymbol=""
                                minimumValue="0"
                                maximumValue="10001"
                                outputFormat="number"
                                decimalCharacter="."
                                digitGroupSeparator=","
                                onChange={(event, value) => setEventCost(value)}
                                style={{ marginTop: 4 }}
                                inputProps={{
                                    style: { textAlign: `left` },
                                }}
                                InputProps={{
                                    startAdornment: null,
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label="RSVP"
                    control={<Switch color="primary" checked={checkboxState.canRSVP} name="canRSVP" onChange={handleChange} size="small" />}
                    enabled={checkboxState.canRSVP}
                    icon={<RSVPIcon />}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                label="Max Members"
                                type="number"
                                value={String(maxAttendees)}
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={e => setMaxAttendees(parseInt(e.target.value))}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                                <InputLabel id="display-attendees-label">Display Attendees</InputLabel>
                                <Select
                                    native
                                    labelId="display-attendees-label"
                                    label="Display Attendees"
                                    value={String(rsvpDisplayAttendees)}
                                    onChange={e => {
                                        setRSVPDisplayAttendees(parseInt(e.target.value))
                                    }}
                                >
                                    <option value={0}>Only to admins</option>
                                    <option value={1}>To rsvp'd members</option>
                                    <option value={2}>To everyone</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                                <InputLabel id="rsvp-deadline-label">Deadline</InputLabel>
                                <Select
                                    native
                                    labelId="rsvp-deadline-label"
                                    label="Deadline"
                                    value={rsvpDeadline ?? ''}
                                    onChange={event => setRSVPDeadline(event.target.value ? parseInt(event.target.value) : 0)}
                                >
                                    {excuseDeadlines.map(length => (
                                        <option value={length.value} key={length.value}>
                                            {length.text}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection
                                label="Waitlist"
                                control={
                                    <Switch
                                        color="primary"
                                        checked={rsvpWaitlist.enabled}
                                        onChange={() => {
                                            setRSVPWaitlist({ ...rsvpWaitlist, enabled: !rsvpWaitlist.enabled })
                                        }}
                                        size="small"
                                    />
                                }
                                enabled={rsvpWaitlist.enabled}
                                icon={<RSVPWaitlistIcon />}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label="Max Waitlist"
                                            type="number"
                                            value={String(rsvpWaitlist.max)}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            onChange={e => setRSVPWaitlist({ ...rsvpWaitlist, max: parseInt(e.target.value) })}
                                            fullWidth
                                        />
                                    </Grid>
                                    {/*<Grid item xs={12} sm={6} md={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={rsvpWaitlist.autoApprove}
                                                    onChange={() => setRSVPWaitlist({ ...rsvpWaitlist, autoApprove: !rsvpWaitlist.autoApprove })}
                                                    color="primary"
                                                    margin="dense"
                                                />
                                            }
                                            label="Auto Award Spots"
                                            style={{ marginTop: 3 }}
                                        />
                                    </Grid>*/}
                                    {!rsvpWaitlist.autoApprove && (
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth variant="outlined" margin="dense">
                                                <InputLabel id="rsvp-waitlist-deadline-label">Deadline to accept</InputLabel>
                                                <Select
                                                    native
                                                    labelId="rsvp-waitlist-deadline-label"
                                                    label="Deadline to accept"
                                                    value={rsvpWaitlist.deadline ?? ''}
                                                    onChange={event =>
                                                        setRSVPWaitlist({ ...rsvpWaitlist, deadline: event.target.value ? parseInt(event.target.value) : 0 })
                                                    }
                                                >
                                                    {rsvpWaitlistDeadline.map(length => (
                                                        <option value={length.value} key={length.value}>
                                                            {length.text}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection
                                label="Groups"
                                control={
                                    <Switch
                                        color="primary"
                                        checked={rsvpGroups.enabled}
                                        onChange={() => {
                                            setRSVPGroups({ ...rsvpGroups, enabled: !rsvpGroups.enabled })
                                        }}
                                        size="small"
                                    />
                                }
                                enabled={rsvpGroups.enabled}
                                icon={<RSVPGroupsIcon />}
                            >
                                <Grid container spacing={1}>
                                    {rsvpGroups.groups.map((group, index) => (
                                        <Grid item xs={12} key={group.id}>
                                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            label={group.name ? '' : 'Group Name'}
                                                            type="text"
                                                            onChange={e => {
                                                                let curGroups = [...rsvpGroups.groups]
                                                                curGroups[index].name = e.target.value
                                                                setRSVPGroups({ ...rsvpGroups, groups: curGroups })
                                                            }}
                                                            value={group.name ?? ''}
                                                            inputProps={{
                                                                maxLength: 32,
                                                            }}
                                                            InputLabelProps={{ shrink: false }}
                                                            fullWidth
                                                            style={{ marginTop: 4 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            label="Number of Spots"
                                                            type="number"
                                                            value={String(group.numSpots) ?? ''}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                            onChange={e => {
                                                                let curGroups = [...rsvpGroups.groups]
                                                                curGroups[index].numSpots = parseInt(e.target.value)
                                                                setRSVPGroups({ ...rsvpGroups, groups: curGroups })
                                                            }}
                                                            fullWidth
                                                            style={{ marginTop: 4 }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Box
                                                    style={{
                                                        margin: 4,
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        justifyContent: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Box>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                let curGroups = [...rsvpGroups.groups]
                                                                curGroups.splice(index, 1)
                                                                setRSVPGroups({ ...rsvpGroups, groups: curGroups })
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {!atLeastSmall && index < rsvpGroups.groups.length - 1 ? (
                                                <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                                            ) : (
                                                <Divider style={{ backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }} />
                                            )}
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <ButtonCore
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            onClick={() => {
                                                let curGroups = [...rsvpGroups.groups]
                                                curGroups.push({
                                                    id: uuidv4(),
                                                    label: '',
                                                    numSpots: 10,
                                                })
                                                setRSVPGroups({ ...rsvpGroups, groups: curGroups })
                                            }}
                                            size="large"
                                            disabled={rsvpGroups.groups.length >= 10}
                                            style={{ marginTop: 4, marginBottom: 4 }}
                                        >
                                            Add Group
                                        </ButtonCore>
                                    </Grid>
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection
                                label="Guests"
                                control={
                                    <Switch
                                        color="primary"
                                        checked={rsvpGuests.enabled}
                                        onChange={() => {
                                            setRSVPGuests({ ...rsvpGuests, enabled: !rsvpGuests.enabled })
                                        }}
                                        size="small"
                                    />
                                }
                                enabled={rsvpGuests.enabled}
                                icon={<AttendanceIcon />}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label="Min"
                                            placeholder="# per member"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 0, max: rsvpGuests.max !== null ? rsvpGuests.max : 10 },
                                            }}
                                            value={rsvpGuests.min !== null ? String(rsvpGuests.min) : ''}
                                            onChange={e => updateRSVPGuests(parseInt(e.target.value), rsvpGuests.max)}
                                            fullWidth
                                            onBlur={() => {
                                                if (!rsvpGuests.min && rsvpGuests.min !== 0) {
                                                    updateRSVPGuests(0, rsvpGuests.max)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label="Max"
                                            placeholder="# per member"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: rsvpGuests.min !== null ? rsvpGuests.min : 0, max: 10 },
                                            }}
                                            value={rsvpGuests.max !== null ? String(rsvpGuests.max) : ''}
                                            onChange={e => updateRSVPGuests(rsvpGuests.min, parseInt(e.target.value))}
                                            fullWidth
                                            onBlur={() => {
                                                if (!rsvpGuests.max) {
                                                    updateRSVPGuests(rsvpGuests.min, 10)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectAutocomplete
                                            variant="outlined"
                                            value={rsvpGuests.fields}
                                            onUpdate={(event, value) => {
                                                setRSVPGuests({ ...rsvpGuests, fields: value })
                                            }}
                                            freeSolo={false}
                                            title="Fields"
                                            events={guestFieldOptions}
                                        />
                                    </Grid>
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label="Attendance"
                    control={<Switch color="primary" checked={checkboxState.usesSignIn} name="usesSignIn" onChange={handleChange} size="small" />}
                    enabled={checkboxState.usesSignIn}
                    icon={<AttendanceIcon />}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} lg={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
                            {checkboxState.usesSignIn && (
                                <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel id="sign-in-type-label">Type</InputLabel>
                                    <Select
                                        native
                                        labelId="sign-in-type-label"
                                        label="Type"
                                        value={signInType ?? ''}
                                        onChange={event => setSignInType(parseInt(event.target.value))}
                                    >
                                        <option value={0}>No Password</option>
                                        <option value={1}>Password</option>
                                        <option value={2}>Scan QR Code</option>
                                        <option value={3}>Admin</option>
                                        <option value={4}>PIN</option>
                                    </Select>
                                    <FormHelperText>{getSignInTypeHelperText()}</FormHelperText>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
                            {checkboxState.usesSignIn && (
                                <FormControl fullWidth variant="outlined" margin="dense">
                                    <InputLabel id="sign-in-start-label">Open Sign In</InputLabel>
                                    <Select
                                        native
                                        labelId="sign-in-start-label"
                                        label="Open Sign In"
                                        value={signInStart ?? ''}
                                        onChange={event => setSignInStart(parseInt(event.target.value))}
                                    >
                                        <option value={30}>30 minutes before</option>
                                        <option value={15}>15 minutes before</option>
                                        <option value={10}>10 minutes before</option>
                                        <option value={5}>5 minutes before</option>
                                        <option value={0}>at start</option>
                                        <option value={-5}>5 minutes after</option>
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        {checkboxState.usesSignIn && signInType === 1 && (
                            <Grid item xs={12} sm={6} lg={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <TextField
                                    variant="outlined"
                                    id="signInPassword"
                                    margin="dense"
                                    label="Password"
                                    type="text"
                                    value={signInPassword}
                                    onChange={event => setSignInPassword(event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormSection>
            </Grid>

            {checkboxState.usesSignIn && (
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={checkboxState.excusesAllowed} onChange={handleChange} name="excusesAllowed" color="primary" margin="dense" />
                        }
                        label="Excuses Allowed"
                        style={{ marginTop: 3 }}
                    />
                </Grid>
            )}
            {checkboxState.usesSignIn && (
                <Grid item xs={12} sm={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    {checkboxState.excusesAllowed && (
                        <FormControl fullWidth variant="outlined" margin="dense">
                            <InputLabel id="deadline-label">Deadline</InputLabel>
                            <Select
                                native
                                labelId="deadline-label"
                                label="Deadline"
                                value={excuseDeadline ?? ''}
                                onChange={event => setExcuseDeadline(event.target.value ? parseInt(event.target.value) : 0)}
                            >
                                {excuseDeadlines.map(length => (
                                    <option value={length.value} key={length.value}>
                                        {length.text}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>
            )}
            {hasPointsCreate &&
                pointOptions.length > 0 &&
                (points.length > 0 || !chapter || !chapter.enabledFeatures || chapter.enabledFeatures.points !== false) && (
                    <>
                        <Grid item xs={12}>
                            <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection label="Points" icon={<PointsIcon />} showHeader>
                                {points.map((point, index) => (
                                    <React.Fragment key={'Point_' + index}>
                                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid container spacing={1} style={{ flexGrow: 1 }}>
                                                <Grid item xs={12} md={6} sm={12}>
                                                    <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                                        <InputLabel id={`Point_Action_${index}`}>Action</InputLabel>
                                                        <Select
                                                            native
                                                            labelId={`Point_Action_${index}`}
                                                            label="Action"
                                                            value={point.action ?? ''}
                                                            style={{ marginTop: 4 }}
                                                            onChange={e => {
                                                                let curPoints = [...points]
                                                                curPoints[index].action = parseInt(e.target.value)
                                                                setPoints(curPoints)
                                                            }}
                                                            disabled={!hasPointsAdmin && !filteredPointOptions.includes(point.system)}
                                                        >
                                                            <option value={0}>Present</option>
                                                            <option value={1}>Absent</option>
                                                            <option value={2}>Absent Without Approved Excuse</option>
                                                            <option value={3}>Absent With Approved Excuse</option>
                                                            <option value={4}>Approved Excuse</option>
                                                            <option value={5}>RSVP'd</option>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} sm={8}>
                                                    <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }}>
                                                        <InputLabel id={`Point_System_${index}`}>System</InputLabel>
                                                        <Select
                                                            native
                                                            labelId={`Point_System_${index}`}
                                                            label="System"
                                                            value={point.system ?? ''}
                                                            style={{ marginTop: 4 }}
                                                            onChange={e => {
                                                                let curPoints = [...points]
                                                                curPoints[index].system = e.target.value
                                                                setPoints(curPoints)
                                                            }}
                                                            disabled={!hasPointsAdmin && !filteredPointOptions.includes(point.system)}
                                                        >
                                                            {pointOptions
                                                                .filter(option => point.system === option.id || filteredPointOptions.includes(option.id))
                                                                .map(option => (
                                                                    <option value={option.id} key={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2} sm={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        label={'Points'}
                                                        type="number"
                                                        onChange={e => {
                                                            if (e.target.validity.valid || e.target.value === '') {
                                                                let curPoints = [...points]
                                                                const result = parseInt(e.target.value)
                                                                curPoints[index].points = isNaN(result) ? 0 : result
                                                                setPoints(curPoints)
                                                            }
                                                        }}
                                                        value={point.points}
                                                        inputProps={{
                                                            maxLength: 6,
                                                            pattern: '-?[0-9]*',
                                                        }}
                                                        disabled={!hasPointsAdmin && !filteredPointOptions.includes(point.system)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box
                                                style={{
                                                    margin: 4,
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Box>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            let curPoints = [...points]
                                                            curPoints.splice(index, 1)
                                                            setPoints(curPoints)
                                                        }}
                                                        disabled={!hasPointsAdmin && !filteredPointOptions.includes(point.system)}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {!atLeastSmall && index < links.length - 1 ? (
                                            <Divider style={{ marginRight: 38, marginTop: 8, marginBottom: 8 }} />
                                        ) : (
                                            <Divider style={{ backgroundColor: 'transparent', marginTop: 2, marginBottom: 2 }} />
                                        )}
                                    </React.Fragment>
                                ))}

                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <ButtonCore
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            onClick={() => {
                                                let curPoints = [...points]
                                                curPoints.push({
                                                    id: uuidv4(),
                                                    action: 0,
                                                    system: filteredPointOptions[0],
                                                    points: 10,
                                                })
                                                setPoints(curPoints)
                                            }}
                                            size="large"
                                            disabled={points.length >= 6 || filteredPointOptions.length === 0}
                                            style={{ marginTop: 4, marginBottom: 4 }}
                                        >
                                            Add Section
                                        </ButtonCore>
                                    </Grid>
                                </Grid>
                            </FormSection>
                        </Grid>
                    </>
                )}
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    label={atLeastSmall ? 'External Calendar Syncing' : 'Ext. Cal. Syncing'}
                    labelTooltip="When turned on, members that can view this event & have the app synced with their calendar will see this event on it. Recommended to be turned off when the event doesn't apply to everyone who can see it."
                    showHeader={true}
                    control={
                        <Switch
                            color="primary"
                            checked={syncing}
                            onChange={() => {
                                setSyncing(!syncing)
                            }}
                            size="small"
                        />
                    }
                    enabled={syncing}
                    icon={<SyncIcon />}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <FormSection label="Categories" icon={<CategoryIcon />}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectAutocomplete
                                variant="outlined"
                                inputLabelProps={{ shrink: false }}
                                style={{ marginTop: 4 }}
                                value={categories}
                                onUpdate={(event, value) => {
                                    setCategories(value)
                                }}
                                hideTitleOnUse={true}
                                freeSolo={false}
                                title="Categories"
                                events={categoryOptions}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            {error && (
                <Grid item xs={12}>
                    <ErrorTypography text={error} isCentered={true} />
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{ marginRight: 4 }}
                            onClick={() => {
                                if (!isNew) {
                                    props.history.push('/app/calendar/event/' + eventID)
                                } else {
                                    props.history.push('/app/calendar/')
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {isLoading ? (
                            <Box style={{ paddingLeft: 25 }}>
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Button variant={'contained'} color={'primary'} onClick={createEvent} style={{ marginLeft: 4 }} loading={isLoading}>
                                {isNew ? 'Create' : 'Update'}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>            
            <Grid container spacing={2}>
                <NavigationBar titles={getNavBarLinks()} rightButtons={getRightBarButtons()} key={!isNew && eventExists ? 'old' : 'new'} grid />
                {!eventExists && hasGrabbed ? (
                    <Grid item xs={12}>
                        <Widget
                            disableWidgetMenu
                            noBodyPadding
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                style={{
                                    marginLeft: 24,
                                    marginRight: 24,
                                    marginTop: 8,
                                    marginBottom: 6,
                                }}
                            >
                                <ErrorTypography text={'This event appears to have been deleted'} isCentered={true} />
                            </Box>
                        </Widget>
                    </Grid>
                ) : (
                    hasGrabbed && (
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                renderEditor()
                            ) : (
                                <Widget disableWidgetMenu inheritHeight noBodyPadding bodyClass={classes.widgetBody}>
                                    {renderEditor()}
                                </Widget>
                            )}
                        </Grid>
                    )
                )}
            </Grid>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => {
                    if (!isLoading) {
                        setDeleteDialogOpen(false)
                    }
                }}
            >
                <DialogTitle>Delete event?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this event?</DialogContentText>
                    {isLoading ? (
                        <>
                            <CircularProgress
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    marginTop: 12,
                                }}
                            />
                        </>
                    ) : (
                        errorText && (
                            <DialogContentText
                                style={{
                                    color: '#E66868',
                                    textAlign: `center`,
                                    marginTop: 12,
                                    marginBottom: 0,
                                }}
                            >
                                {errorText}
                            </DialogContentText>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary" disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteEvent()} disabled={isLoading} color="red">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    )
}

NewEvent.propTypes = {
    history: PropTypes.shape({
        length: PropTypes.number,
        action: PropTypes.string,
        location: PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
            key: PropTypes.string
        }),
        push: PropTypes.func,
        replace: PropTypes.func,
        goBack: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        key: PropTypes.string
    }).isRequired,
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        isExact: PropTypes.bool,
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }).isRequired
}