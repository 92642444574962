import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import ViewPoll from 'pages/applications/polls/view'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Network } from '@capacitor/network'

// components
import Layout from './Layout'
// import Documentation from './Documentation/Documentation'

// pages
import Error from '../pages/error'
import Login from '../pages/login'
import Setup from '../pages/setup'

import MessagesMobile from '../pages/messages/mobile'

import AppUrlListener from './AppUrlListener'

// context
import { AuthContext } from '../context/AuthContext'

import { isMobileDevice } from 'code/Helper'
import { PlanManagement } from './PlanManagement'
import { usePageTracking } from 'services/mixpanel/analytics'

export default function App(props) {
    // global
    usePageTracking();
    const { user, currentUser } = React.useContext(AuthContext)

    const [connected, setConnected] = React.useState(true)
    const [showConnectionUpdate, setShowConnectionUpdate] = React.useState(false)

    const getCurrentStatus = async () => {
        let status = await Network.getStatus()

        let _connected = status.connected

        if (_connected !== connected) {
            setConnected(_connected)
            setShowConnectionUpdate(true)
        }
    }

    React.useEffect(() => {
        getCurrentStatus()

        Network.addListener('networkStatusChange', status => {
            let _connected = status.connected

            if (_connected !== connected) {
                setConnected(_connected)
                setShowConnectionUpdate(true)
            }
        })

        return () => {
            Network.removeAllListeners()
        }
    }, [connected, getCurrentStatus])
    return (
        <>
                <PlanManagement />
                <AppUrlListener />
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
                    <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
                    {/*<Route path="/documentation" component={Documentation} />*/}
                    {/*<Route exact path="/app/applications/courses" component={Courses} />*/}
                    <Route exact path="/app/applications/polls/view/:chapterID/:pollID" component={ViewPoll} />
                    {false && currentUser && isMobileDevice() && <Route exact path="/app/messages/:conversationId" component={MessagesMobile} />}
                    <Route exact path="/join/:code" render={props => <Redirect to={`/login?code=${props.match.params.code}&register=true`} />} />
                    <Route exact path="/join" render={props => <Redirect to={`/login?register=true`} />} />
                    <Route exact path="/register" render={props => <Redirect to={`/login?register=true`} />} />
                    <PublicRoute path="/login" component={Login} />
                    <PublicRoute path="/setup" component={Setup} />
                    <PrivateRoute path="/update" component={Login} />
                    <PrivateRoute path="/app" component={Layout} />
                    <Route component={Error} />
                </Switch>
            <Snackbar
                open={showConnectionUpdate}
                autoHideDuration={6000}
                onClose={() => setShowConnectionUpdate(false)}
                anchorOrigin={{ vertical: isMobileDevice() ? 'top' : 'bottom', horizontal: isMobileDevice() ? 'center' : 'left' }}
            >
                <MuiAlert onClose={() => setShowConnectionUpdate(false)} severity={connected ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {connected ? 'Your internet connection has been restored' : 'Your internet connection appears to be offline'}
                </MuiAlert>
            </Snackbar>
        </>
    )

    function PrivateRoute({ component, ...rest }) {
        if (!user || (!user.hasRegistered() && rest && rest.location && rest.location.pathname)) {
            sessionStorage.setItem('redirect', rest.location.pathname)
        }

        return (
            <Route
                {...rest}
                render={props =>
                    user ? (
                        !user.hasRegistered() ? (
                            props.history.location.pathname !== '/login' && <Redirect to={'/login'} />
                        ) : user.needsDetailsUpdate() && props.history.location.pathname !== '/update' ? (
                            <Redirect to={'/update'} />
                        ) : (
                            React.createElement(component, props)
                        )
                    ) : (
                        <Redirect to={'/login'} />
                    )
                }
            />
        )
    }

    function PublicRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    user && user.needsDetailsUpdate() ? (
                        <Redirect
                            to={{
                                pathname: '/update',
                            }}
                        />
                    ) : user && user.hasRegistered() ? (
                        <Redirect
                            to={{
                                pathname: '/',
                            }}
                        />
                    ) : (
                        React.createElement(component, props)
                    )
                }
            />
        )
    }
}
