import React, { useContext, useState, useEffect } from 'react'

import 'firebase/functions'
import app from 'firebase/app'
import { Box, CircularProgress, Divider, Grid, TextField } from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'
import {
    HomeOutlined as HomeIcon,
    WhatshotOutlined as RushIcon,
    SettingsOutlined as SettingsIcon,
} from '@material-ui/icons'

import NavigationBar from 'components/NavigationBar'
import Paper from 'components/Paper'
import { Button } from 'components/Wrappers'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import { isMobileDevice } from 'code/Helper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGetRushes } from 'hooks/queries/rush/useGetRushes'

export default function EditRush(props) {
    const { requestChapterUpdate } = useContext(ChapterContext)
    const { user } = useContext(AuthContext)

    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { data: rush, isLoading: isLoadingRush, isError: isErrorRush } = useGetRushes({
        chapterId: user.getChapter(),
        isActive: true
    })
    
    useEffect(() => {
        setName(rush?.name || '')
        setStartDate(rush?.startDate ? new app.firestore.Timestamp(
            rush?.startDate?.seconds,
            rush?.startDate?.nanoseconds
        ).toDate() : null)
        setEndDate(rush?.endDate ? new app.firestore.Timestamp(
            rush?.endDate?.seconds,
            rush?.endDate?.nanoseconds
        ).toDate() : null)
    }, [rush])


    function handleSubmit() {
        if (!startDate || !endDate) {
            setErrorMessage('Please select both start and end dates')
            return
        }
        if(startDate > endDate) {
            setErrorMessage('Start date cannot be after end date')
            return
        }
        if(startDate === endDate) {
            setErrorMessage('Start date and end date cannot be the same')
            return
        }
        if(startDate === null || endDate === null) {
            setErrorMessage('Please select both start and end dates')
            return
        }
        if(name.length > 32) {
            setErrorMessage('Name cannot be longer than 32 characters')
            return
        }
        if(name.length < 1) {
            setErrorMessage('Name cannot be empty')
            return
        }

        let rushUpdate = {
            chapterID: user.getChapter(),
            name: name,
            rushID: rush.id,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        }
        
        setIsLoading(true)
        
        const updateActiveRush = app.functions().httpsCallable('updateActiveRush')
        updateActiveRush(rushUpdate)
            .then(async function(result) {
                await requestChapterUpdate()
                setIsLoading(false)
                props.history.replace('/app/applications/rush/settings/')
            })
            .catch(function(error) {
                setErrorMessage(error.message || 'An error occurred while updating the rush')
                setIsLoading(false)
            })
    }

    const renderEditor = () => (    
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    label={'Name'}
                    type="text"
                    inputProps={{
                        maxLength: 32,
                    }}
                    style={{ marginTop: 0 }}
                    onChange={e => setName(e.target.value)}
                    value={name}
                    required
                    fullWidth
                    placeholder="ex: Fall 2024"
                />
            </Grid>
            
            <Grid item xs={12}>
            <MuiAlert severity="info">
                Beta Feature: Set a date range to track Rush event attendance in rushee profiles.
            </MuiAlert>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>

            <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="Start Date"
                        value={startDate || null}
                        onChange={(newValue) => {
                            setStartDate(newValue)
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: "outlined",
                                margin: "dense",
                                required: true
                            }
                        }}
                    />
            </Grid>
            <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="End Date"
                        value={endDate || null}
                        onChange={(newValue) => setEndDate(newValue)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: "outlined",
                                margin: "dense",
                                required: true
                            }
                        }}
                    />
                </Grid>
            </LocalizationProvider>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {errorMessage && (
                        <Grid item xs={12}>
                            <MuiAlert 
                                severity="error" 
                                onClose={() => setErrorMessage('')}
                                sx={{ mb: 2 }}
                            >
                                {errorMessage}
                            </MuiAlert>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{ marginRight: 4 }}
                            onClick={() => {
                                props.history.replace('/app/applications/rush/settings/')
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {isLoading ? (
                            <Box style={{ paddingLeft: 25 }}>
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Button 
                                variant={'contained'} 
                                color={'primary'} 
                                onClick={() => handleSubmit()} 
                                style={{ marginLeft: 4 }}
                            >
                                Save
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Grid container spacing={2}>
            <NavigationBar
                titles={[
                    {
                        name: 'My House',
                        link: '/app/dashboard/',
                        icon: <HomeIcon />,
                    },
                    {
                        name: 'Rush',
                        link: '/app/applications/rush/',
                        icon: <RushIcon />,
                        iconMobileOnly: true,
                    },
                    {
                        name: 'Settings',
                        link: '/app/applications/rush/settings/',
                        icon: <SettingsIcon />,
                        iconMobileOnly: true,
                    },
                    { name: 'Edit' },
                ]}
                grid
            />
            {isLoadingRush && <CircularProgress />}
            {isErrorRush && <MuiAlert severity="error">An error occurred while loading the rush</MuiAlert>}
            {rush && (
            <Grid item xs={12}>
                {isMobileDevice() ? (
                    renderEditor()
                ) : (
                    <Paper>
                        <Box style={{ padding: '1rem' }}>{renderEditor()}</Box>
                        </Paper>
                    )}
                </Grid>
            )}
        </Grid>
    )
}
