import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, Box, Select, MenuItem, useMediaQuery, useTheme, Grid } from '@mui/material';
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import NavigationBar from 'components/NavigationBar';
import CandidatesTab from './candidates/CandidatesTab';
import VotingCard from './voting/VotingCard';
import CalendarTab from './events/CalendarTab';
import AnalyticsTab from './analytics/AnalyticsTab';
import { useGetRushes } from 'hooks/queries/rush/useGetRushes';
import { AuthContext } from 'context/AuthContext';
import { useGetCalendarEvents } from 'hooks/queries/calendar/useGetCalendarEvents';
import { DateTime } from 'luxon';
import { ChapterContext } from 'context/ChapterContext';

export default function RushDashboard({rushId}) {
    const { user } = useContext(AuthContext)
    const [currentTab, setCurrentTab] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { chapter } = useContext(ChapterContext)
    const currentRushId = rushId ?? chapter.activeRush
    const { data: rushData, isLoading: isLoadingRushData  } = useGetRushes(
        { chapterId: user.getChapter(), rushId: currentRushId  }
    )
    console.log(`test chapter id ${currentRushId}`)
    const { data: calendarEvents, isLoading: isCalendarEventsLoading, isError: isCalendarEventsError, error: calendarEventsError } = useGetCalendarEvents({
        chapterId: user?.getChapter() || null,
        chapter: chapter || null,
        memberStatus: chapter?.members[user?.getId()]?.status || null,
        userId: user?.getId() || null,
        startDate: DateTime.now().toJSDate(),
        endDate: DateTime.now().plus({ days: 30 }).toJSDate(),
        limitCount: 8
      });

      const handleChange = (event, newValue) => {
        if (isMobile) {
            setCurrentTab(Number(event.target.value));
        } else {
            setCurrentTab(newValue);
        }
    };

    const TabPanel = ({ children, value, index }) => {
        return (
            <div hidden={value !== index} role="tabpanel">
                {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
            </div>
        );
    };
    console.log(`calendarEvents: ${JSON.stringify(calendarEvents)}`)

    const tabItems = [
        { label: 'Candidates', icon: <PersonIcon />,
            component: <CandidatesTab 
            rushees={rushData ? rushData?.rushees : []}
            rushId={currentRushId}
            isLoadingRushees={isLoadingRushData}
            type="rush"
            title="Rush Candidates"
            addButtonText="Add Candidate"
        />},
        { label: 'Events', icon: <EventIcon />, component: <CalendarTab events={calendarEvents ? calendarEvents : []} isLoading={isCalendarEventsLoading} isError={isCalendarEventsError} /> },
        { label: 'Voting', icon: <HowToVoteIcon />, component: <VotingCard candidates={[
            {
              id: 1,
              name: "John Smith",
              year: "Sophomore",
              major: "Computer Science",
              gpa: "3.8",
              events_attended: 3,
              status: "active",
              rating: 4.5,
              notes: [
                { date: "2025-01-15", author: "Mike", text: "Great conversation about leadership experience" }
              ],
              tags: ["athlete", "leadership"]
            },
            // More sample candidates...
          ]} /> },
        { label: 'Analytics', icon: <AssessmentIcon />, component: <AnalyticsTab /> }
    ];

    return (
        <>            
            <Grid container spacing={0}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeOutlined />,
                        },
                        {name: 'Rush', link: '/app/applications/rush'},
                        { name: `Active` },
                    ]}
                    grid={true}
                />
                <Grid item xs={12} mt={2}>
                    <Box>
                        {isMobile ? (
                            <Select
                                value={currentTab}
                                onChange={handleChange}
                                fullWidth
                                displayEmpty
                                renderValue={(value) => {
                                    if (value === undefined || value === null) return '';
                                    return (
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {tabItems[value].icon}
                                            {tabItems[value].label}
                                        </Box>
                                    );
                                }}
                            >
                                {tabItems.map((item, index) => (
                                    <MenuItem key={index} value={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {item.icon}
                                            {item.label}
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Tabs 
                                value={currentTab} 
                                onChange={handleChange}
                                aria-label="rush management tabs"
                                centered={true}
                                sx={{ borderRadius: 2, border: '1px solid #e0e0e0', backgroundColor: theme.palette.background.paper }}
                            >
                                {tabItems.map((item, index) => (
                                    <Tab 
                                        key={index}
                                        icon={item.icon}
                                        label={item.label}
                                        iconPosition="start"
                                    />
                                ))}
                            </Tabs>
                        )}
                    </Box>
                </Grid>
            </Grid>
            
                {tabItems.map((item, index) => (
                    <TabPanel key={index} value={currentTab} index={index}>
                        {item.component}
                    </TabPanel>
                ))}
       
        </>
    );
}
