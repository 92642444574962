import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Widget from '../Widget'
import { Button } from 'components/Wrappers'
import cn from 'classnames'
import './style.css'
import { useTheme } from '@material-ui/styles'

import { NavigateNext as NavigateNextIcon, Search as SearchIcon, Clear as CancelIcon } from '@material-ui/icons'
import { Grid, Box, Breadcrumbs, IconButton, Tooltip, InputAdornment, TextField, InputBase, Typography, Menu, MenuItem } from '@material-ui/core'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { HeaderContext } from 'context/HeaderContext'

import { isMobileDevice as _isMobileDevice } from 'code/Helper'

// styles
import useStyles from './styles'

export default function NavigationBar(props) {
    const { 
        keep = false,
        grid = false,
        component = false,
        authRequired = {
            roles: [],
            permissions: [],
            checkAuth: () => true
        },
        leftButtons,
        titles,
        rightButtons,
        rightSubtitle,
        onSearch,
        onToggleSearch,
        hiddenTitles,
        className
    } = props;

    var classes = useStyles()
    var theme = useTheme()

    const [isMobileDevice] = useState(_isMobileDevice())

    const [hiddenTarget, setHiddenTarget] = useState(null)

    const atLeastMedium = useMediaQuery(theme.breakpoints.up('md'))
    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const [isSearchSmall, innerSetIsSearchSmall] = useState(true)

    const [search, setSearch] = useState('')

    const { icons, setIcons } = React.useContext(HeaderContext)

    function setIsSearchSmall(val) {
        if (onToggleSearch) {
            onToggleSearch(!val)
        }
        innerSetIsSearchSmall(val)
    }

    const handleSearch = e => {
        let val = e.target.value
        setSearch(val)
        onSearch(val)
    }

    function getShortLink(link) {
        if (link.indexOf('#') !== 0) {
            return link
        }

        return link.substring(1)
    }

    function getLongLink(link) {
        return link
    }

    function renderTitleIcon(title, key) {
        if (title.name) {
            return (
                <Tooltip title={title.name} style={{ margin: 3 }} key={key}>
                    <Box className={classes.iconTitle}>{title.icon}</Box>
                </Tooltip>
            )
        } else {
            return (
                <Box className={classes.iconTitle} key={key}>
                    {title.icon}
                </Box>
            )
        }
    }

    React.useEffect(() => {
        if (isMobileDevice && !keep) {
            if (rightButtons) {
                if (icons !== rightButtons) {
                    setIcons(rightButtons)
                }
            } else if (icons.length > 0) {
                setIcons([])
            }
        }
    }, [icons, isMobileDevice, keep, rightButtons, setIcons])

    React.useEffect(() => {
        return () => {
            setHiddenTarget(null)
        }
    }, [titles])

    if (isMobileDevice && !keep) {
        return <></>
    }

    const render = () => (
        <>
            {hiddenTitles && (
                <Menu anchorEl={hiddenTarget} open={!!hiddenTarget} onClose={() => setHiddenTarget(null)}>
                    {hiddenTitles.map(title => (
                        <MenuItem key={title.link} component={Link} to={getLongLink(title.link)}>
                            {title.name}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            <Widget disableWidgetMenu inheritHeight bodyClass={cn(classes.navPadding, className)}>
                {isSearchSmall || atLeastMedium || !onSearch ? (
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap={'nowrap'}>
                        {leftButtons && (
                            <Box display="flex" alignItems="center">
                                {leftButtons.map(button =>
                                    button.type && button.type === 'icon' ? (
                                        <IconButton key={button.name} aria-label={button.name} size="small" onClick={button.onClick}>
                                            {button.innerIcon}
                                        </IconButton>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color={button.color}
                                            className={cn(classes.button, classes.buttonWhite)}
                                            onClick={button.onClick}
                                            key={button.name}
                                        >
                                            {button.name}
                                        </Button>
                                    ),
                                )}
                            </Box>
                        )}
                        <Box display="flex" alignItems="center" className={classes.navTitle}>
                            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                                {titles &&
                                    titles.map((title, index) =>
                                        title.link ? (
                                            title.icon && (!title.iconMobileOnly || !atLeastSmall) ? (
                                                <IconButton size="small" component={Link} to={getShortLink(title.link)} key={index} aria-label={title.name}>
                                                    <Box className={classes.iconTitle}>{title.icon}</Box>
                                                </IconButton>
                                            ) : (
                                                <Link to={getLongLink(title.link)} key={index} className={classes.breadcrumbsLink}>
                                                    <Typography variant="h4" className={classes.breadcrumbsText}>
                                                        {title.name}
                                                    </Typography>
                                                </Link>
                                            )
                                        ) : title.icon && (!title.iconMobileOnly || !atLeastSmall) ? (
                                            renderTitleIcon(title, index)
                                        ) : title.collapsed ? (
                                            <Button
                                                size="small"
                                                onClick={e => {
                                                    setHiddenTarget(e.currentTarget)
                                                }}
                                                style={{ minWidth: 32, padding: 0, borderRadius: 4 }}
                                            >
                                                •••
                                            </Button>
                                        ) : (
                                            <Typography variant="h4" className={classes.breadcrumbsText} key={index}>
                                                {title.name}
                                            </Typography>
                                        ),
                                    )}
                            </Breadcrumbs>
                        </Box>
                        <Box display="flex" alignItems="center">
                            {onSearch && (
                                <TextField
                                    id="search-field"
                                    margin="none"
                                    size="small"
                                    label="Search"
                                    variant="outlined"
                                    style={{ marginLeft: 4 }}
                                    className={`${isSearchSmall ? classes.searchField : classes.searchFieldLarge} without-padding`}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon className={classes.searchIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onFocus={() => {
                                        if (onToggleSearch) {
                                            onToggleSearch(true)
                                        }
                                    }}
                                    onBlur={() => {
                                        if (onToggleSearch) {
                                            onToggleSearch(false)
                                        }
                                    }}
                                    inputProps={{ maxLength: 30 }}
                                    onChange={handleSearch}
                                    value={search}
                                />
                            )}
                            {isSearchSmall && onSearch && (
                                <IconButton aria-label={'Search'} className={classes.searchSmallIcon} size="small" onClick={() => setIsSearchSmall(false)}>
                                    <SearchIcon />
                                </IconButton>
                            )}
                            {(rightButtons || rightSubtitle) && (
                                <Box display="flex" alignItems="center">
                                    {rightSubtitle && (
                                        <Typography variant="body1" className={classes.breadcrumbsText}>
                                            {rightSubtitle}
                                        </Typography>
                                    )}
                                    {rightButtons && (
                                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 6, marginLeft: 6 }}>
                                            {rightButtons.map((button, index) =>
                                                button.type && button.type === 'icon' ? (
                                                    button.tooltip ? (
                                                        <Tooltip title={button.tooltip} key={index}>
                                                            <div>
                                                                <IconButton aria-label={button.name} size="small" onClick={button.onClick}>
                                                                    {button.innerIcon}
                                                                </IconButton>
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <IconButton aria-label={button.name} size="small" onClick={button.onClick} key={index}>
                                                            {button.innerIcon}
                                                        </IconButton>
                                                    )
                                                ) : button.type && button.type === 'switch' ? (
                                                    <ToggleButtonGroup
                                                        exclusive
                                                        value={button.value}
                                                        onChange={(e, val) => button.onClick(val)}
                                                        aria-label={button.name}
                                                        size={button.size ? button.size : 'medium'}
                                                        className={classes.toggleRightButton}
                                                    >
                                                        {button.icons.map(icon => (
                                                            <ToggleButton value={icon.value} aria-label={icon.name} key={icon.value}>
                                                                {icon.icon}
                                                            </ToggleButton>
                                                        ))}
                                                    </ToggleButtonGroup>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color={button.color}
                                                        className={cn(classes.button, classes.buttonWhite)}
                                                        onClick={button.onClick}
                                                        key={index}
                                                    >
                                                        {button.name}
                                                    </Button>
                                                ),
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Grid>
                ) : (
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap={'nowrap'}>
                        <Box display="flex" alignItems="center">
                            <SearchIcon />
                        </Box>
                        <Box display="flex" alignItems="center" style={{ flexGrow: 1 }}>
                            <InputBase
                                id="search-field"
                                placeholder="Search..."
                                inputProps={{ maxLength: 30, 'aria-label': 'Search' }}
                                onChange={handleSearch}
                                style={{ paddingLeft: 8, width: `100%` }}
                                value={search}
                                autoFocus
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <IconButton
                                aria-label="Cancel"
                                size="small"
                                onClick={() => {
                                    setIsSearchSmall(true)
                                    setSearch('')
                                    onSearch('')
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                )}
            </Widget>
        </>
    )

    if (props?.grid) {
        return (
            <Grid item xs={12}>
                {render()}
            </Grid>
        )
    }

    if (props?.component) {
        return <Box style={{ padding: 8, width: '100%' }}>{render()}</Box>
    }

    return render()
}

NavigationBar.propTypes = {
    // Core properties
    keep: PropTypes.bool,
    grid: PropTypes.bool,
    component: PropTypes.bool,
    className: PropTypes.string,
    
    // Button and content properties
    leftButtons: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['icon', 'switch']),
        color: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        innerIcon: PropTypes.node,
        tooltip: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.any,
            icon: PropTypes.node
        })),
        value: PropTypes.any,
        size: PropTypes.string
    })),
    
    rightButtons: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['icon', 'switch']),
        color: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        innerIcon: PropTypes.node,
        tooltip: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.any,
            icon: PropTypes.node
        })),
        value: PropTypes.any,
        size: PropTypes.string
    })),
    
    titles: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        icon: PropTypes.node,
        iconMobileOnly: PropTypes.bool,
        collapsed: PropTypes.bool
    })),
    
    hiddenTitles: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string
    })),
    
    // Search functionality
    onSearch: PropTypes.func,
    onToggleSearch: PropTypes.func,
    
    // Additional content
    rightSubtitle: PropTypes.string,
    
    // Authorization
    authRequired: PropTypes.shape({
        roles: PropTypes.arrayOf(PropTypes.string),
        permissions: PropTypes.arrayOf(PropTypes.string),
        checkAuth: PropTypes.func
    })
}
