import React from 'react';
import EventCard from './EventCard';
import { CircularProgress, Typography, Grid } from '@material-ui/core';

const CalendarTab = ({ events, isLoading, isError }) => {
  if(isLoading) return <CircularProgress />
  if(isError) return <Typography>Error loading events</Typography>
  if(!events) return <Typography>No events found</Typography>

    return (
      <>
      <Grid container spacing={2}>
        
        
        {events.map(event => {
        return (
          <Grid item xs={12} key={event.id}>
          <EventCard key={event.id} event={event} view={'events'} />
        </Grid>
        )
      })}
      </Grid>
      </>
    );
}

export default CalendarTab;