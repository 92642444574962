// src/lib/queryKeys.js
export const queryKeys = {
    users: {
      all: ['users'],
      infinite: ['users', 'infinite'],
      detail: (id) => ['users', id],
      settings: (id) => ['users', id, 'settings'],
    },
    rush: {
      all: ['rush'],
      active: ['rush', 'active'],
      detail: (id) => ['rush', id]
    },
    rushee: {
      all: (chapterId, rushId) => ['rushee', chapterId, rushId],
      detail: (chapterId, rushId, rusheeId) => ['rushee', chapterId, rushId, rusheeId],
    },
    chapter: 'chapter',
    calendar: {
      all: ['calendar'],
      categories: (chapterId) => ['calendar', 'categories', chapterId],
    }
  };
  