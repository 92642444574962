import React from 'react'

import { Redirect } from 'react-router-dom'

import House from 'pages/house'
import Courses from 'pages/applications/courses'
import ViewCourse from 'pages/applications/courses/view'
import PreviewCourse from 'pages/applications/courses/preview'
import Drive from 'pages/applications/drive'
import Meals from 'pages/applications/meals'
import Orders from 'pages/applications/meals/orders'
import Menus from 'pages/applications/meals/menus'
import NewMenu from 'pages/applications/meals/menus/new'
import MealsSettings from 'pages/applications/meals/settings'
import EditMealsPlan from 'pages/applications/meals/settings/edit-plan'
import Roles from 'pages/admin/roles'
import UpdateRole from 'pages/admin/roles/update'
import HQChapters from 'pages/hq/chapters'
import Notifications from 'pages/notifications'
import Staff from 'pages/hq/staff'
import HQViewChapter from 'pages/hq/chapters/view'
import HQAccreditation from 'pages/hq/accreditation'
import HQCourses from 'pages/hq/courses'
import ManageChapter from 'pages/admin/manage-chapter'
import MemberManagement from 'pages/admin/member-management'

import Messages from 'pages/messages'
import ViewMessages from 'pages/messages/view'
import ViewMessagesDetails from 'pages/messages/view/details'
import UpdateMessages from 'pages/messages/update'

import Members from 'pages/members'
import Applications from 'pages/applications'
import Rush from 'pages/applications/rush/Rush'
import RushDashboard from 'pages/applications/rush/dashboard/RushDashboard'
import Tasks from 'pages/applications/tasks'
import UpdateTask from 'pages/applications/tasks/update'
import ViewTask from 'pages/applications/tasks/view'
import Points from 'pages/applications/points'
import ViewPoints from 'pages/applications/points/view'
import UpdatePoints from 'pages/applications/points/update'
import Polls from 'pages/applications/polls'
import EditPoll from 'pages/applications/polls/edit'
import ViewPoll from 'pages/applications/polls/view'
import AddRushee from 'pages/applications/rush/add-rushee'
import RushSettings from 'pages/applications/rush/settings/Settings'
import CreateRush from 'pages/applications/rush/settings/create-rush/CreateRush'
import UpcomingEvents from 'pages/calendar/upcoming-events'
import ViewEvent from 'pages/calendar/upcoming-events/view-event'
import NewEvent from 'pages/calendar/upcoming-events/new-event'
import CalendarSettings from 'pages/calendar/upcoming-events/calendar-settings'
import ViewRushee from 'pages/applications/rush/view-rushee'
import EditRushee from 'pages/applications/rush/edit-rushee'
import ViewProfile from 'pages/profile'
import EditProfile from 'pages/profile/edit'
import ProfileSettings from 'pages/profile/settings'
import Chapters from 'pages/gc/chapters'
import ViewChapter from 'pages/gc/chapters/view'
import UpdateChapter from 'pages/gc/chapters/update'
import Organizations from 'pages/gc/organizations'
import UpdateOrganization from 'pages/gc/organizations/update'
import ViewOrganization from 'pages/gc/organizations/view'
import Quotes from 'pages/gc/quotes'
import ViewQuote from 'pages/gc/quotes/view'
import Reporting from 'pages/applications/rush/reporting/Reporting'
import EditRush from 'pages/applications/rush/settings/edit-rush/EditRush'
/**
 * Maps the routes/urls to Page components
 */
export const paths = [
    /* User */
    { path: '/app/dashboard/:action', component: House, title: 'Home', baseRoute: true },
    { path: '/app/dashboard', component: House, title: 'Home', baseRoute: true },

    { path: '/app/admin/member-management', component: MemberManagement, title: 'Member Management', backPath: '/app/admin' },

    { path: '/app/admin/roles/add', component: UpdateRole, title: 'New Role', backPath: '/app/admin/roles' },
    { path: '/app/admin/roles/edit/:id', component: UpdateRole, title: 'Update Role', backPath: '/app/admin/roles' },
    { path: '/app/admin/roles', component: Roles, title: 'Roles', backPath: '/app/admin' },

    { path: '/app/admin', component: ManageChapter, title: 'Admin', baseRoute: true },

    { path: '/app/calendar/event/new', component: NewEvent, title: 'New Event', backPath: '/app/calendar' },
    { path: '/app/calendar/event/:id/edit', component: NewEvent, title: 'Update Event', backPath: '/app/calendar/event/:id' },
    { path: '/app/calendar/signIn/:id/:qrCode', component: ViewEvent, title: 'Event', backPath: '/app/calendar' },
    { path: '/app/calendar/event/:id', component: ViewEvent, title: 'Event', backPath: '/app/calendar' },
    { path: '/app/calendar/settings', component: CalendarSettings, title: 'Calendar Settings', backPath: '/app/calendar' },
    { path: '/app/calendar/:date', component: UpcomingEvents, title: 'Calendar', baseRoute: true },
    { path: '/app/calendar', component: UpcomingEvents, title: 'Calendar', baseRoute: true },

    {
        path: '/app/applications/courses/:id/preview',
        component: PreviewCourse,
        title: 'Preview Course',
        backPath: '/app/applications/courses/:id',
        feature: 'courses',
    },
    { path: '/app/applications/courses/:id', component: ViewCourse, title: 'Course', backPath: '/app/applications/courses', feature: 'courses' },
    { path: '/app/applications/courses', component: Courses, title: 'Courses', backPath: '/app/applications', feature: 'courses' },

    { path: '/app/applications/members', component: Members, title: 'Members', backPath: '/app/applications' },

    { path: '/app/applications/points/view/:id', component: ViewPoints, title: 'System', backPath: '/app/applications/points', feature: 'points' },
    {
        path: '/app/applications/points/edit/new',
        component: UpdatePoints,
        title: 'New System',
        backPath: '/app/applications/points',
        feature: 'points',
    },
    {
        path: '/app/applications/points/edit/:id',
        component: UpdatePoints,
        title: 'Update System',
        backPath: ({ id }) => `/app/applications/points/view/${id}`,
        feature: 'points',
    },
    { path: '/app/applications/points', component: Points, title: 'Points', backPath: '/app/applications', feature: 'points' },

    { path: '/app/applications/polls/view/:chapterID/:pollID', component: ViewPoll, title: 'Poll', backPath: '/app/applications', feature: 'polls' },
    { path: '/app/applications/polls/edit/:id', component: EditPoll, title: 'Edit Poll', backPath: '/app/applications/polls', feature: 'polls' },
    { path: '/app/applications/polls*', component: Polls, title: 'Polls', backPath: '/app/applications', feature: 'polls' },

    {
        path: '/app/applications/tasks/:id/edit',
        component: UpdateTask,
        title: 'Update Task',
        backPath: ({ id }) => (id === 'new' ? '/app/applications/tasks' : `/app/applications/tasks/${id}`),
        feature: 'tasks',
    },
    { path: '/app/applications/tasks/:id', component: ViewTask, title: 'Task', backPath: '/app/applications/tasks', feature: 'tasks' },
    { path: '/app/applications/tasks', component: Tasks, title: 'Tasks', backPath: '/app/applications', feature: 'tasks' },

    { path: '/app/applications/meals/menus/:id', component: NewMenu, title: 'New Menu', backPath: '/app/applications/meals/menus', feature: 'meals' },
    { path: '/app/applications/meals/menus', component: Menus, title: 'Menus', backPath: '/app/applications/meals', feature: 'meals' },
    {
        path: '/app/applications/meals/settings/plans/:id',
        component: EditMealsPlan,
        title: 'Edit Meal Plan',
        backPath: '/app/applications/meals/settings',
        feature: 'meals',
    },
    { path: '/app/applications/meals/settings', component: MealsSettings, title: 'Meals Settings', backPath: '/app/applications/meals', feature: 'meals' },
    { path: '/app/applications/meals/orders', component: Orders, title: 'Orders', backPath: '/app/applications/meals', feature: 'meals' },
    { path: '/app/applications/meals', component: Meals, title: 'Meals', backPath: '/app/applications', feature: 'meals' },
    { path: '/app/applications/v2/rush', component: RushDashboard, title: 'Rushv2.0', backPath: '/app/applications/rush', feature: 'rush' },
    {
        path: '/app/applications/rush/settings/create',
        component: CreateRush,
        title: 'Create Rush',
        backPath: '/app/applications/rush/settings',
        feature: 'rush',
    },
    {
        path: '/app/applications/rush/settings/edit',
        component: EditRush,
        title: 'Edit Rush',
        backPath: '/app/applications/rush/settings',
        feature: 'rush',
    },
    {
        path: '/app/applications/rush/rushee/:id/edit',
        component: EditRushee,
        title: 'Edit Rushee',
        backPath: ({ id }) => (id === 'new' ? '/app/applications/rush' : `/app/applications/rush/rushee/${id}`),
        feature: 'rush',
    },
    { path: '/app/applications/rush/:id/reporting', component: Reporting, title: 'Analytics', backPath: '/app/applications/rush', feature: 'rush' },
    { path: '/app/applications/rush/settings', component: RushSettings, title: 'Rush Settings', backPath: '/app/applications/rush', feature: 'rush' },
    { path: '/app/applications/rush/add-rushee', component: AddRushee, title: 'Add Rushee', backPath: '/app/applications/rush', feature: 'rush' },
    { path: '/app/applications/rush/rushee/:id', component: ViewRushee, title: 'Rushee', backPath: '/app/applications/rush', feature: 'rush' },
    { path: '/app/applications/rush', component: Rush, title: 'Rush', backPath: '/app/applications', feature: 'rush' },

    {
        path: '/app/messages/new/:eventId/:trigger',
        component: UpdateMessages,
        title: 'Create Conversation',
        backPath: '/app/messages',
        feature: 'messages',
    },
    { path: '/app/messages/new', component: UpdateMessages, title: 'Create Conversation', backPath: '/app/messages', feature: 'messages' },
    {
        path: '/app/messages/:conversationId/edit',
        component: UpdateMessages,
        title: 'Update Conversation',
        backPath: ({ conversationId }) => `/app/messages/${conversationId}/details`,
        feature: 'messages',
    },
    {
        path: '/app/messages/:conversationId/details',
        component: ViewMessagesDetails,
        title: 'Conversation Details',
        backPath: ({ conversationId }) => `/app/messages/${conversationId}`,
        feature: 'messages',
    },
    {
        path: '/app/messages/:conversationId/:postId',
        component: ViewMessages,
        title: 'Conversation',
        backPath: '/app/messages',
        feature: 'messages',
    },
    { path: '/app/messages/:conversationId', component: ViewMessages, title: 'Conversation', backPath: '/app/messages', feature: 'messages' },
    { path: '/app/messages', component: Messages, title: 'Messages', baseRoute: true, feature: 'messages' },

    { path: '/app/profile/:id/edit', component: EditProfile, title: 'Update Account', backPath: ({ id }) => `/app/profile/${id}` },
    { path: '/app/profile/:id', component: ViewProfile, title: 'Account', backPath: '/app/dashboard' },

    { path: '/app/notifications', component: Notifications, title: 'Notifications', backPath: '/app/dashboard' },
    { path: '/app/settings', component: ProfileSettings, title: 'Settings', backPath: '/app/dashboard' },

    /* Greek Connect Headquarters */
    { path: '/app/gc/organizations/new', component: UpdateOrganization, title: 'New Organization', backPath: '/app/gc/organizations' },
    { path: '/app/gc/organizations/:id/edit', component: UpdateOrganization, title: 'Update Organization', backPath: '/app/gc/organizations/:id' },
    { path: '/app/gc/organizations/:id', component: ViewOrganization, title: 'Organization', backPath: '/app/gc/organizations' },
    { path: '/app/gc/organizations', component: Organizations, title: 'Organizations', baseRoute: true },
    { path: '/app/gc/quotes/:id', component: ViewQuote, title: 'View Quote', backPath: '/app/gc/quotes' },
    { path: '/app/gc/quotes', component: Quotes, title: 'Quotes', baseRoute: true },
    { path: '/app/gc/chapters/new', component: UpdateChapter, title: 'Update Chapter', backPath: '/app/gc/chapters' },
    { path: '/app/gc/chapters/:chapterID/member-management', component: MemberManagement, title: 'Member Management', backPath: '/app/gc/chapters/:chapterID' },
    { path: '/app/gc/chapters/:id/edit', component: UpdateChapter, title: 'Update Chapter', backPath: '/app/gc/chapters/:id' },
    { path: '/app/gc/chapters/:id', component: ViewChapter, title: 'Chapter', backPath: '/app/gc/chapters' },
    { path: '/app/gc/chapters', component: Chapters, title: 'Chapters', baseRoute: true },

    /* Organization Headquarters */
    { path: '/app/hq/chapters/:chapterID/member-management', component: MemberManagement, title: 'Member Management', backPath: '/app/hq/chapters/:chapterID' },
    { path: '/app/hq/chapters/:id', component: HQViewChapter, title: 'Chapter', backPath: '/app/hq/chapters' },
    { path: '/app/hq/chapters', component: HQChapters, title: 'Chapters', backPath: '/app/hq' },
    { path: '/app/hq/apps/accreditation', component: HQAccreditation, title: 'Accreditation', backPath: '/app/hq/apps/' },
    { path: '/app/hq/apps/courses', component: HQCourses, title: 'Courses', backPath: '/app/hq/apps/' },
    { path: '/app/hq/staff', component: Staff, title: 'Staff', backPath: '/app/hq' },

    { path: '/app/hq/roles/add', component: UpdateRole, title: 'New Role' },
    { path: '/app/hq/roles/edit/:id', component: UpdateRole, title: 'Update Role' },
    { path: '/app/hq/roles', component: Roles, title: 'Roles' },

    { path: '/app/applications/drive*', component: Drive, title: 'Drive', feature: 'drive' },

    /* Fallback */
    { path: '/app/applications', component: Applications, title: 'Applications', baseRoute: true },

    { path: '/', render: () => <Redirect to="/app/dashboard" />, title: '' },
]
