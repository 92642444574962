import React from 'react';
import { Collapse, Grow, Box } from '@material-ui/core';
import Widget from 'components/Widget';
import GCWidget from 'components/GCWidget';
import useStyles from './styles';

const EventCard = ({view, history, isDifferentTimezone}) => {
    const classes = useStyles()
    return (
        <Collapse key={view.type === 'events' ? view.data.id : 'menu'} classes={{ root: classes.componentBoxContainer }}>
            <Grow appear in>
                <Box className={classes.componentBox}>
                    <Box className={classes.widgetParent}>
                        <Widget disableWidgetMenu inheritHeight noBodyPadding>
                            <GCWidget type={view.type} {...view.data} history={history} isDifferentTimezone={isDifferentTimezone} />
                        </Widget>
                    </Box>
                </Box>
            </Grow>
        </Collapse>
    )
}
export default EventCard;