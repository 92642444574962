import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'lib/queryKeys';
import { db } from 'config/firebase'

/**
 * returns either the active rush or the rush with the id of rushId
 * @param {*} chapterId 
 * @param {*} { rushId , isActive } 
 * @returns {firebase.firestore.DocumentData}
 */
const fetchRushData = async (chapterId, { rushId , isActive }) => {
  console.log(`Chapter ${chapterId} and rush id of ${rushId} and isActive of ${isActive}`)
  let rushes

  // get all rushes
  if(!rushId && !isActive) {
    rushes = await db.collection('chapters')
      .doc(chapterId)
      .collection('rushes')
      .get()

      if(!rushes.docs.length) {
        throw new Error('No rushes found')
      }
    return rushes.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  }
  
  // get active rush
  if(!rushId && isActive) {
     const chapter= await db.collection('chapters')
      .doc(chapterId).get()
      if(!chapter.data().activeRush) {
        throw new Error('No active rush found')
      }
      rushes = await db.collection('chapters')
        .doc(chapterId)
        .collection('rushes')
        .doc(chapter.data().activeRush)
        .get()

      if(!rushes.exists) {
        throw new Error('No active rush found')
      }
    return { id: rushes.id, ...rushes.data() }
  }
  
  // get specific rush
  if(rushId) {
    rushes = await db.collection('chapters')
      .doc(chapterId)
      .collection('rushes')
      .doc(rushId)
      .get()

    if (!rushes.exists) {
      throw new Error('Rush not found')
    }
    return { id: rushes.id, ...rushes.data() }
  }
}

export function useGetRushes({ 
  chapterId, 
  rushId, 
  isActive,
  onSuccess,
  onError 
}) {
  return useQuery({
    queryKey: queryKeys.rush.active,
    queryFn: () => fetchRushData(chapterId, { rushId , isActive }),
    enabled: !!chapterId,
    onSuccess,
    onError
  })
}